import React, { useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import qs from 'query-string'

import Button from '../../Button/Button'
import { FieldFull } from '../components/Fields'
import { Fieldset, Form } from '../components/Layout'
import { onSubmitCreator } from '../helpers/onSubmitCreator'

import {
  fetchUserToValidateRequest,
  validateUserRequest
} from '../../../actions/auth'
import { getUser } from '../../../reducers/user'

const resetInitialValues = { password: '', passwordConfirmation: '' }
const resetSchema = Yup.object().shape({
  password: Yup.string()
    .min(7, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  )
})

const ResetPasswordForm = ({ user, validateUserRequest }) => {
  return (
    <Formik
      initialValues={{ ...resetInitialValues, ...user }}
      validationSchema={resetSchema}
      onSubmit={onSubmitCreator(validateUserRequest)}
    >
      {({ isSubmitting, handleReset, handleSubmit }) => (
        <Form onReset={handleReset} onSubmit={handleSubmit}>
          <h1>Hello {user.firstName}</h1>
          <p>You can set your password here</p>
          <Fieldset>
            <input
              type="text"
              name="username"
              autoComplete="email"
              value={user.email}
              style={{ display: 'none' }}
              readOnly
            />
            <FieldFull
              name="password"
              label="New password"
              type="password"
              autoComplete="new-password"
            />
            <FieldFull
              name="passwordConfirmation"
              label="Confirm password"
              type="password"
              autoComplete="new-password"
            />
          </Fieldset>
          <Fieldset>
            <Button data-type="large" type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Creating account' : 'Sign Up'}
            </Button>
          </Fieldset>
        </Form>
      )}
    </Formik>
  )
}

const ResetPasswordFormContainer = ({
  user,
  location,
  fetchUserToValidateRequest,
  validateUserRequest
}) => {
  let { token } = qs.parse(location.search)

  useEffect(() => {
    token && fetchUserToValidateRequest(token)
  }, [token, fetchUserToValidateRequest])

  validateUserRequest = validateUserRequest.bind(this, {
    validationToken: token
  })

  return user ? (
    <ResetPasswordForm user={user} validateUserRequest={validateUserRequest} />
  ) : (
    'Getting your account information'
  )
}

const mapStateToProps = state => {
  return {
    user: getUser(state, state.auth.validationUser)
  }
}

const ResetPasswordFormConnected = connect(
  mapStateToProps,
  {
    fetchUserToValidateRequest,
    validateUserRequest
  }
)(ResetPasswordFormContainer)

export { ResetPasswordForm, ResetPasswordFormConnected }
