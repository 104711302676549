import React from 'react'
import styled from '@emotion/styled'
import { ShotVersionConnected } from './ShotVersion'

import { type, colors } from '../../styles'

const ShotVersionListContainer = styled.ul({})

const ShotVersionEmptyContainer = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  padding: '0.5rem 0 1rem'
})

const ShotVersionEmpty = styled.div({
  ...type.contentOverride,
  color: colors.day
})

const ShotVersionList = ({ shotVersionIds }) => {
  return (
    <ShotVersionListContainer>
      {shotVersionIds &&
        (shotVersionIds.length > 0 ? (
          shotVersionIds.map(shotVersionId => (
            <ShotVersionConnected
              key={shotVersionId}
              shotVersionId={shotVersionId}
            />
          ))
        ) : (
          <ShotVersionEmptyContainer>
            <ShotVersionEmpty>no shot versions</ShotVersionEmpty>
          </ShotVersionEmptyContainer>
        ))}
    </ShotVersionListContainer>
  )
}

export { ShotVersionList }
