import { connect } from 'react-redux'
import { updateUserRequest } from '../../../actions/editUser'

function mySubmit(values) {
  console.log("Props", updateUserRequest)
}

export default connect(null, {
  // updateUserRequest
})(mySubmit)

