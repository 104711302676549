import React from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'

import Button from '../../Button/Button'
import { FieldFull } from '../components/Fields'
import { Fieldset, Form, HR, LinkStyled } from '../components/Layout'
import { onSubmitCreator } from '../helpers/onSubmitCreator'

import { loginRequest } from '../../../actions/auth'

// doing some simple validations
const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .min(7, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
})

// actual component
const LoginForm = ({ loginRequest }) => {
  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={LoginSchema}
      onSubmit={onSubmitCreator(loginRequest)}
    >
      {({ isSubmitting, handleReset, handleSubmit }) => (
        <Form onReset={handleReset} onSubmit={handleSubmit}>
          <Fieldset>
            <FieldFull
              name="email"
              label="Email"
              autoComplete="email"
              readOnly
            />
            <FieldFull
              name="password"
              label="Password"
              autoComplete="current-password"
              type="password"
            />
          </Fieldset>

          <Fieldset>
            <Button data-type="large" type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Logging in' : 'Login'}
            </Button>
          </Fieldset>

          <Fieldset>
            <HR />
            <LinkStyled to="/auth/retrieve"> Retrieve Password?</LinkStyled>
          </Fieldset>
        </Form>
      )}
    </Formik>
  )
}

// HOC for redux
const LoginFormConnected = connect(
  null,
  {
    loginRequest
  }
)(LoginForm)

export { LoginForm, LoginFormConnected }
