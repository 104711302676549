import { FETCH_TAGS_SUCCESS } from '../actions/tag'
import { combineReducers } from 'redux'
import { union } from 'lodash'

export const tagsById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TAGS_SUCCESS:
      return { ...state, ...action.payload.entities.tag }
    default:
      return state
  }
}

function allTags(state = [], action) {
  switch (action.type) {
    case FETCH_TAGS_SUCCESS:
      return union([], state, action.payload.result)
    default:
      return state
  }
}

export const tags = combineReducers({
  byId: tagsById,
  allIds: allTags
})

export const getTags = state => {
  return Object.values(state.entities.tags.byId)
}

export const getTag = ({ state, tagId }) => {
  return state.entities.tags.byId[tagId]
}
