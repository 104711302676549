export const FETCH_SHOTS_BY_PROJECT_ID_REQUEST =
  'FETCH_SHOTS_BY_PROJECT_ID_REQUEST'
export const FETCH_SHOTS_BY_PROJECT_ID_SUCCESS =
  'FETCH_SHOTS_BY_PROJECT_ID_SUCCESS'
export const FETCH_SHOTS_BY_PROJECT_ID_FAILURE =
  'FETCH_SHOTS_BY_PROJECT_ID_FAILURE'

export const FETCH_SHOT_REQUEST = 'FETCH_SHOT_REQUEST'
export const FETCH_SHOT_SUCCESS = 'FETCH_SHOT_SUCCESS'
export const FETCH_SHOT_FAILURE = 'FETCH_SHOT_FAILURE'

export const FETCH_SHOT_LIST_REQUEST = 'FETCH_SHOT_LIST_REQUEST'
export const FETCH_SHOT_LIST_SUCCESS = 'FETCH_SHOT_LIST_SUCCESS'
export const FETCH_SHOT_LIST_FAILURE = 'FETCH_SHOT_LIST_FAILURE'

export const CREATE_SHOT_REQUEST = 'CREATE_SHOT_REQUEST'
export const CREATE_SHOT_SUCCESS = 'CREATE_SHOT_SUCCESS'
export const CREATE_SHOT_FAILURE = 'CREATE_SHOT_FAILURE'

export const UPDATE_SHOT_REQUEST = 'UPDATE_SHOT_REQUEST'
export const UPDATE_SHOT_SUCCESS = 'UPDATE_SHOT_SUCCESS'
export const UPDATE_SHOT_FAILURE = 'UPDATE_SHOT_FAILURE'

export const UPDATE_SHOT_THUMB_REQUEST = 'UPDATE_SHOT_THUMB_REQUEST'
export const UPDATE_SHOT_THUMB_SUCCESS = 'UPDATE_SHOT_THUMB_SUCCESS'
export const UPDATE_SHOT_THUMB_FAILURE = 'UPDATE_SHOT_THUMB_FAILURE'

export const DELETE_SHOT_REQUEST = 'DELETE_SHOT_REQUEST'
export const DELETE_SHOT_SUCCESS = 'DELETE_SHOT_SUCCESS'
export const DELETE_SHOT_FAILURE = 'DELETE_SHOT_FAILURE'

export const fetchShotsByProjectIdRequest = projectId => {
  return {
    type: FETCH_SHOTS_BY_PROJECT_ID_REQUEST,
    payload: { projectId }
  }
}

export const fetchShotsByProjectIdSuccess = ({ entities, result, request }) => {
  return {
    type: FETCH_SHOTS_BY_PROJECT_ID_SUCCESS,
    payload: {
      entities,
      result,
      request,
      communication: {
        lastUpdated: Date.now(),
        isValid: true
      }
    }
  }
}

export const fetchShotsByProjectIdFailure = e => {
  return {
    type: FETCH_SHOTS_BY_PROJECT_ID_FAILURE,
    payload: e,
    error: true
  }
}

export const fetchShotRequest = ({ shotId }) => {
  return {
    type: FETCH_SHOT_REQUEST,
    payload: { shotId }
  }
}

export const fetchShotSuccess = ({ entities, result }) => {
  return {
    type: FETCH_SHOT_SUCCESS,
    payload: { entities, result }
  }
}

export const fetchShotFailure = e => {
  return {
    type: FETCH_SHOT_FAILURE,
    payload: e,
    error: true
  }
}

export const fetchShotListRequest = ({ shotIds }) => {
  return {
    type: FETCH_SHOT_LIST_REQUEST,
    payload: { shotIds }
  }
}

export const fetchShotListSuccess = ({ entities, result }) => {
  return {
    type: FETCH_SHOT_LIST_SUCCESS,
    payload: { entities, result }
  }
}

export const fetchShotListFailure = e => {
  return {
    type: FETCH_SHOT_LIST_FAILURE,
    payload: e,
    error: true
  }
}

export const createShotRequest = shot => {
  return {
    type: CREATE_SHOT_REQUEST,
    payload: shot
  }
}

export const createShotSuccess = () => {
  return {
    type: CREATE_SHOT_SUCCESS
  }
}

export const createShotFailure = e => {
  return {
    type: CREATE_SHOT_FAILURE,
    payload: e,
    error: true
  }
}

export const updateShotRequest = shot => {
  return {
    type: UPDATE_SHOT_REQUEST,
    payload: shot
  }
}

export const updateShotSuccess = ({ shot }) => {
  return {
    type: UPDATE_SHOT_SUCCESS,
    payload: { ...shot }
  }
}

export const updateShotFailure = e => {
  return {
    type: UPDATE_SHOT_FAILURE,
    payload: e,
    error: true
  }
}

export const updateShotThumbRequest = ({ shotId, files }) => {
  return {
    type: UPDATE_SHOT_THUMB_REQUEST,
    payload: { shotId, files }
  }
}

export const updateShotThumbSuccess = ({ shot }) => {
  return {
    type: UPDATE_SHOT_THUMB_SUCCESS,
    payload: { ...shot }
  }
}

export const updateShotThumbFailure = e => {
  return {
    type: UPDATE_SHOT_THUMB_FAILURE,
    payload: e,
    error: true
  }
}

export const deleteShotRequest = shotId => {
  return {
    type: DELETE_SHOT_REQUEST,
    payload: shotId
  }
}

export const deleteShotSuccess = shotId => {
  return {
    type: DELETE_SHOT_SUCCESS,
    payload: shotId
  }
}

export const deleteShotFailure = e => {
  return {
    type: DELETE_SHOT_FAILURE,
    payload: e,
    error: true
  }
}
