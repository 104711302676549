import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'

import { fetchSelectedUserRequest } from '../../actions/auth'
import { fetchSelectedUserProjectsRequest } from '../../actions/userManager'
import { updateUserRequest } from '../../actions/editUser'
import { updateProjectMembersRequest } from '../../actions/project'
import { fetchProjectsArchivedRequest } from '../../actions/project'
import { setEditUserSelection } from '../../actions/editUser'

import { getAllUserIds } from '../../reducers/user'
import { getUserManagerSelection } from '../../reducers/selection'
import { getSelectedUserProjects } from '../../reducers/userManager'
import { getEditUserSelected } from '../../reducers/editUser'

import { OuterSelect } from './Select/OuterSelect'
import { BorderHeader, Header, HeaderItem, UserManagerContainer } from './UserManagerStyles'
import { fetchUserProjectsRequest } from '../../actions/project'
import { getProjects } from '../../reducers/project'

const UserManager = ({
    selectedUserProjects,
    selectedUser,
    userManagerSelection,
    fetchSelectedUserRequest,
    fetchSelectedUserProjectsRequest,
    match,
    userEditable,
    updateUserRequest,
    fetchUserProjectsRequest,
    projects,
    updateProjectMembersRequest,
    fetchProjectsArchivedRequest,
    setEditUserSelection
}) => {

    const [userId, setUserId] = useState(match.params.userId)

    useEffect(() => {
        if (userId !== undefined) {
            userManagerSelection = userId
        }
        return () => {
            setEditUserSelection(false)
          }
    }, [userId, userManagerSelection])

    useEffect(() => {
        if (!isNaN(userManagerSelection)) {
            fetchSelectedUserRequest(userManagerSelection)
            fetchSelectedUserProjectsRequest(userManagerSelection)
        }
        return () => {
            setEditUserSelection(false)
          }
    }, [userManagerSelection, userId])

    return (
        <UserManagerContainer >
            <Helmet>
                <title>SA User Management</title>
            </Helmet>

{(!isNaN(userManagerSelection)||userId !== undefined )? (
            <BorderHeader>
            <Header>
                {selectedUser[userManagerSelection] ? (
                    <HeaderItem >
                        {selectedUser[userManagerSelection].firstName + " " +
                            selectedUser[userManagerSelection].lastName}
                    </HeaderItem>
                ) : (
                        (userId !== undefined && Object.keys(selectedUser).length !== 0) && (<HeaderItem >
                            {selectedUser[userId].firstName + " " +
                                selectedUser[userId].lastName}
                        </HeaderItem>)
                    )
                }
            </Header>
        </BorderHeader>
): (<div></div>)}


            {(() => {
                if (!isNaN(userManagerSelection) && Object.keys(selectedUser).length !== 0) {
                    return (<OuterSelect
                        userData={selectedUser[userManagerSelection]}
                        userProjects={selectedUserProjects}
                        editable={userEditable ? userEditable : false}
                        updateUserRequest={updateUserRequest}
                        fetchSelectedUserRequest={fetchSelectedUserRequest}
                        fetchUserProjectsRequest={fetchUserProjectsRequest}
                        projects={projects}
                        updateProjectMembersRequest={updateProjectMembersRequest}
                        fetchProjectsArchivedRequest={fetchProjectsArchivedRequest}
                    >
                    </OuterSelect>)
                } else if (userId !== undefined && Object.keys(selectedUser).length !== 0) {
                    return (<OuterSelect
                        userData={selectedUser[userId]}
                        userProjects={selectedUserProjects}
                        editable={userEditable ? userEditable : false}
                        updateUserRequest={updateUserRequest}
                        fetchSelectedUserRequest={fetchSelectedUserRequest}
                        fetchUserProjectsRequest={fetchUserProjectsRequest}
                        projects={projects}
                        updateProjectMembersRequest={updateProjectMembersRequest}
                        fetchProjectsArchivedRequest={fetchProjectsArchivedRequest}
                    >
                    </OuterSelect>
                    )
                } else {
                    return (<div>Select a user in the menu on the left</div>)
                }
            })()}
        </UserManagerContainer>
    )
}
const mapStateToProps = (state, userManagerSelection) => {
    return {
        userManagerSelection: getUserManagerSelection(state),
        selectedUser: getAllUserIds(state),
        selectedUserProjects: getSelectedUserProjects(state, userManagerSelection),
        userEditable: getEditUserSelected(state),
        projects: getProjects(state),
    }
}

export default connect(mapStateToProps, {
    getUserManagerSelection,
    getAllUserIds,
    fetchSelectedUserRequest,
    fetchSelectedUserProjectsRequest,
    updateUserRequest,
    fetchUserProjectsRequest,
    updateProjectMembersRequest,
    fetchProjectsArchivedRequest,
    setEditUserSelection
})(UserManager)
