import { call, all, put, takeEvery } from 'redux-saga/effects'
import * as actions from '../actions/episode'
import * as backend from '../api/backend/index'

function* createEpisode({ payload }) {
  try {
    const episode = yield call(backend.episode.createEpisode, payload)
    yield put(actions.createEpisodeSuccess(episode))
  } catch (e) {
    yield put(actions.createEpisodeFailure(e))
  }
}

function* updateEpisode({ payload }) {
  try {
    const episode = yield call(backend.episode.updateEpisode, payload)
    yield put(actions.updateEpisodeSuccess(episode))
  } catch (e) {
    yield put(actions.updateEpisodeFailure(e))
  }
}

function* deleteEpisode({ payload }) {
  try {
    const episode = yield call(backend.episode.deleteEpisode, payload)
    yield put(actions.deleteEpisodeSuccess(episode))
  } catch (e) {
    yield put(actions.deleteEpisodeFailure(e))
  }
}

export function* episodeRootSaga() {
  yield all([
    yield takeEvery(actions.CREATE_EPISODE_REQUEST, createEpisode),
    yield takeEvery(actions.UPDATE_EPISODE_REQUEST, updateEpisode),
    yield takeEvery(actions.DELETE_EPISODE_REQUEST, deleteEpisode)
  ])
}
