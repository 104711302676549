import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import {
    fetchTechSpecsRequest, updateTechSpecsRequest,
    reorderTechSpecsRequest, deleteTechSpecsRequest
} from '../../actions/techSpecs'
import { getAllTechSpecs, getIsCreateTechSpecsActive } from '../../reducers/techSpecs'
import { TechSpecsContainer, BorderHeader, Header, HeaderItem } from './TechSpecsStyles'
import { reduxForm } from 'redux-form'
import { MiddleComponent } from './MiddleComponent'
import mySubmit from './mySubmit'

let TechSpecs = (props) => {

    const [isEditable, setIsEditable] = useState((props.match.params.extraInfo === 'edit' || props.match.params.extraInfo === 'create') ? true : false)
    useEffect(() => {
        setIsEditable((props.match.params.extraInfo === 'edit' || props.match.params.extraInfo === 'create') ? true : false)
    }, [props.match.params.extraInfo, props.singleProject && props.singleProject.projectId])

    useEffect(() => {
        props.fetchTechSpecsRequest(props.match.params.projectId)
    }, [props.fetchTechSpecsRequest])

    const { handleSubmit } = props

    let allTechSpecs = Object.entries(props.allSpecs && props.allSpecs).map(([key, value]) => {
        return value
    })

    useEffect(() => {
        const { initialize } = props

        initialize({ techSpecsArray: allTechSpecs })
    }, [allTechSpecs == undefined, isEditable])

    return (
        <React.Fragment>
            <TechSpecsContainer >
                <form onSubmit={handleSubmit} >
                    <Helmet>
                        <title>SA User Management</title>
                    </Helmet>

                    <BorderHeader>

                        <Header>
                            <HeaderItem>Tech Specs</HeaderItem>
                        </Header>

                    </BorderHeader>

                    <MiddleComponent
                        allTechSpecs={allTechSpecs && allTechSpecs}
                        isEditable={isEditable}
                        updateTechSpecsRequest={props.updateTechSpecsRequest}
                        reorderTechSpecsRequest={props.reorderTechSpecsRequest}
                        deleteTechSpecsRequest={props.deleteTechSpecsRequest}
                        propsMatch={props.match}
                        isCreateSpecs={props.isCreateSpecs}
                    />

                </form>
            </TechSpecsContainer>)
        </React.Fragment>
    )
}
const mapStateToProps = (state, props) => {

    return {
        allSpecs: getAllTechSpecs(state),
        isCreateSpecs: getIsCreateTechSpecsActive(state)
    }
}

TechSpecs = reduxForm({
    form: 'techSpecsForm',
    onSubmit: mySubmit,

})(TechSpecs)

TechSpecs = connect(mapStateToProps, {
    fetchTechSpecsRequest,
    updateTechSpecsRequest,
    reorderTechSpecsRequest,
    deleteTechSpecsRequest,
})(TechSpecs)

export default TechSpecs 
