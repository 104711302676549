import { delay, all, put, takeEvery } from 'redux-saga/effects'
import { deleteNotification, createNotification } from '../actions/notification'
import { VALIDATE_USER_SUCCESS } from '../actions/auth'

const actions = [VALIDATE_USER_SUCCESS]

function* notify({ type, payload }) {
  let notification = yield put(createNotification(type, payload))
  yield delay(4000)
  yield put(deleteNotification(notification.payload.notificationID))
}

function* error(action) {
  if (action.error) {
    yield notify({ ...action, payload: { ...action.payload, type: 'error' } })
  }
}

function* notificationSaga() {
  yield takeEvery(actions, notify)
  yield takeEvery('*', error)
}

export function* notificationRootSaga() {
  yield all([notificationSaga()])
}
