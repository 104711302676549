const media = [
  '@media (min-width: 0px) and (max-width: 600px)',
  '@media (min-width: 600px) and (max-width: 800px)',
  '@media (min-width: 800px) and (max-width: 1100px)',
  '@media (min-width: 1200px) and (max-width: 1600px)',
  '@media (min-width: 1600px) and (max-width: 1900px)',
  '@media (min-width: 1900px)'
]

export default media
