import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { mergeWith } from 'lodash'

import Button from '../../Button/Button'
import { FieldFull } from '../components/Fields'
import { Fieldset, Form } from '../components/Layout'
import { onSubmitCreator } from '../helpers/onSubmitCreator'

import {
  fetchUserProjectsRequest,
  createProjectRequest,
  updateProjectRequest,
  deleteProjectRequest
} from '../../../actions/project'
import { getProject } from '../../../reducers/project'

const projectInitialValues = {
  title: '',
  shortTitle: '',
  projectType: '',
  director: '',
  dop: '',
  editor: '',
  producer: '',
  vfxCompany: '',
  notes: ''
}

const ProjectSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  shortTitle: Yup.string()
    .min(3, 'Too Short!')
    .max(20, 'Too Long!'),
  projectType: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!'),
  director: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!'),
  dop: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!'),
  editor: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!'),
  producer: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!'),
  vfxCompany: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!'),
  notes: Yup.string().max(160, 'Too Long!')
})

const ProjectForm = ({
  project,
  onSubmit,
  submitText,
  deleteProjectRequest
}) => {
  return (
    <Formik
      initialValues={project || projectInitialValues}
      enableReinitialize
      validationSchema={ProjectSchema}
      onSubmit={onSubmitCreator(onSubmit)}
    >
      {({ isSubmitting, handleReset, handleSubmit }) => (
        <Form onReset={handleReset} onSubmit={handleSubmit}>
          <Fieldset>
            <FieldFull name="title" label="Title" />
            <FieldFull name="shortTitle" label="Short Title" />
            <FieldFull name="projectType" label="Type" />
            <FieldFull name="director" label="Director" />
            <FieldFull name="dop" label="D.O.P." />
            <FieldFull name="editor" label="Editor" />
            <FieldFull name="producer" label="Producer" />
            <FieldFull name="vfxCompany" label="Special Effects Company" />
            <FieldFull name="notes" label="Notes" />
          </Fieldset>
          <Fieldset>
            <Button data-type="large" type="submit" disabled={isSubmitting}>
              {isSubmitting
                ? `${submitText}ing Project`
                : `${submitText}e Project`}
            </Button>
          </Fieldset>
          {project && (
            <Fieldset>
              <Button
                type="button"
                data-type="large"
                onClick={() => {
                  deleteProjectRequest(project.projectId)
                }}
              >
                Delete
              </Button>
            </Fieldset>
          )}
        </Form>
      )}
    </Formik>
  )
}

const FormWrapper = ({
  projectId,
  project,
  fetchUserProjectsRequest,
  updateProjectRequest,
  createProjectRequest,
  deleteProjectRequest
}) => {
  useEffect(() => {
    projectId && fetchUserProjectsRequest()
  }, [projectId, fetchUserProjectsRequest])

  // API SHOULD NOT RETURN NULL
  let mergedProject = mergeWith({}, projectInitialValues, project, (a, b) =>
    b === null ? a : undefined
  )

  if (projectId) {
    return project ? (
      <ProjectForm
        project={mergedProject}
        onSubmit={updateProjectRequest}
        submitText="Updat"
        deleteProjectRequest={deleteProjectRequest}
      />
    ) : (
      'loading data'
    )
  } else {
    return <ProjectForm onSubmit={createProjectRequest} submitText="Creat" />
  }
}

const mapStateToProps = (state, { match }) => {
  let projectId = match.params.projectId
  let project = getProject(state, projectId)
  return { project, projectId, isUpdate: projectId }
}

const ProjectFormConnected = connect(
  mapStateToProps,
  {
    createProjectRequest,
    fetchUserProjectsRequest,
    updateProjectRequest,
    deleteProjectRequest
  }
)(FormWrapper)

export { ProjectForm, ProjectFormConnected }
