import { combineReducers } from 'redux'

import { auth } from './auth'
import { notifications } from './notification'
import { selections } from './selection'
import { editUserButton} from './editUser'

const ui = combineReducers({
  auth,
  notifications,
  selections,
  editUserButton
})

export { ui }
