import { request } from './index'

export const fetchTechSpecs = async (projectId) => {
  let res = await request(`/projects/${projectId}/tech-specs`)
  return res
}

export const createTechSpecs = async techSpecs => {
  let res = await request('/tech-specs', {
    method: 'post',
    data: techSpecs
  })
  return res
}

export const updateTechSpecs = async techSpecs => {
  let res = await request(`/tech-specs/${techSpecs.techSpecId}`, {
    method: 'put',
    data: techSpecs
  })
  return res
}

export const deleteTechSpecs = async techSpecId => {
  let res = await request(`/tech-specs/${techSpecId}`, {
    method: 'delete'
  })
  return res
}

export const reorderTechSpecs = async techSpecs => {
  let res = await request(`/projects/${techSpecs.projectId}/tech-specs/reorder`, {
    method: 'post',
    data: techSpecs.orderedTechSpecIds
  })
  return res
}