import { combineReducers } from 'redux'
import { FETCH_TECHSPECS_SUCCESS, SET_TECHSPECS_SUCCESS} from '../actions/techSpecs'

export const allTechSpecs = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TECHSPECS_SUCCESS:
      return { ...state, ...action.payload.techSpecs}
    default:
      return state
  }
}

const setTechSpec =(state={}, action) =>{
  switch (action.type) {
      case SET_TECHSPECS_SUCCESS:
          return action.payload
      default:
          return state
  }
}

export const techSpecs = combineReducers({
  allSpecs: allTechSpecs,
  setSpecs: setTechSpec
})

export const getAllTechSpecs= state => {
  return state.entities.techSpecs.allSpecs
}

export const getIsCreateTechSpecsActive=(state)=> {
  return state.entities.techSpecs.setSpecs.payload
}
