import React from 'react'
import { connect } from 'react-redux'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import styled from '@emotion/styled'

import Toast from '../Toast/Toast'
import Transition from '../Transition/Transition'
import { getNotificationsByTimestamp } from '../../reducers/notification'

import { units } from '../../styles'

const Container = styled.div({
  position: 'absolute',
  bottom: units.double,
  right: units.double
})

const ToastList = ({ notifications = [] }) => {
  return (
    <Container>
      <TransitionGroup>
        {notifications.map(notification => (
          <Transition key={notification.notificationID}>
            <Toast message={notification.message} type={notification.type} />
          </Transition>
        ))}
      </TransitionGroup>
    </Container>
  )
}

const mapStateToProps = state => {
  return {
    notifications: getNotificationsByTimestamp(state)
  }
}

const ToastListConnected = connect(mapStateToProps)(ToastList)

export { ToastList, ToastListConnected }
