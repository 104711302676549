import React, { useState, useEffect } from 'react'
import Site from './DetailsComponents/Site'
import Company from './DetailsComponents/Company'
import Contact from './DetailsComponents/Contact'
import { connect } from 'react-redux'
import siteDetailsSubmit from './siteDetailsSubmit'
import { reduxForm } from 'redux-form'
import { updateSitesRequest } from '../../actions/sites'
import { DropdownSub } from '../ProjectsManager/DropdownSub'

let DropdownSiteDetails = (props) => {
    useEffect(() => {
        if (props.site !== undefined && props.site.siteId !== undefined) {
            props.initialize(props.site)
        }
    }, [props.site == undefined, props.site.siteId])

    return (
        <React.Fragment >
                <DropdownSub innerName={'Site'}>
                    <Site site={props.site} isEditable={props.isEditable} updateSitesRequest={updateSitesRequest}></Site>
                </DropdownSub>
                <DropdownSub innerName={'Company'}>
                    <Company site={props.site} isEditable={props.isEditable} updateSitesRequest={updateSitesRequest}></Company>
                </DropdownSub>
                <DropdownSub innerName={'Contact'}>
                    <Contact site={props.site} isEditable={props.isEditable} updateSitesRequest={updateSitesRequest}></Contact>
                </DropdownSub>
        </React.Fragment>
    )
}

DropdownSiteDetails = reduxForm({
    form: 'DropdownSiteDetailsForm',
    onSubmit: siteDetailsSubmit,

})(DropdownSiteDetails)

const mapStateToProps = (state) => {
    return {

    }
}
DropdownSiteDetails = connect(mapStateToProps, {
    updateSitesRequest,
})(DropdownSiteDetails)

export { DropdownSiteDetails }