import React, { useState, useEffect } from 'react'
import doubleArrow from '../Button/down.svg'
import { ShotAssetStatuses } from './DetailsComponents/ShotAssetStatuses'
import { VersionStatuses } from './DetailsComponents/VersionStatuses'
import { colors } from '../../styles'
import styled from '@emotion/styled'
import { SelectContainer, Wrap, InnerContainer, SelectedItem, Icon, IconWrap, DropdownContainer, UserProfileContainer } from './ProjectManagerStyles'

const WrapContainer = styled.div({
    marginTop: "0.2rem",
    marginBottom: "0.2rem"
})

let DropdownSub = (props) => {

    const [dropdownName, setDropdownName] = useState(props.innerName)

    let [dropdownVisible, setDropdownVisibility] = useState(true)
    let clickHandler = () => setDropdownVisibility(!dropdownVisible)

    useEffect(() => {
        setDropdownName(props.innerName)
    }, [props.innerName])

    return (
        <Wrap >
            <WrapContainer>
                <SelectContainer style={{ backgroundColor: colors.darkerGrey, border: 'none', height: '1.75rem', }}>
                    <InnerContainer  >
                        <SelectedItem style={{ fontSize: '0.8rem' }}>
                            {dropdownName}
                        </SelectedItem>
                        <IconWrap>
                            <Icon src={doubleArrow} onClick={clickHandler} rotated={dropdownVisible} />
                        </IconWrap>
                    </InnerContainer>
                </SelectContainer>
                <DropdownContainer>
                    {dropdownVisible && (
                            <UserProfileContainer style={{ marginTop: "0.1rem" }}>
                                {props.children}
                            </UserProfileContainer>
                    )}
                </DropdownContainer>
            </WrapContainer>
        </Wrap>
    )
}

export { DropdownSub }