export const FETCH_TECHSPECS_REQUEST = 'FETCH_TECHSPECS_REQUEST'
export const FETCH_TECHSPECS_SUCCESS = 'FETCH_TECHSPECS_SUCCESS'
export const FETCH_TECHSPECS_FAILURE = 'FETCH_TECHSPECS_FAILURE'
export const CREATE_TECHSPECS_REQUEST = 'CREATE_TECHSPECS_REQUEST'
export const CREATE_TECHSPECS_SUCCESS = 'CREATE_TECHSPECS_SUCCESS'
export const CREATE_TECHSPECS_FAILURE = 'CREATE_TECHSPECS_FAILURE'
export const UPDATE_TECHSPECS_REQUEST = 'UPDATE_TECHSPECS_REQUEST'
export const UPDATE_TECHSPECS_SUCCESS = 'UPDATE_TECHSPECS_SUCCESS'
export const UPDATE_TECHSPECS_FAILURE = 'UPDATE_TECHSPECS_FAILURE'
export const DELETE_TECHSPECS_REQUEST = 'DELETE_TECHSPECS_REQUEST'
export const DELETE_TECHSPECS_SUCCESS = 'DELETE_TECHSPECS_SUCCESS'
export const DELETE_TECHSPECS_FAILURE = 'DELETE_TECHSPECS_FAILURE'
export const REORDER_TECHSPECS_REQUEST = 'REORDER_TECHSPECS_REQUEST'
export const REORDER_TECHSPECS_SUCCESS = 'REORDER_TECHSPECS_SUCCESS'
export const REORDER_TECHSPECS_FAILURE = 'REORDER_TECHSPECS_FAILURE'
export const SET_TECHSPECS_REQUEST = 'SET_TECHSPECS_REQUEST'
export const SET_TECHSPECS_SUCCESS = 'SET_TECHSPECS_SUCCESS'
export const SET_TECHSPECS_FAILURE = 'SET_TECHSPECS_FAILURE'

export const fetchTechSpecsRequest = (projectId) => {
  return {
    type: FETCH_TECHSPECS_REQUEST,
    payload: projectId
  }
}

export const fetchTechSpecsSuccess = (techSpecs) => {
  return {
    type: FETCH_TECHSPECS_SUCCESS,
    payload: techSpecs
  }
}

export const fetchTechSpecsFailure = e => {
  return {
    type: FETCH_TECHSPECS_FAILURE,
    payload: e,
    error: true
  }
}

export const createTechSpecsRequest = techSpecs => {
  return {
    type: CREATE_TECHSPECS_REQUEST,
    payload: techSpecs
  }
}

export const createTechSpecsSuccess = () => {
  return {
    type: CREATE_TECHSPECS_SUCCESS
  }
}

export const createTechSpecsFailure = e => {
  return {
    type: CREATE_TECHSPECS_FAILURE,
    payload: e,
    error: true
  }
}

export const updateTechSpecsRequest = techSpecs => {
  return {
    type: UPDATE_TECHSPECS_REQUEST,
    payload: techSpecs
  }
}

export const updateTechSpecsSuccess = () => {
  return {
    type: UPDATE_TECHSPECS_SUCCESS
  }
}

export const updateTechSpecsFailure = e => {
  return {
    type: UPDATE_TECHSPECS_FAILURE,
    payload: e,
    error: true
  }
}

export const deleteTechSpecsRequest = techSpecId => {
  return {
    type: DELETE_TECHSPECS_REQUEST,
    payload: techSpecId
  }
}

export const deleteTechSpecsSuccess = techSpecId => {
  return {
    type: DELETE_TECHSPECS_SUCCESS,
    payload: techSpecId
  }
}

export const deleteTechSpecsFailure = e => {
  return {
    type: DELETE_TECHSPECS_FAILURE,
    payload: e,
    error: true
  }
}

export const reorderTechSpecsRequest = techSpecs => {
  return {
    type: REORDER_TECHSPECS_REQUEST,
    payload: techSpecs
  }
}

export const reorderTechSpecsSuccess = () => {
  return {
    type: REORDER_TECHSPECS_SUCCESS
  }
}

export const reorderTechSpecsFailure = e => {
  return {
    type: REORDER_TECHSPECS_FAILURE,
    payload: e,
    error: true
  }
}

export const setTechSpecsRequest = techSpecs => {
  return {
    type: SET_TECHSPECS_REQUEST,
    payload: techSpecs
  }
}

export const setTechSpecsSuccess = (techSpecs) => {
  return {
    type: SET_TECHSPECS_SUCCESS,
    payload: techSpecs
  }
}

export const setTechSpecsFailure = e => {
  return {
    type: SET_TECHSPECS_FAILURE,
    payload: e,
    error: true
  }
}