import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import styled from '@emotion/styled'

import { ProjectConnected } from '../Project/Project'
import { fetchUserProjectsRequest } from '../../actions/project'
import { getAllProjectsIds } from '../../reducers/project'

import { type, media } from '../../styles'

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem 2rem'
})

const Header = styled.div({
  display: 'flex',
  padding: '1rem 0'
})

const HeaderItem = styled.span({
  ...type.subHeader,
  padding: '0 1rem',
  'first-of-type': {
    paddingLeft: '0'
  }
})
const ProjectsContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridGap: '1rem',
  width: '100%',
  [media[0]]: {
    gridTemplateColumns: '1fr'
  },
  [media[1]]: {
    gridTemplateColumns: '1fr'
  },
  [media[2]]: {
    gridTemplateColumns: '1fr 1fr'
  },
  [media[3]]: {
    gridTemplateColumns: '1fr 1fr 1fr'
  },
  [media[4]]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr'
  },
  [media[5]]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'
  }
})

const ProjectList = ({ projectIds, fetchUserProjectsRequest }) => {
  useEffect(() => {
    fetchUserProjectsRequest()
  }, [fetchUserProjectsRequest])

  return (
    <Container>
      <Helmet>
        <title>SA Projects</title>
      </Helmet>
      <Header>
        <HeaderItem>Projects</HeaderItem>
        {/* <HeaderItem>Current Projects</HeaderItem> */}
      </Header>
      <ProjectsContainer>
        {projectIds &&
          projectIds.map(projectId => {
            return <ProjectConnected key={projectId} projectId={projectId} />
          })}
      </ProjectsContainer>
    </Container>
  )
}

const mapStateToProps = state => {
  return { projectIds: getAllProjectsIds(state) }
}

const ProjectListConnected = connect(
  mapStateToProps,
  { fetchUserProjectsRequest }
)(ProjectList)

export { ProjectList, ProjectListConnected }
