function globalTagsSubmit(values, dispatch, props) {

let tagUpdateAndCreate = values.globalTagsArray.filter(function (obj) { return props.globalTags.indexOf(obj) == -1; });

tagUpdateAndCreate.map((value) => {

  if (value.tagId) {
    props.updateGlobalTagRequest({ tagId: value.tagId, title: value.title, color: value.color })
  }
  else {
    props.createGlobalTagRequest({color: value.color, title: value.title})
  }
})

let array4 = props.globalTags.filter(function (obj) { return values.globalTagsArray.indexOf(obj) == -1; });
let arrayDeleted = array4.filter(o => !tagUpdateAndCreate.find(o2 => o.tagId === o2.tagId))

arrayDeleted.map((value) => {
  props.deleteGlobalTagRequest({ tagId: value.tagId })
})
}

export default globalTagsSubmit

