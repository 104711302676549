import React from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router'
import store from './configureStore'
import { history } from './history'

import Layout from './components/Layout/Layout'

import { checkAuthentication } from './actions/auth'
import { loginRequest } from './actions/auth'
import './App.css';

// auto-login for testing purposes
if (process.env.NODE_ENV === 'development') {
  store.dispatch(checkAuthentication())
  store.dispatch(
    loginRequest({
      // email: 'rjkorteschiel@gmail.com',
      email: 'frank@planetx.nl',
      // password: 'test12345'
      password: 'test1234'
    })
  )
}

const App = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Layout />
      </Router>
    </Provider>
  )
}

export default App
