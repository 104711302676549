import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { ellipsis } from 'polished'

import { TagList } from '../Tag/TagList'
import { StatusConnected } from '../Status/Status'
import { Spinner } from '../Spinner/Spinner'
import { ThumbDropzoneConnected } from '../ThumbDropzone/ThumbDropzone'
import { ShotVersionList } from './ShotVersionList'
import { Expander } from '../Button/Expander'

import { getShot } from '../../reducers/shot'
import { fetchShotRequest, updateShotRequest } from '../../actions/shot'

import { colors, type } from '../../styles'

const ShotContainerEl = styled.div({
  width: '100%',
  borderBottom: `0.0625rem solid ${colors.grey}`
})

const ShotMain = styled.div({
  display: 'grid',
  gridTemplateColumns: '10rem 15rem 15rem 10rem 1fr 4rem',
  width: '100%',
  flexDirection: 'column'
})

const ShotSection = styled.div({
  minWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '0.5rem 0.8125rem'
})

const ShotSectionDetails = styled(ShotSection)({
  justifyContent: 'space-between'
})

const ThumbnailContainer = styled.div({
  position: 'relative',
  overflow: 'hidden'
})

const ShotCodeLink = styled(Link)({
  ...type.subHeader,
  ...ellipsis('100%')
})

const ShotCustomProp = styled.li({
  ...type.contentOverride
})

const Description = styled.p({
  ...type.contentOverride,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 5,
  overflow: 'hidden'
})

const Shot = ({
  shot,
  updateShotRequest,
  versionsVisible,
  handleVersionExpansion
}) => {
  return (
    <ShotMain>
      <ThumbnailContainer>
        <ThumbDropzoneConnected shot={shot} />
      </ThumbnailContainer>

      <ShotSectionDetails>
        <ShotCodeLink
          to={`/project/${shot.projectId}/shot/${shot.shotId}/edit`}
        >
          {shot.shotCode}
        </ShotCodeLink>

        <TagList tagIds={shot.tags} shotId={shot.shotId} />
      </ShotSectionDetails>

      <ShotSection>
        <StatusConnected
          statusId={shot.status}
          statusHandler={statusId => {
            updateShotRequest({ shotId: shot.shotId, statusId })
          }}
        />
      </ShotSection>

      <ShotSection as="ul">
        {[`∆ ${shot.shotLength}`, shot.editId, shot.adminNote].map(
          (value, key) => {
            return <ShotCustomProp key={key}>{value}</ShotCustomProp>
          }
        )}
      </ShotSection>

      <ShotSection>
        <Description>{shot.shotDescription}</Description>
      </ShotSection>

      <ShotSection>
        <Expander onClick={handleVersionExpansion} active={versionsVisible} />
      </ShotSection>
    </ShotMain>
  )
}

const ShotContainer = React.memo(
  ({ shotId, shot, inView = false, updateShotRequest }) => {
    let [isRendered, setIsRendered] = useState(false)
    !isRendered && inView && setIsRendered(true)

    let [versionsVisible, setVersionsVisible] = useState(false)
    let handleVersionExpansion = () => setVersionsVisible(!versionsVisible)

    return (
      <ShotContainerEl>
        {shot && (inView || isRendered) ? (
          <>
            <Shot
              shot={shot}
              updateShotRequest={updateShotRequest}
              versionsVisible={versionsVisible}
              handleVersionExpansion={handleVersionExpansion}
            />
            {versionsVisible && (
              <ShotVersionList shotVersionIds={shot.shotVersionIds} />
            )}
          </>
        ) : (
          <Spinner />
        )}
      </ShotContainerEl>
    )
  }
)

const mapStateToProps = (state, { shotId }) => {
  return {
    shot: getShot(state, shotId)
  }
}

const ShotConnected = connect(
  mapStateToProps,
  { fetchShotRequest, updateShotRequest }
)(ShotContainer)

export { Shot, ShotConnected }
