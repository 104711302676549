import {
  FETCH_FULL_PROJECT_SUCCESS,
  FETCH_USER_PROJECTS_SUCCESS,
  FETCH_PROJECTS_ARCHIVED_SUCCESS,
  UPDATE_PROJECT_MEMBERS_SUCCESS,
  UPDATE_PROJECT_SUCCESS
} from '../actions/project'
import { UPDATE_TAG_SUCCESS, CREATE_TAG_SUCCESS } from '../actions/tag'
import { combineReducers } from 'redux'
import { pick, merge } from 'lodash'
import { CREATE_EPISODE_SUCCESS, UPDATE_EPISODE_SUCCESS} from '../actions/episode'
import { CREATE_PROJECT_STATUS_SUCCESS, UPDATE_PROJECT_STATUS_SUCCESS } from '../actions/status'

export const projectsById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER_PROJECTS_SUCCESS:
    case FETCH_PROJECTS_ARCHIVED_SUCCESS:
      return merge(
        {},
        pick(state, action.payload.result),
        action.payload.entities.projects
      )
    case FETCH_FULL_PROJECT_SUCCESS:
      return merge({}, state, action.payload.entities.projects)
    default:
      return state
  }
}

function allProjects(state = [], action) {
  switch (action.type) {
    case FETCH_USER_PROJECTS_SUCCESS:
    case FETCH_PROJECTS_ARCHIVED_SUCCESS:
      return action.payload.result
    default:
      return state
  }
}

function singleProject(state = [], action) {
  switch (action.type) {
    case FETCH_FULL_PROJECT_SUCCESS:
      return action.payload.entities.projects
    case CREATE_TAG_SUCCESS:
    case UPDATE_TAG_SUCCESS:
    case CREATE_EPISODE_SUCCESS:
    case UPDATE_EPISODE_SUCCESS:
    case CREATE_PROJECT_STATUS_SUCCESS:
    case UPDATE_PROJECT_STATUS_SUCCESS:
    case UPDATE_PROJECT_MEMBERS_SUCCESS:
    case UPDATE_PROJECT_SUCCESS:
      return {...state}

    default:
      return state
  }
}

export const projects = combineReducers({
  byId: projectsById,
  allIds: allProjects,
  project: singleProject
})

export const getProject = (state, projectId) => {
  return state.entities.projects.byId[projectId]
}

export const getAllProjectsIds = state => {
  return state.entities.projects.allIds
}

export const getSingleProject = (state, projectId) => {
  return state.entities.projects.project[projectId]
}

export const getProjects = state => {
  return state.entities.projects.byId
}
