import React, { useState, useEffect } from 'react'
import doubleArrow from '../Button/down.svg'
import  Team  from './DetailsComponents/Team'
import  Statuses from './DetailsComponents/Statuses'
import Tags from './DetailsComponents/DefaultTags'
import { colors } from '../../styles'
import styled from '@emotion/styled'
import { SelectContainer, Wrap, InnerContainer, SelectedItem, Icon, IconWrap, DropdownContainer, UserProfileContainer } from './SiteManagerStyles'

const WrapContainer = styled.div({
    marginTop: "0.2rem",
    marginBottom: "0.2rem"
})

let DropdownProjectDefaults = (props) => {

    const [dropdownName, setDropdownName] = useState(props.innerName)

    let [dropdownVisible, setDropdownVisibility] = useState(true)
    let clickHandler = () => setDropdownVisibility(!dropdownVisible)

    useEffect(() => {
        setDropdownName(props.innerName)
    }, [props.innerName])

    return (
        <Wrap >
            <WrapContainer>
                <SelectContainer style={{ backgroundColor: colors.darkerGrey, border: 'none', height: '1.75rem', }}>
                    <InnerContainer  >
                        <SelectedItem style={{ fontSize: '0.8rem' }}>
                            {dropdownName}
                        </SelectedItem>
                        <IconWrap>
                            <Icon src={doubleArrow} onClick={clickHandler} rotated={dropdownVisible} />
                        </IconWrap>
                    </InnerContainer>
                </SelectContainer>
                <DropdownContainer>
                    {dropdownVisible && (
                        <div>
                            <UserProfileContainer style={{ marginTop: "0.1rem" }}
                            >
                                {dropdownName === "Team" && (<Team site={props.site} isEditable={props.isEditable} ></Team>)}

                                {dropdownName === "Statuses" && (<Statuses site={props.site} isEditable={props.isEditable} ></Statuses>)}

                                {dropdownName === "Tags" && (<Tags site={props.site} isEditable={props.isEditable} ></Tags>)}

                            </UserProfileContainer>
                        </div>
                    )}
                </DropdownContainer>
            </WrapContainer>
        </Wrap>
    )
}

export { DropdownProjectDefaults }