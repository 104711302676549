
export const FETCH_USER_PROJECTS_REQUEST = 'FETCH_USER_PROJECTS_REQUEST'
export const FETCH_USER_PROJECTS_SUCCESS = 'FETCH_USER_PROJECTS_SUCCESS'
export const FETCH_USER_PROJECTS_FAILURE = 'FETCH_USER_PROJECTS_FAILURE'

export const FETCH_FULL_PROJECT_REQUEST = 'FETCH_FULL_PROJECT_REQUEST'
export const FETCH_FULL_PROJECT_SUCCESS = 'FETCH_FULL_PROJECT_SUCCESS'
export const FETCH_FULL_PROJECT_FAILURE = 'FETCH_FULL_PROJECT_FAILURE'

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST'
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS'
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE'

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST'
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS'
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE'

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST'
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS'
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE'
export const UPDATE_PROJECT_MEMBERS_REQUEST = 'UPDATE_PROJECT_MEMBERS_REQUEST'
export const UPDATE_PROJECT_MEMBERS_SUCCESS = 'UPDATE_PROJECT_MEMBERS_SUCCESS'
export const UPDATE_PROJECT_MEMBERS_FAILURE = 'UPDATE_PROJECT_MEMBERS_FAILURE'

export const FETCH_PROJECTS_ARCHIVED_REQUEST = 'FETCH_PROJECTS_ARCHIVED_REQUEST'
export const FETCH_PROJECTS_ARCHIVED_SUCCESS = 'FETCH_PROJECTS_ARCHIVED_SUCCESS'
export const FETCH_PROJECTS_ARCHIVED_FAILURE = 'FETCH_PROJECTS_ARCHIVED_FAILURE'

export const UPDATE_PROJECT_PICTURE_REQUEST = 'UPDATE_PROJECT_PICTURE_REQUEST'
export const UPDATE_PROJECT_PICTURE_SUCCESS = 'UPDATE_PROJECT_PICTURE_SUCCESS'
export const UPDATE_PROJECT_PICTURE_FAILURE = 'UPDATE_PROJECT_PICTURE_FAILURE'

export const fetchUserProjectsRequest = () => {
  return {
    type: FETCH_USER_PROJECTS_REQUEST
  }
}

export const fetchUserProjectsSuccess = ({ entities, result }) => {
  return {
    type: FETCH_USER_PROJECTS_SUCCESS,
    payload: { entities, result }
  }
}

export const fetchUserProjectsFailure = e => {
  return {
    type: FETCH_USER_PROJECTS_FAILURE,
    payload: e,
    error: true
  }
}

export const fetchProjectsArchivedRequest = () => {
  return {
    type: FETCH_PROJECTS_ARCHIVED_REQUEST
  }
}

export const fetchProjectsArchivedSuccess = ({ entities, result }) => {
  return {
    type: FETCH_PROJECTS_ARCHIVED_SUCCESS,
    payload: { entities, result }
  }
}

export const fetchProjectsArchivedFailure = e => {
  return {
    type: FETCH_PROJECTS_ARCHIVED_FAILURE,
    payload: e,
    error: true
  }
}

export const fetchFullProjectRequest = ( projectId ) => {
  return {
    type: FETCH_FULL_PROJECT_REQUEST,
    payload:  projectId 
  }
}

export const fetchFullProjectSuccess = ({ entities, result }) => {
  return {
    type: FETCH_FULL_PROJECT_SUCCESS,
    payload: { entities, result }
  }
}

export const fetchFullProjectFailure = e => {
  return {
    type: FETCH_FULL_PROJECT_FAILURE,
    payload: e,
    error: true
  }
}

export const createProjectRequest = project => {
  return {
    type: CREATE_PROJECT_REQUEST,
    payload: project
  }
}

export const createProjectSuccess = () => {
  return {
    type: CREATE_PROJECT_SUCCESS
  }
}

export const createProjectFailure = e => {
  return {
    type: CREATE_PROJECT_FAILURE,
    payload: e,
    error: true
  }
}

export const updateProjectRequest = project => {
  return {
    type: UPDATE_PROJECT_REQUEST,
    payload: project
  }
}

export const updateProjectSuccess = () => {
  return {
    type: UPDATE_PROJECT_SUCCESS
  }
}

export const updateProjectFailure = e => {
  return {
    type: UPDATE_PROJECT_FAILURE,
    payload: e,
    error: true
  }
}

//new
export const updateProjectMembersRequest = member => {
  return {
    type: UPDATE_PROJECT_MEMBERS_REQUEST,
    payload: member
  }
}

export const updateProjectMembersSuccess = () => {
  return {
    type: UPDATE_PROJECT_MEMBERS_SUCCESS
  }
}

export const updateProjectMembersFailure = e => {
  return {
    type: UPDATE_PROJECT_MEMBERS_FAILURE,
    payload: e,
    error: true
  }
}

export const deleteProjectRequest = projectId => {
  return {
    type: DELETE_PROJECT_REQUEST,
    payload: projectId
  }
}

export const deleteProjectSuccess = projectId => {
  return {
    type: DELETE_PROJECT_SUCCESS,
    payload: projectId
  }
}

export const deleteProjectFailure = e => {
  return {
    type: DELETE_PROJECT_FAILURE,
    payload: e,
    error: true
  }
}

export const updateProjectPictureRequest = ({ projectId, files }) => {

  return {
    type: UPDATE_PROJECT_PICTURE_REQUEST,
    payload: { projectId, files }
  }
}

export const updateProjectPictureSuccess = ({ project }) => {
  return {
    type: UPDATE_PROJECT_PICTURE_SUCCESS,
    payload: { ...project }
  }
}

export const updateProjectPictureFailure = e => {
  return {
    type: UPDATE_PROJECT_PICTURE_FAILURE,
    payload: e,
    error: true
  }
}