import { call, put, takeEvery, all } from 'redux-saga/effects'
import * as actions from '../actions/userManager'
import * as backend from '../api/backend/index'

function* SelectedtUserProjects({payload}) {
    try {
      const projects = yield call(backend.userManager.fetchSelectedUserProjects, payload)
      yield put(actions.fetchSelectedUserProjectsSuccess({projects}))
    } catch (e) {
      yield put(actions.fetchSelectedUserProjectsFailure(e))
    }
  }

  export function* userManager() {
    yield all([
      takeEvery(actions.FETCH_SELECTED_USER_PROJECTS_REQUEST, SelectedtUserProjects),
    ])
  }
