import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

import doubleArrow from '../../Button/down.svg'
import { UserProfile } from '../UserProfile'
import { AssignedProjects } from '../AssignedProjects'
import { SelectContainer, Wrap, InnerContainer, SelectedItem, IconWrap, DropdownContainer } from './SelectStyles'
import { keyframes } from '@emotion/core'

const ddKeyframes = keyframes`
0% {
  opacity: 0;
  transform: scaleY(0)
}
80% {
  opacity: 0.5;
  transform: scaleY(1.1)
}
100% {
  opacity: 1;
  transform: scaleY(1)
}
`
const Icon = styled.img(({ rotated }) => ({
  width: '0.8rem',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: ` scale(1) translate(-50%, -50%) ${rotated ? 'rotate(0deg)' : 'rotate(90deg)'}`,
}))

const InnerSelect = (props) => {
  const [isEditable, setIsEditable] = useState(props.editable ? props.editable : false)

  useEffect(() => {
    setIsEditable(props.editable)
  }, [props.editable, props.userData.userId])


  return (
    <React.Fragment >
      {props.userData &&
        (
          <Wrap >
            <SelectContainer >
              <InnerContainer  >
                <SelectedItem >
                  {props.innerName}
                </SelectedItem>
                <IconWrap>
                  <Icon src={doubleArrow} onClick={props.click} rotated={props.rotated} />
                </IconWrap>
              </InnerContainer>
            </SelectContainer>

            <DropdownContainer >
              {props.innerName === "User Profile" && props.visibility && (
                <UserProfile
                  onSubmit={values => {
                    props.updateUserRequest(values)
                    setIsEditable(false)
                  }}
                  userData={props.userData}
                  editable={isEditable}
                  updateUserRequest={props.updateUserRequest}
                  fetchSelectedUserRequest={props.fetchSelectedUserRequest}
                >
                </UserProfile>)}
            </DropdownContainer>

            <DropdownContainer >
              {props.innerName === "Assigned Projects" && props.visibility && (
                <AssignedProjects 

                userProjects={props.userProjects && props.userProjects}
                  editable={isEditable}
                  fetchUserProjectsRequest={props.fetchUserProjectsRequest}
                  projects={props.projects}
                  userData={props.userData}
                  fetchProjectsArchivedRequest={props.fetchProjectsArchivedRequest}

                  onSubmit={values => {

                    values.memberProjects.map((value)=> {

                      let invitation
                      let permissionsChecked = value.permissions
                      if(value.assigned==true) {
                        invitation="invite"
                      }
                      else if (value.assigned==false) {
                        invitation="remove"
                        permissionsChecked = ""
                      }
                      props.updateProjectMembersRequest({userId: props.userData.userId, permissions: permissionsChecked,
                                                  projectId: value.projectId, action: invitation})

                    })

                  }}
                >
                </AssignedProjects>)}
            </DropdownContainer>

          </Wrap>
        )}
    </React.Fragment>
  )
}

export { InnerSelect }