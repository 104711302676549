import React, { useState, useEffect } from 'react'
import profilePicture from '../../Project/birdman.png'
import { Field} from 'redux-form'

import { DetailsContainer, FirstSection, SecondSection, ThirdSection, 
  Label, TextWrap, Icon, Text, FourthSection, PersonalDetailsContainer } from './DetailsStyles'
import { SelectThird } from './RenderFields'
import { ThumbDropzoneProfileConnected } from '../../ThumbDropzone/ThumbDropzoneProfile'

const renderField = ({
  input, label, type, meta: { touched, error, warning },
}) => (
    <div >
      <label>{label}</label>
      <div >
        <input className="black-field" {...input} placeholder={label} type={type}
        />
      </div>
    </div>
  )

let PersonalDetails = (props) => {
  const [isEditable, setIsEditable] = useState(props.editable)

  useEffect(() => {
    setIsEditable(props.editable)
  }, [props.editable])

  //Because children do not rerender if the props of the parent change, but if its STATE changes, so i do not get the component to rerender when image changes
  const [image, setImage]=useState('')

  useEffect(() => { setImage(props.userData.avatarURL) }, [props.userData.avatarURL]);

  return (
    <PersonalDetailsContainer>
      {(() => {
        if (isEditable) {
          return (
            <React.Fragment>
              <DetailsContainer>

                <FirstSection>
                  <ThumbDropzoneProfileConnected picture={image ? image : profilePicture}
                    user={props.userData.userId && props.userData.userId}
                  />

                </FirstSection>

                <SecondSection>

                  <TextWrap>
                    <Label>First Name</Label>
                    <Field
                      name="firstName"
                      type="text"
                      component={renderField}
                    />
                  </TextWrap>

                  <TextWrap>
                    <Label>Last Name</Label>
                    <Field
                      name="lastName"
                      type="text"
                      component={renderField}
                    />
                  </TextWrap>

                  <TextWrap>
                    <Label>Role</Label>
                    <Field
                      name="role"
                      component={SelectThird}
                      // width='100%'
                      options={[
                        { label: 'Admin', value: 'Admin' },
                        { label: 'Super User', value: 'SuperUser' },
                        { label: 'User', value: 'User' },
                        { label: 'External', value: 'External' }]}
                    >
                    </Field>
                  </TextWrap>

                </SecondSection>

                <ThirdSection>
                  <TextWrap>
                    <Label>Company</Label>
                    <Field
                      name="company"
                      type="text"
                      component={renderField}
                    />
                  </TextWrap>

                  <TextWrap>
                    <Label>Function</Label>
                    <Field
                      name="function"
                      type="text"
                      component={renderField}
                    />
                  </TextWrap>

                  <TextWrap>
                    <Label>Email</Label>
                    <Field
                      name="email"
                      type="text"
                      component={renderField}
                    />
                  </TextWrap>
                </ThirdSection>
                <FourthSection></FourthSection>

              </DetailsContainer>
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>

              {props.userData &&
                <DetailsContainer>
                  <FirstSection>
                    {props.userData.avatarURL ? <Icon src={props.userData.avatarURL}></Icon>
                      : <Icon src={profilePicture}></Icon>}

                  </FirstSection>

                  <SecondSection>
                    <TextWrap>
                      <Label>First Name</Label>
                      <Text
                        type="text"
                      >{props.userData.firstName}</Text>
                    </TextWrap>

                    <TextWrap>
                      <Label>Last Name</Label>
                      <Text
                        type="text"
                      >{props.userData.lastName}</Text>
                    </TextWrap>
                    <TextWrap>
                      <Label>Role</Label>
                      <Text
                        type="text"
                      >{props.userData.role}</Text>
                    </TextWrap>
                  </SecondSection>

                  <ThirdSection>
                    <TextWrap>
                      <Label>Company</Label>
                      <Text
                        type="text"
                      >{props.userData.company}</Text>
                    </TextWrap>
                    <TextWrap>
                      <Label>Function</Label>
                      <Text
                        type="text"
                      >{props.userData.function}</Text>
                    </TextWrap>
                    <TextWrap>
                      <Label>Email</Label>
                      <Text
                        type="text"
                      >{props.userData.email}</Text>
                    </TextWrap>
                  </ThirdSection>

                  <FourthSection></FourthSection>

                </DetailsContainer>
              }
            </React.Fragment>
          )
        }
      })()}
    </PersonalDetailsContainer>
  )
}

export default PersonalDetails