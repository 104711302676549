import React, { useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import qs from 'query-string'

import { FieldFull } from '../components/Fields'
import { Fieldset, Form } from '../components/Layout'
import Button from '../../Button/Button'
import { onSubmitCreator } from '../helpers/onSubmitCreator'

import {
  fetchUserToValidateRequest,
  validateUserRequest
} from '../../../actions/auth'
import { getUser } from '../../../reducers/user'

const verifySchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(4, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .min(7, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  )
})

const VerifyAccountForm = ({ user, validateUserRequest }) => {
  return (
    <Formik
      initialValues={user}
      validationSchema={verifySchema}
      onSubmit={onSubmitCreator(validateUserRequest)}
    >
      {({ isSubmitting, handleReset, handleSubmit }) => (
        <Form onReset={handleReset} onSubmit={handleSubmit}>
          <Fieldset>
            <h1>Hello {this.state.tempUser.firstName}</h1>
            <p>
              Thank you for joining SpaceAdmin, you can verify your account and
              set your password here.
            </p>
          </Fieldset>
          <Fieldset>
            <FieldFull
              name="firstName"
              label="First Name"
              autoComplete="given-name"
            />
            <FieldFull
              name="lastName"
              label="Last Name"
              autoComplete="family-name"
            />
            <FieldFull name="email" label="Email" autoComplete="email" />
            <FieldFull
              name="password"
              label="Password"
              autoComplete="new-password"
            />
            <FieldFull
              name="passwordConfirmation"
              label="Password"
              autoComplete="new-password"
            />
          </Fieldset>
          <Fieldset>
            <Button data-type="large" type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Creating account' : 'Sign Up'}
            </Button>
          </Fieldset>
        </Form>
      )}
    </Formik>
  )
}

const VerifyAccountFormContainer = ({
  user,
  location,
  fetchUserToValidateRequest,
  validateUserRequest
}) => {
  let { token } = qs.parse(location.search)

  useEffect(() => {
    token && fetchUserToValidateRequest(token)
  }, [token, fetchUserToValidateRequest])

  validateUserRequest = validateUserRequest.bind(this, {
    validationToken: token
  })
  return user ? (
    <VerifyAccountForm user={user} validateUserRequest={validateUserRequest} />
  ) : (
    'Getting your account information'
  )
}

const mapStateToProps = state => {
  return {
    user: getUser(state)
  }
}

const VerifyAccountFormConnected = withRouter(
  connect(
    mapStateToProps,
    {
      fetchUserToValidateRequest,
      validateUserRequest
    }
  )(VerifyAccountFormContainer)
)

export { VerifyAccountForm, VerifyAccountFormConnected }
