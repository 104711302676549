import { put, takeEvery, all } from 'redux-saga/effects'
import * as actions from '../actions/editUser'

export function* editUser({payload}) {
    try {
        yield put(actions.setEditUserSelectionSuccess({payload}))
    } catch (e) {
        yield put(actions.setEditUserSelectionFailure(e))
    }
}


export function* editUserSelection() {
    yield all([
        takeEvery(actions.EDIT_USER_REQUEST, editUser),
    ])
}