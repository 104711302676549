import React, { useState } from 'react'
import styled from '@emotion/styled'

import { TagConnected } from './Tag'
import { TagDropdownConnected } from './TagDropdown'

import { colors, units } from '../../styles'

const TagListContainer = styled.div({
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  margin: '0 -0.1875rem -0.1875rem 0'
})

const Controller = styled.div({
  display: 'flex',

  padding: '0 0.5rem',
  margin: '0 0.1875rem 0.1875rem 0',

  background: colors.darkerGrey,
  borderRadius: units.borderRadius,

  ':hover': {
    filter: 'brightness(2)',
    cursor: 'pointer'
  }
})

const TagList = ({ shotId, tagIds = [] }) => {
  const [dropdown, setDropdown] = useState(false)
  const toggleDropdown = () => {
    setDropdown(!dropdown)
  }
  const closeDropdown = () => {
    setDropdown(false)
  }

  return (
    <TagListContainer onMouseLeave={closeDropdown}>
      {tagIds.length > 0 &&
        tagIds.map(tagId => {
          return <TagConnected key={tagId} tagId={tagId} />
        })}
      <Controller onClick={toggleDropdown}>+</Controller>
      {dropdown && <TagDropdownConnected shotId={shotId} />}
    </TagListContainer>
  )
}

export { TagList }
