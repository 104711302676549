import styled from '@emotion/styled'

import { colors, type } from '../../../styles'

const Label = styled.label({
  display: 'block',
  margin: `1rem 0 0`,
  padding: `0 0 0.5rem 0`,

  ...type.labelBig,
  color: colors.grey
})

export { Label }
