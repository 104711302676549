import React, { useCallback } from 'react'
import { connect } from 'react-redux'

import styled from '@emotion/styled'
import { cover, borderColor, borderWidth } from 'polished'
import { useDropzone } from 'react-dropzone'

import { updateProfilePictureRequest } from '../../actions/auth'
import rocket from './rocket.png'

const Thumbnail = styled.img({
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%)',
  borderRadius: '50%',
  height: '6rem',
  width: '6rem',
  margin: '1rem',
  objectFit: 'cover',
  padding: '0.3rem',
  border: '1px solid #4a4b4c',
})

const DropIcon = styled.div({
  position: 'absolute',
  ...cover(),
  background: 'rgba(255,255,255, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const ThumbDropzoneProfile = ({ picture, updateProfilePictureRequest, user }) => {
  const onDrop = useCallback(
    acceptedFiles => {
        updateProfilePictureRequest({ userId: user, files: acceptedFiles })
    },
    [updateProfilePictureRequest, picture]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Thumbnail src={picture || rocket} alt="" />
      {isDragActive && <DropIcon >DROP</DropIcon>}
    </div>
  )
}

const ThumbDropzoneProfileConnected = connect(
  null,
  {
    updateProfilePictureRequest
  }
)(ThumbDropzoneProfile)

export { ThumbDropzoneProfile, ThumbDropzoneProfileConnected }
