import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'
import * as api from './api/backend/index'

import reducers from './reducers/index.js'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()
const logger = createLogger({
  diff: true,
  duration: true,
  collapsed: true
})

const composeEnhancers = composeWithDevTools({ trace: true })

const middleware =
  process.env.NODE_ENV === 'development'
    ? composeEnhancers(applyMiddleware(sagaMiddleware, logger))
    : applyMiddleware(sagaMiddleware)

const store = createStore(reducers, middleware)

sagaMiddleware.run(rootSaga, api)

if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
  window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function() {}
}

export default store
