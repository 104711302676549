import { colors } from '../../../styles'
import styled from '@emotion/styled'
import React, { useState, useEffect } from 'react'
import { Field } from 'redux-form'
import { ButtonChoose } from './RenderFields'
import { ThumbDropzoneProjectPictureConnected } from '../../ThumbDropzone/ThumbDropzoneProjectPicture'
import profilePicture from '../../Project/birdman.png'

const renderField = ({
    input, label, type, meta: { touched, error, warning },
}) => (
        <div >
            <label>{label}</label>
            <div >
                <input className="black-field-projects" {...input} placeholder={label} type={type} style={{fontSize: '0.9rem', fontWeight: 'bold'}}
                />
            </div>
        </div>
    )

const ProjectDetails = (props) => {

    const FullPhoto = styled.div({
        position: 'relative',
        width: '100%',
        height: '60%',
        background: colors.grey,
    })

    const Row = styled.div({
        position: 'relative',
        width: '100%',
        background: colors.dark,
        display: 'flex',
        flexWrap: 'wrap'
    })

    const Section = styled.div({
        position: 'relative',
        width: '50%',
        background: colors.dark,
        paddingLeft: '0.2rem',
        paddingTop: '1rem',
        paddingBottom: '1rem'
    })

    const Container = styled.div({
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        height: '70rem'
    })

    const Label = styled.div({
        fontSize: "0.9rem",
        fontStyle: "bold",
        paddingBottom: '0.5rem'
    })

    const TextWrap = styled.div({
        position: 'relative',
        width:'50%',
        height: '5.5rem',
        padding: '0.5rem',
        paddingLeft: '0.8rem',
        paddingRight: '0.8rem',
    })

    const TextWrapReadonly = styled.div({
        position: 'relative',
        width:'100%',
        height: '5.5rem',
        padding: '0.5rem',
        paddingLeft: '0.8rem',
        paddingRight: '0.8rem'
    })

    const Text = styled.div({
        position: 'relative',
        fontWeight: "bold",
        fontSize: "1rem",
        color: '#DCDCDC',
        paddingTop: '0.2rem'
    })
    
    const Icon = styled.img(() => ({
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%)',
        height: '100%',
        width: '100%',
        objectFit: 'cover'
      }))

      const [image, setImage]=useState(props.singleProject.thumbnailURL )
      useEffect(() => { setImage(props.singleProject.thumbnailURL) }, [props.singleProject.thumbnailURL]);

    return (
        <React.Fragment>
            {props.singleProject && (

                <React.Fragment >
                    {props.isEditable ? (
                        <Container>
                            <FullPhoto>
                                <ThumbDropzoneProjectPictureConnected picture={image ? image : profilePicture}
                                projectId={props.singleProject&&props.singleProject.projectId} />
                            </FullPhoto>

                            <Row>
                                <Section>

                                    <TextWrap >
                                        <Label>Project Title</Label>
                                        <Field
                                            name="title"
                                            type="text"
                                            component={renderField}
                                        />
                                    </TextWrap>


                                </Section>

                                <Section>
                                    <TextWrap>
                                        <Label>Project Producer</Label>
                                        <Field
                                            name="producer"
                                            type="text"
                                            component={renderField}
                                        />
                                    </TextWrap>

                                </Section>

                            </Row>

                            <Row>
                                <Section>

                                    <TextWrap>
                                        <Label>Project Director</Label>
                                        <Field
                                            name="director"
                                            type="text"
                                            component={renderField}
                                        />
                                    </TextWrap>

                                </Section>

                                <Section>

                                    <TextWrap>
                                        <Label>Project Editor</Label>
                                        <Field
                                            name="editor"
                                            type="text"
                                            component={renderField}
                                        />
                                    </TextWrap>

                                </Section>

                            </Row>
                            <Row>
                            <Section>
                                    <TextWrap >
                                        <Label>Project Type</Label>
                                        <Field
                                            name="projectType"
                                            type="text"
                                            component={renderField}
                                        />
                                    </TextWrap>

                                    <TextWrap>
                                        <Label>VFX Company</Label>
                                        <Field
                                            name="vfxCompany"
                                            type="text"
                                            component={renderField}
                                        />
                                    </TextWrap>
                                </Section>

                                <Section>
                                    <TextWrap>
                                        <Label>Archived</Label>
                                        <Field
                                            name="isArchived"
                                            component={ButtonChoose}
                                        />
                                    </TextWrap>

                                </Section>
                            </Row>

                        </Container>
                    ) : (
                            <Container>
                                <FullPhoto>
                                <Icon src={image ? image : profilePicture}></Icon>

                                </FullPhoto>

                                <Row>
                                    <Section>

                                        <TextWrapReadonly >
                                            <Label>Project Title</Label>
                                            <Text style={{fontSize: '1.4rem'}}>{props.singleProject.title}</Text>
                                        </TextWrapReadonly>

                                        <TextWrapReadonly>
                                            <Label>Project Director</Label>
                                            <Text>{props.singleProject.director}</Text>
                                        </TextWrapReadonly>

                                    </Section>

                                    <Section>
                                        <TextWrapReadonly>
                                            <Label>Project Producer</Label>
                                            <Text>{props.singleProject.producer}</Text>
                                        </TextWrapReadonly>

                                        <TextWrapReadonly>
                                            <Label>Project Editor</Label>
                                            <Text>{props.singleProject.editor}</Text>
                                        </TextWrapReadonly>

                                    </Section>

                                </Row>

                                <Row>
                                <Section>
                                        <TextWrapReadonly >
                                            <Label>Project Type</Label>
                                            <Text>{props.singleProject.projectType}</Text>
                                        </TextWrapReadonly>

                                        <TextWrapReadonly>
                                            <Label>VFX Company</Label>
                                            <Text>{props.singleProject.company}</Text>
                                        </TextWrapReadonly>
                                    </Section>

                                    <Section>
                                        <TextWrapReadonly>
                                            <Label>Archived</Label>
                                            <Text>{props.singleProject.isArchived == false ? 'No' : 'Yes'}</Text>
                                        </TextWrapReadonly>

                                    </Section>
                                </Row>

                            </Container>
                        )
                    }
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export { ProjectDetails }
