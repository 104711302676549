export const FETCH_SELECTED_USER_PROJECTS_REQUEST = 'FETCH_SELECTED_USER_PROJECTS_REQUEST'
export const FETCH_SELECTED_USER_PROJECTS_SUCCESS = 'FETCH_SELECTED_USER_PROJECTS_SUCCESS'
export const FETCH_SELECTED_USER_PROJECTS_FAILURE = 'FETCH_SELECTED_USER_PROJECTS_FAILURE'

export const fetchSelectedUserProjectsRequest = userId => {
    return {
        type: FETCH_SELECTED_USER_PROJECTS_REQUEST,
        payload: { userId }
    }
}

export const fetchSelectedUserProjectsSuccess = projects => {
    return {
        type: FETCH_SELECTED_USER_PROJECTS_SUCCESS,
        payload: { projects }
    }
}

export const fetchSelectedUserProjectsFailure = e => {
    return {
      type: FETCH_SELECTED_USER_PROJECTS_FAILURE,
      payload: e,
      error: true
    }
  }