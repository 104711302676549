import React from 'react'
import { withRouter, Route, Switch, Redirect } from 'react-router'
import { connect } from 'react-redux'
import styled from '@emotion/styled'

import { AuthConnected } from '../Auth/Auth'
import { ProjectListConnected } from '../ProjectList/ProjectList'
import { ProjectFormConnected } from '../Forms/types/ProjectForm'
import { CreateUserFormConnected } from '../Forms/types/CreateUserForm'
import { ShotListConnected } from '../Shot/ShotList'
import { ShotFormConnected } from '../Forms/types/ShotForm'

import { layout } from '../../styles'
import { isAuthenticated } from '../../reducers/auth'

import UserManager from '../UserManager/UserManager'
import UserAccount from '../UserAccount/UserAccount'
import ProjectsManager from '../ProjectsManager/ProjectsManager'
import SiteManager from '../SiteManager/SiteManager'
import TechSpecs from '../TechSpecs/TechSpecs'

const Container = styled.main({
  display: 'flex',
  alignItems: 'flex-start',
  height: '100%',
  overflow: 'scroll',
  flex: `1 1 ${layout.right}`,
  minWidth: '600px'
})

const MainRouter = ({ isAuthenticated, location: { pathname = '' } }) => {
  return (
    <Container>
      <Switch>
        {!isAuthenticated &&
          pathname !== '/auth/login' &&
          pathname !== '/auth/retrieve' &&
          pathname !== '/auth/reset' && <Redirect to="/auth/login" />}

        <Route path="/auth" component={AuthConnected} />

        <Route path="/project/all" component={ProjectListConnected} />

        <Route path="/project/create" component={ProjectFormConnected} />

        <Route path="/user/create" component={CreateUserFormConnected} />

        <Route
          path="/project/:projectId(\d+)/shot/create"
          component={ShotFormConnected}
        />

        <Route
          path="/project/:projectId(\d+)/edit"
          component={ProjectFormConnected}
        />

        <Route
          path="/project/:projectId(\d+)/shot/all"
          component={ShotListConnected}
        />

        <Route
          path="/project/:projectId(\d+)/shot/:shotId(\d+)/edit"
          component={ShotFormConnected}
        />

        <Route path="/project/:projectId/techspecs/:extraInfo" component={TechSpecs} />
        <Route path="/project/:projectId/techspecs" component={TechSpecs} />

        <Route path="/users/:userId" component={UserManager} />

        <Route path="/users" component={UserManager} />

        <Route path="/account" component={UserAccount} />

        <Route path='/projects/manager/:projectId/:extraInfo' component={ProjectsManager} />
        <Route path='/projects/manager/:projectId' component={ProjectsManager} />
        <Route path="/projects/manager" component={ProjectsManager} />

        <Route path="/sites/:extraInfo" component={SiteManager} />
        <Route path="/sites" component={SiteManager} />
        {/* <Route path="/project/#/techspecs" component={TechSpecs} /> */}

        <Redirect to="/project/all" />
        <Redirect to="/users/" />
        <Redirect to="/account" />
        <Redirect to="/projects/manager" />
        <Redirect to="/sites" />
        {/* <Redirect to="/project/#/techspecs" /> */}

      </Switch>
    </Container>
  )
}

const mapStateToProps = (state, { location }) => {
  return {
    isAuthenticated: isAuthenticated(state)
  }
}

const MainRouterConnected = withRouter(connect(mapStateToProps)(MainRouter))

export { MainRouterConnected }
