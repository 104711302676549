import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import { ui } from './ui'
import { entities } from './entities'

const reducers = combineReducers({
  ui,
  entities,
  form: formReducer
})

export default reducers
