import React, { useState, useEffect } from 'react'
import { Field, FieldArray } from 'redux-form'
import { SelectOptionUsers, SelectOption } from './RenderFields'
import Button from '../../Button/Button'
import {
    RowTitles, Row, Label, Section, LabelSection, Text, CloseIcon,
} from './DetailsComponentsStyles'
import { colors } from '../../../styles'
import closeIcon from './closeIcon.svg'

const RenderMembers = ({ fields, allUsers, initial }) => {

    let dropdownUsers = Object.entries(allUsers).map(([key, value]) => {
        let dropdownUser = {}
        dropdownUser.value = value.userId
        dropdownUser.label = value.firstName + ' ' + value.lastName
        return dropdownUser
    })

    let allUsersValues = Object.entries(allUsers).map(([key, value]) => {
        return value
    })

    dropdownUsers.sort((a, b) => (a.value > b.value) ? 1 : -1)

    let [items, setItems] = useState(fields ? fields : '')

    useEffect(() => {
        setItems(fields)
    }, [fields == undefined])

    if (fields !== undefined) {
        return (<React.Fragment>

            {fields.map((members, index) => {

                return (

                    <div style={{
                        position: 'relative',
                        height: '20%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        background: colors.night,
                        borderBottom: '0.0625rem solid #4a4b4c',
                    }}>
                        <Section>
                            <Field
                                name={`${members}.userId`}
                                component={SelectOptionUsers}
                                options={dropdownUsers}
                                allUsersValues={allUsersValues && allUsersValues}
                            >
                            </Field>
                        </Section>

                        <Section>
                            <Field
                                name={`${members}.permissions`}
                                component={SelectOption}
                                options={[
                                    { label: 'Owner', value: 'Owner' },
                                    { label: 'Supervisor', value: 'Supervisor' },
                                    { label: 'Artist', value: 'Artist' },
                                    { label: 'External', value: 'External' }]}
                            >
                            </Field>
                        </Section>
                        <Section>
                        </Section>
                        <Section><CloseIcon src={closeIcon} onClick={() => fields.remove(index)}></CloseIcon></Section>
                    </div>
                )
            })}
            <Row>
                <Section><Button type='button' onClick={() => fields.push({})}
                    style={{ height: '2.5rem', fontWeight: 'bold' }}>Assign User
             </Button></Section>
                <Section></Section>
                <Section></Section>
                <Section></Section>
            </Row>

        </React.Fragment>)
    }
    else return <div>""</div>
}

const AssignUsers = (props) => {

    if (props.singleProject) {
        return (
            <React.Fragment >
                {props.isEditable ? (
                    <React.Fragment style={{paddingLeft: '1rem'}}>

                        {props.singleProject.members.length !== 0 ? (
                            <RowTitles style={{paddingLeft: '0.2rem'}}>
                                <LabelSection><Label>Selected User</Label></LabelSection>
                                <LabelSection><Label>Role</Label></LabelSection>
                                <LabelSection><Label></Label></LabelSection>
                                <LabelSection></LabelSection>
                            </RowTitles>
                        ) : <div></div>}


                        <FieldArray name="members" component={RenderMembers} allUsers={props.users && props.users} initial={props.singleProject.members} />

                    </React.Fragment>
                ) : (
                        <React.Fragment >
                            {props.singleProject.members.length !== 0 ? (
                                <RowTitles style={{paddingLeft: '0.2rem'}}>
                                    <LabelSection><Label>Selected User</Label></LabelSection>
                                    <LabelSection><Label>Permissions</Label></LabelSection>
                                    <LabelSection><Label></Label></LabelSection>
                                    <LabelSection></LabelSection>
                                </RowTitles>
                            ) : <div style={{ padding: '1rem' }}>There are currently no users</div>}

                            {Object.entries(props.singleProject.members).map(([key, value]) => {
                                return (
                                    <React.Fragment key={value.userId} >

                                        <Row style={{paddingLeft: '0.2rem'}}>
                                            <Section >
                                                <Text>{value.firstName + ' ' + value.lastName}</Text>
                                            </Section>
                                            <Section><Text>{value.permissions}</Text></Section>
                                            <Section></Section>
                                            <Section></Section>
                                        </Row>
                                    </React.Fragment>
                                )
                            })}
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        )
    }
    else {
        return <div></div>
    }
}

export { AssignUsers }
