import React, { useState, useEffect } from 'react'
import { DetailsContainer, FirstSection, SecondSection, 
  ThirdSection, Label, TextWrap, Text, FourthSection, PreferenceDetailsContainer } from './DetailsStyles'

import { Field} from 'redux-form'
import { ButtonChoose } from './RenderFields'
import { SelectThird } from './RenderFields'

let PreferencesDetails = (props) => {

  const [isEditable, setIsEditable] = useState(props.editable)

  useEffect(() => {
    setIsEditable(props.editable)
  }, [props.editable])

  return (
    <PreferenceDetailsContainer>
      {(() => {
        if (isEditable) {
          return (
            <React.Fragment>
              <DetailsContainer>

                <FirstSection>
                </FirstSection>

                <SecondSection>
                  <TextWrap>
                    <Label>Receives Changelogs</Label>
                    <Field
                      name="prefsReceivesChangeLog"
                      component={ButtonChoose}
                    />
                  </TextWrap>

                  <TextWrap>
                    <Label>Custom Field 1</Label>
                    <Field
                      name="prefsCustomFieldA"
                      component={SelectThird}
                      options={[
                        { label: 'Shot Length', value: 'Shot Length' },
                        { label: 'Scene Number', value: 'Scene Number' },
                        { label: 'Pull Version', value: 'Pull Version' },
                        { label: 'Admin Note', value: 'Admin Note' }]}
                    >
                    </Field>
                  </TextWrap>

                  <TextWrap>
                    <Label>Custom Field 3</Label>
                    <Field
                      name="prefsCustomFieldC"
                      component={SelectThird}
                      options={[
                        { label: 'Shot Length', value: 'Shot Length' },
                        { label: 'Scene Number', value: 'Scene Number' },
                        { label: 'Pull Version', value: 'Pull Version' },
                        { label: 'Admin Note', value: 'Admin Note' }]}
                    >
                    </Field>
                  </TextWrap>
                </SecondSection>

                <ThirdSection>
                  <TextWrap>
                    <Label>Date/Time Formatting</Label>
                    <Field
                      name="prefsDateTimeFormat"
                      component={SelectThird}
                      options={[
                        { label: 'DD-MM-YYYY HH:mm:ss', value: 'DD-MM-YYYY HH:mm:ss' },
                        { label: 'MM/DD/YYYY hh:mm:ss', value: 'MM/DD/YYYY hh:mm:ss' },
                        { label: 'YYYY-MM-DD HH:mm:ss', value: 'YYYY-MM-DD HH:mm:ss' },
                        ]}
                    >
                    </Field>
                  </TextWrap>
                  <TextWrap>
                    <Label>Custom Field 2</Label>
                    <Field
                      name="prefsCustomFieldB"
                      component={SelectThird}
                      // width='100%'
                      className='black-select'
                      options={[
                        { label: 'Shot Length', value: 'Shot Length' },
                        { label: 'Scene Number', value: 'Scene Number' },
                        { label: 'Pull Version', value: 'Pull Version' },
                        { label: 'Admin Note', value: 'Admin Note' }]}
                    >
                    </Field>
                  </TextWrap>

                </ThirdSection>
                <FourthSection></FourthSection>
              </DetailsContainer>
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>

              {props.userData &&
                <DetailsContainer>
                  <FirstSection>
                  </FirstSection>

                  <SecondSection>
                    <TextWrap>
                      <Label>Receives Changelogs</Label>
                      <Text
                        type="text"
                      >{props.userData.prefsReceivesChangeLog == false ? 'No' : 'Yes'}</Text>
                    </TextWrap>

                    <TextWrap>
                      <Label>Custom Field 1</Label>
                      <Text
                        type="text"
                      >{props.userData.prefsCustomFieldA}</Text>
                    </TextWrap>

                    <TextWrap>
                      <Label>Custom Field 3</Label>
                      <Text
                        type="text"
                      >{props.userData.prefsCustomFieldC}</Text>
                    </TextWrap>
                  </SecondSection>

                  <ThirdSection>
                    <TextWrap>
                      <Label>Date/Time Formatting</Label>
                      <Text
                        type="text"
                      >{props.userData.prefsDateTimeFormat}</Text>
                    </TextWrap>

                    <TextWrap>
                      <Label>Custom Field 2</Label>
                      <Text
                        type="text"
                      >{props.userData.prefsCustomFieldB}</Text>
                    </TextWrap>

                  </ThirdSection>
                  <FourthSection></FourthSection>
                </DetailsContainer>
              }
            </React.Fragment>
          )
        }
      })()}
   </PreferenceDetailsContainer>
  )
}

export { PreferencesDetails }
