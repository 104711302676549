import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'

import { Spinner } from '../Spinner/Spinner'

import { colors, units, type } from '../../styles'
import { fetchStatusesRequest } from '../../actions/status'
import { getStatuses } from '../../reducers/status'

const Container = styled.div({
  position: 'absolute',
  width: '100%',
  zIndex: '100',
  padding: '0.5rem 0',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: units.borderRadius,
  background: colors.night
})

const Item = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '0.5rem 0.5rem',
  cursor: 'pointer',
  '&:hover': {
    filter: 'brightness(1.2)'
  }
})

const SubHeader = styled.h3({
  ...type.headerNavActive
})

const ColorSquare = styled.div(({ color }) => ({
  width: '0.5rem',
  height: '0.5rem',
  margin: '0 0.5rem 0 0',
  background: color || colors.grey
}))

const Dropdown = ({
  statuses = [],
  fetchStatusesRequest = () => {},
  statusHandler
}) => {
  useEffect(() => {
    fetchStatusesRequest()
  }, [fetchStatusesRequest])

  return (
    <Container>
      {statuses.length ? (
        statuses.map((status, key) => {
          let handleClick = () => {
            statusHandler(status.statusId)
          }
          return (
            <Item key={key} onClick={handleClick}>
              <ColorSquare color={status.color} />
              <SubHeader>{status.title || 'loading'} </SubHeader>
            </Item>
          )
        })
      ) : (
        <Spinner />
      )}
    </Container>
  )
}

const mapStateToProps = state => {
  let statuses = getStatuses(state)
  return { statuses }
}

const DropdownConnected = connect(
  mapStateToProps,
  { fetchStatusesRequest }
)(Dropdown)

export { Dropdown, DropdownConnected }
