import { call, all, put, takeEvery } from 'redux-saga/effects'

import * as actions from '../actions/techSpecs'
import * as backend from '../api/backend/index'

function* fetchTechSpecs({ payload }) {
  
  try {
    const techSpecs = yield call(backend.techSpecs.fetchTechSpecs, payload)
    yield put(actions.fetchTechSpecsSuccess({techSpecs}))
  } catch (e) {
    yield put(actions.fetchTechSpecsFailure(e))
  }
}

function* createTechSpecs({ payload }) {
  try {
    const techSpecs = yield call(backend.techSpecs.createTechSpecs, payload)
    yield put(actions.createTechSpecsSuccess(techSpecs))
  } catch (e) {
    yield put(actions.createTechSpecsFailure(e))
  }
}

function* updateTechSpecs({ payload }) {
  try {
    const techSpecs = yield call(backend.techSpecs.updateTechSpecs, payload)
    yield put(actions.updateTechSpecsSuccess(techSpecs))
  } catch (e) {
    yield put(actions.updateTechSpecsFailure(e))
  }
}

function* deleteTechSpecs({ payload }) {
  try {
    const techSpecs = yield call(backend.techSpecs.deleteTechSpecs, payload)
    yield put(actions.deleteTechSpecsSuccess(techSpecs))
  } catch (e) {
    yield put(actions.deleteTechSpecsFailure(e))
  }
}

function* reorderTechSpecs({ payload }) {
  try {
    const techSpecs = yield call(backend.techSpecs.reorderTechSpecs, payload)
    yield put(actions.reorderTechSpecsSuccess(techSpecs))
  } catch (e) {
    yield put(actions.reorderTechSpecsFailure(e))
  }
}

export function* setTechSpec({payload}) {
  try {
      yield put(actions.setTechSpecsSuccess({payload}))
  } catch (e) {
      yield put(actions.setTechSpecsFailure(e))
  }
}

export function* techSpecsRootSaga() {
  yield all([
    yield takeEvery(actions.FETCH_TECHSPECS_REQUEST, fetchTechSpecs),
    yield takeEvery(actions.CREATE_TECHSPECS_REQUEST, createTechSpecs),
    yield takeEvery(actions.UPDATE_TECHSPECS_REQUEST, updateTechSpecs),
    yield takeEvery(actions.DELETE_TECHSPECS_REQUEST, deleteTechSpecs),
    yield takeEvery(actions.REORDER_TECHSPECS_REQUEST, reorderTechSpecs),
    yield takeEvery(actions.SET_TECHSPECS_REQUEST, setTechSpec)
  ])
}
