import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { fetchFullProjectRequest, updateProjectRequest } from '../../actions/project'
import { updateTagRequest, createTagRequest, deleteTagRequest, fetchTagsRequest } from '../../actions/tag'
import { updateEpisodeRequest, createEpisodeRequest, deleteEpisodeRequest } from '../../actions/episode'
import { createProjectStatusRequest, updateProjectStatusRequest, deleteProjectStatusRequest, fetchStatusesRequest } from '../../actions/status'
import { updateProjectMembersRequest } from '../../actions/project'

import { getSingleProject } from '../../reducers/project'
import { getStatuses } from '../../reducers/status'
import { getTags } from '../../reducers/tag'
import { getAllUserIds } from '../../reducers/user'

import { Dropdowns } from './Dropdowns'
import { ProjectsManagerContainer, BorderHeader, Header, HeaderItem } from './ProjectManagerStyles'
import projectManagerSubmit from './projectManagerSubmit'

import { ProjectDetails } from './DetailsComponents/ProjectDetails'
import { Episodes } from './DetailsComponents/Episodes'
import { AssignUsers } from './DetailsComponents/AssignUsers'
import { Tags } from './DetailsComponents/Tags'
import { DropdownStatuses } from './DropdownStatuses'

let ProjectsManager = (props) => {

    const [projectId, setProjectId] = useState(props.match.params.projectId)
    const [wholeProject, setWholeProject] = useState(props.singleProject)

    //added the project in state, so that it can rerender on every change when deleting, updating, creating
    useEffect(() => {
        setWholeProject(props.singleProject)
        if (projectId !== undefined) {
            props.fetchFullProjectRequest(projectId)
        }
    }, [projectId, props.fetchFullProjectRequest, props.match.params.projectId, wholeProject])

    let editingMode = false;
    if (props.match.params.extraInfo === 'edit') editingMode = true;

    const [isEditable, setIsEditable] = useState(props.match.params.extraInfo === 'edit' ? true : false)
    useEffect(() => {
        setIsEditable(props.match.params.extraInfo === 'edit' ? true : false)
    }, [props.match.params.extraInfo, props.singleProject && props.singleProject.projectId])

    useEffect(() => {
        setProjectId(props.match.params.projectId)
        if (projectId !== undefined) {
            props.fetchFullProjectRequest(projectId)
        }
    }, [projectId, props.fetchFullProjectRequest, props.match.params.projectId, isEditable,])

    useEffect(() => {
        const { initialize } = props

        if (props.singleProject !== undefined) {
            props.singleProject.globalTags = props.globalTags
            initialize(props.singleProject)
        }
    }, [projectId, props.singleProject])

    useEffect(() => {
        props.fetchTagsRequest()
    }, [props.fetchTagsRequest])

    useEffect(() => {
        props.fetchStatusesRequest()
    }, [props.fetchStatusesRequest])

    const { handleSubmit } = props

    return (
        <React.Fragment>
            {/* {(() => {
                if (props.singleProject !== undefined) {
                    return (
                        <BorderHeader >
                            <Header  >
                                <HeaderItem >{props.singleProject.title}</HeaderItem>
                            </Header>
                        </BorderHeader>
                    )
                } else {
                    return (<div></div>)
                }
            })()} */}
            {(() => {
                if (props.singleProject !== undefined) {
                    return (
                        <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                                                                <BorderHeader styel={{position:'fixed'}}>
                                    <Header  >
                                        <HeaderItem >{props.singleProject.title}</HeaderItem>
                                    </Header>
                                </BorderHeader>

                            {/* <BorderHeader >
                                <Header  >
                                    <HeaderItem >{props.singleProject.title}</HeaderItem>
                                </Header>
                            </BorderHeader> */}

                            <ProjectsManagerContainer >
                                <form onSubmit={handleSubmit} >
                                    <Helmet>
                                        <title>SA User Management</title>
                                    </Helmet>

                                    <BorderHeader >
                                    <Header  styel={{position:'fixed'}}>
                                        <HeaderItem >{props.singleProject.title}</HeaderItem>
                                    </Header>
                                </BorderHeader>

                                    <Dropdowns innerName={'Project Details'}>
                                        <ProjectDetails
                                            singleProject={props.singleProject && props.singleProject}
                                            isEditable={isEditable}
                                            updateProjectRequest={props.updateProjectRequest}>
                                        </ProjectDetails>
                                    </Dropdowns>

                                    <Dropdowns innerName={'Episodes'}>
                                        <Episodes
                                            singleProject={props.singleProject && props.singleProject}
                                            isEditable={isEditable}
                                            createEpisodeRequest={props.createEpisodeRequest}
                                            updateEpisodeRequest={props.updateEpisodeRequest}
                                            deleteEpisodeRequest={props.deleteEpisodeRequest}
                                        ></Episodes>
                                    </Dropdowns>

                                    <Dropdowns innerName={'Statuses'}>
                                        <React.Fragment>
                                            <DropdownStatuses innerName={'Shots/Assets statuses'}
                                                singleProject={props.singleProject && props.singleProject}
                                                isEditable={isEditable}
                                                createProjectStatusRequest={props.createProjectStatusRequest}
                                                updateProjectStatusRequest={props.updateProjectStatusRequest}
                                                deleteProjectStatusRequest={props.deleteProjectStatusRequest}
                                                globalStatuses={props.globalStatuses}
                                            >
                                            </DropdownStatuses>

                                            <DropdownStatuses innerName={'Version statuses'}
                                                singleProject={props.singleProject && props.singleProject}
                                                isEditable={isEditable}
                                                createProjectStatusRequest={props.createProjectStatusRequest}
                                                updateProjectStatusRequest={props.updateProjectStatusRequest}
                                                deleteProjectStatusRequest={props.deleteProjectStatusRequest}
                                                globalStatuses={props.globalStatuses && props.globalStatuses}
                                            >
                                            </DropdownStatuses>
                                        </React.Fragment>
                                    </Dropdowns>

                                    <Dropdowns innerName={'Tags'}>
                                        <Tags
                                            singleProject={props.singleProject && props.singleProject}
                                            isEditable={isEditable}
                                            createTagRequest={props.createTagRequest}
                                            updateTagRequest={props.updateTagRequest}
                                            deleteTagRequest={props.deleteTagRequest}
                                            globalTags={props.globalTags}
                                        ></Tags>
                                    </Dropdowns>

                                    <Dropdowns innerName={'Assign Users'}>
                                        <AssignUsers
                                            singleProject={props.singleProject && props.singleProject}
                                            isEditable={isEditable}
                                            users={props.users && props.users}
                                            updateProjectMembersRequest={props.updateProjectMembersRequest}
                                        ></AssignUsers>
                                    </Dropdowns>
                                </form>
                            </ProjectsManagerContainer>
                        </div>)
                } else {
                    return (<ProjectsManagerContainer ><div>Select a project in the menu on the left</div></ProjectsManagerContainer>)
                }
            })()}
        </React.Fragment>
    )
}
const mapStateToProps = (state, props) => {

    if (props && props.match.params.projectId !== undefined) {
        return {
            singleProject: getSingleProject(state, props.match.params.projectId),
            globalTags: getTags(state),
            users: getAllUserIds(state),
            globalStatuses: getStatuses(state)
        }
    }
    else return {
        globalTags: getTags(state),
        users: getAllUserIds(state),
        globalStatuses: getStatuses(state)
    }
}

ProjectsManager = reduxForm({
    form: 'projectManagerForm',
    onSubmit: projectManagerSubmit,

})(ProjectsManager)

ProjectsManager = connect(mapStateToProps, {
    fetchFullProjectRequest,
    updateTagRequest,
    createTagRequest,
    deleteTagRequest,
    updateEpisodeRequest,
    createEpisodeRequest,
    deleteEpisodeRequest,
    fetchTagsRequest,
    createProjectStatusRequest,
    updateProjectStatusRequest,
    deleteProjectStatusRequest,
    updateProjectRequest,
    fetchStatusesRequest,
    updateProjectMembersRequest
})(ProjectsManager)

export default ProjectsManager 