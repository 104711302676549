import React, { useEffect } from 'react'
import { FieldArray } from 'redux-form'
import { Dropdowns } from './DropdownsReadOnly'
import { DropdownsEditable } from './DropdownsEditable'
import { colors } from '../../styles'
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const RenderEditableSpecs = ({ fields, propsMatch, allTechSpecs, isCreateSpecs, isClicked, messagesEndRef }) => {

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        fields.move(result.source.index, result.destination.index)
    }

    const getListStyle = () => ({
        position: 'relative',
        height: '20%',
        width: '100%',
        background: colors.night,
    });

    useEffect(() => {
        if (isCreateSpecs == 1 || isClicked == 1) {
            fields.push({})
            scrollToBottom()
        }
    }, [isCreateSpecs, isClicked])


    //if i did not put fields.push as a dependency, it was scrolling to the last item before the push item
    useEffect(() => {
        if (isCreateSpecs == 1 || isClicked == 1) {
            scrollToBottom()
        }
    }, [isCreateSpecs==0, fields.push])

    if (fields !== undefined) {

        return (<React.Fragment>
            <DragDropContext onDragEnd={onDragEnd} style={{
                position: 'relative',
                width: '100%',
                background: colors.night,
            }}>

                <Droppable droppableId="droppable"
                    style={{
                        position: 'relative',
                        width: '100%',
                        background: colors.night,
                    }}>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle()}
                        >
                            {fields.map((singleSpec, index) => {

                                return (
                                    <React.Fragment>
                                        <DropdownsEditable key={index} singleSpec={singleSpec} index={index} isClicked={isClicked}
                                            propsMatch={propsMatch} fields={fields} index={index} allTechSpecs={allTechSpecs} />
                                    </React.Fragment>
                                )
                            })}
                            {provided.placeholder}
                        </div>)}
                </Droppable>

            </DragDropContext>

        </React.Fragment>)
    }
    else return <div>""</div>
}

let MiddleComponent = (props) => {

    const messagesEndRef = React.useRef(null)

    let isClicked = 0
    if (props.isCreateSpecs == 1) {
        isClicked = 1
    }
    return (
        <React.Fragment>
            <React.Fragment >
                {props.isEditable ? (
                    <React.Fragment>
                        <FieldArray
                            name="techSpecsArray"
                            component={RenderEditableSpecs}
                            propsMatch={props.propsMatch}
                            allTechSpecs={props.allTechSpecs && props.allTechSpecs}
                            isCreateSpecs={props.isCreateSpecs}
                            isClicked={isClicked}
                            messagesEndRef={messagesEndRef}
                        />
                    <div ref={messagesEndRef} />

                    </React.Fragment>
                ) : (
                        <React.Fragment>
                            {props.allTechSpecs.map((oneSpec) => {
                                return (
                                    <Dropdowns oneSpec={oneSpec} key={oneSpec.techSpecId} />
                                )

                            })}
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        </React.Fragment>
    )
}

export { MiddleComponent }