import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Dropdowns } from '../ProjectsManager/Dropdowns'
import { DropdownSub } from '../ProjectsManager/DropdownSub'
import { SiteManagerContainer, BorderHeader, Header, HeaderItem } from './SiteManagerStyles'
import { DropdownSiteDetails } from './DropdownSiteDetails'
import Site  from './DetailsComponents/Site'
import Company  from './DetailsComponents/Company'
import Contact  from './DetailsComponents/Contact'
import Statuses from './Statuses'
import Tags from './Tags'
import Team from './DetailsComponents/Team'
import DefaultStatuses from './DetailsComponents/Statuses'
import DefaultTags from './DetailsComponents/DefaultTags'
import { DropdownProjectDefaults } from './DropdownProjectDefaults'
import { fetchCurrentSiteRequest, updateSitesRequest } from '../../actions/sites'
import { getCurrentSite } from '../../reducers/site'

let SiteManager = (props) => {
    const [isEditable, setIsEditable] = useState(props.match.params.extraInfo === 'edit' ? true : false)

    useEffect(() => {
        setIsEditable(props.match.params.extraInfo === 'edit' ? true : false)
    }, [props.match.params.extraInfo, props.site && props.site.siteId])

    useEffect(() => {
        props.fetchCurrentSiteRequest()
    }, [props.fetchCurrentSiteRequest])

    return (
        <React.Fragment>
            {(() => {
                if (props.site !== undefined) {
                    return (
                        <SiteManagerContainer >
                            <React.Fragment >
                                <Helmet>
                                    <title>SA Site Management</title>
                                </Helmet>

                                <BorderHeader>
                                    <Header>
                                        <HeaderItem>{props.site.siteName}</HeaderItem>
                                    </Header>
                                </BorderHeader>

                                <Dropdowns innerName={'Site Details'}>
                                    <DropdownSiteDetails site={props.site} isEditable={isEditable} updateSitesRequest={updateSitesRequest}></DropdownSiteDetails>
                                    {/* <DropdownSub innerName={'Site'}>
                                        <Site site={props.site} isEditable={isEditable} updateSitesRequest={updateSitesRequest}></Site>
                                    </DropdownSub>
                                    <DropdownSub innerName={'Company'}>
                                    <Company site={props.site} isEditable={isEditable} updateSitesRequest={updateSitesRequest}></Company>
                                    </DropdownSub>
                                    <DropdownSub innerName={'Contact'}>
                                        <Contact site={props.site} isEditable={isEditable} updateSitesRequest={updateSitesRequest}></Contact>
                                    </DropdownSub> */}
                                </Dropdowns>

                                <Dropdowns innerName={'Statuses'}>
                                    <Statuses site={props.site} isEditable={isEditable} ></Statuses>
                                </Dropdowns>

                                <Dropdowns innerName={'Tags'}>
                                    <Tags site={props.site} isEditable={isEditable} ></Tags>
                                </Dropdowns>

                                <Dropdowns innerName={'Project Defaults'}>
                                    <DropdownSub innerName={'Team'}>
                                        <Team projectDefaultUsers={props.site.projectDefaultUsers} isEditable={isEditable} ></Team>
                                    </DropdownSub>
                                    <DropdownSub innerName={'Statuses'}>
                                        <DefaultStatuses projectDefaultStatusesShots={props.site.projectDefaultStatusesShots} isEditable={isEditable} ></DefaultStatuses>
                                    </DropdownSub>
                                    <DropdownSub innerName={'Tags'}>
                                        <DefaultTags projectDefaultTags={props.site.projectDefaultTags} isEditable={isEditable} ></DefaultTags>
                                    </DropdownSub>
                                </Dropdowns>

                            </React.Fragment>
                        </SiteManagerContainer>
                    )
                } else {
                    return (
                        <div></div>
                    )
                }
            })()}
        </React.Fragment>
    )
}
const mapStateToProps = (state, props) => {
    return {
        site: getCurrentSite(state),
    }
}

SiteManager = connect(mapStateToProps, {
    fetchCurrentSiteRequest,
    updateSitesRequest
})(SiteManager)

export default SiteManager 