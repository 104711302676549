import { call, all, put, takeEvery } from 'redux-saga/effects'

import * as actions from '../actions/sites'
import * as backend from '../api/backend/index'

function* fetchSites() {
  try {
    const sites = yield call(backend.sites.fetchSites)
    yield put(actions.fetchSitesSuccess(sites))
  } catch (e) {
    yield put(actions.fetchSitesFailure(e))
  }
}

function* fetchCurrentSite() {
  try {
    const site = yield call(backend.sites.fetchCurrentSite)
    yield put(actions.fetchCurrentSiteSuccess(site))
  } catch (e) {
    yield put(actions.fetchCurrentSiteRequest(e))
  }
}

function* updateSites({ payload }) {
  try {
    const sites = yield call(backend.sites.updateSites, payload)
    yield put(actions.updateSitesSuccess(sites))
  } catch (e) {
    yield put(actions.updateSitesFailure(e))
  }
}

export function* sitesRootSaga() {
  yield all([
    yield takeEvery(actions.FETCH_SITES_REQUEST, fetchSites),
    yield takeEvery(actions.FETCH_CURRENT_SITE_REQUEST, fetchCurrentSite),
    yield takeEvery(actions.UPDATE_SITES_REQUEST, updateSites)
  ])
}
