import { combineReducers } from 'redux'

import { users } from './user'
import { projects } from './project'
import { shots } from './shot'
import { shotVersions } from './shotVersion'
import { statuses } from './status'
import { tags } from './tag'
import { userManager } from './userManager'
import { site } from './site'
import { sites } from './sites'
import { techSpecs } from './techSpecs'

const entities = combineReducers({
  users,
  projects,
  shots,
  shotVersions,
  statuses,
  tags,
  userManager,
  site,
  sites,
  techSpecs
})

export { entities }
