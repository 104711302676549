import React, { useState } from 'react'
import styled from '@emotion/styled'

import { colors, units, type } from '../../../styles'
import doubleArrow from './Dropdown.svg'

import { DropdownForForm } from '../../Dropdown/DropdownForForm'

const SelectContainer = styled.div({
  position: 'relative',
  width: '100%',
  height: '2.5rem',
  background: colors.night,
  border: `0.0625rem solid ${colors.grey}`,
  borderRadius: units.borderRadius,
  outline: '0',
  ':focus': {
    ...type.formActive
  },

})

const InnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  padding: '0 0.5rem'
})

const SelectedItem = styled.label({
  ...type.formActive,
  display: 'inline-block'
})

const Icon = styled.img({ width: '0.5rem' })

const DropdownContainer = styled.div({
  position: 'relative'
})

const SelectForProjectsManager = ({ options, selectedOption, input }) => {
  let [dropdownVisible, setDropdownVisibility] = useState(false)
  let clickHandler = () => setDropdownVisibility(!dropdownVisible)
  let leaveHandler = () => setDropdownVisibility(false)

  return (
    <SelectContainer onClick={clickHandler} onMouseLeave={leaveHandler}>
      <InnerContainer>
        <SelectedItem  >
          {selectedOption ? selectedOption : input.value}
        </SelectedItem>
        <Icon src={doubleArrow} />
      </InnerContainer>
      <DropdownContainer>
        {dropdownVisible && (
          <DropdownForForm
            items={options.map(option => {
              return {
                ...option,
                action: () => {
                  option.action()
                }
              }
            })}
            input={input}
            selectedOption={selectedOption}
          />
        )}
      </DropdownContainer>
    </SelectContainer>
  )
}

export { SelectForProjectsManager }
