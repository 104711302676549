import React, { useState } from 'react'
import { Route, Switch } from 'react-router'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { ellipsis } from 'polished'

import { Dropdown } from '../Dropdown/Dropdown'
import { ProjectTitleConnected } from './ProjectTitle'

import { colors, type, layout } from '../../styles'
import hamburger from './hamburger.png'
import dropdownItems from './dropdown.json'

const SideNavContainer = styled.div({
  flex: `0 0 ${layout.left}`,
  minWidth: '20%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  borderRight: `0.0625rem solid ${colors.grey}`,
  minWidth: '280px'
})

const Items = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  padding: '0 1rem'
})

const RouteTitle = styled.h1({
  ...type.subHeaderBold,
  whiteSpace: 'nowrap',
  ...ellipsis('calc(100% - 2.5rem)')
})

const DropdownContainer = styled.div({
  position: 'relative'
})

const DropdownIcon = styled.img({
  height: '1rem'
})

const TitleRouter = () => {
  return (
    <RouteTitle>
      <Switch>

        <Route
          path="/project/:projectId(\d+)"
          component={ProjectTitleConnected}
        />
        <Route path="/project/#/techspecs">
          <Link to="/project/#/techspecs">Tech Specs</Link>
        </Route>

        <Route path={['/project', '/project']} children>
          <Link to="/projects/all">Projects</Link>
        </Route>


        <Route path="/users">
          <Link to="/users">User Manager</Link>
        </Route>

        <Route path="/account">
          <Link to="/account">User Account</Link>
        </Route>

        <Route path="/user/create">
          <Link to="/user/create">Create User</Link>
        </Route>

        <Route path={['/projects']} children>
          <Link to="/projects/manager">Project Manager</Link>
        </Route>

        <Route path="/sites">
          <Link to="/sites">Site Manager</Link>
        </Route>

      </Switch>
    </RouteTitle>
  )
}

const SideNav = () => {
  const [dropdownVisible, setDropdownVisability] = useState(false)
  return (
    <SideNavContainer onMouseLeave={() => setDropdownVisability(false)}>
      <Items>
        <TitleRouter />
        <DropdownIcon
          src={hamburger}
          alt="navigation dropdown"
          onClick={() => setDropdownVisability(!dropdownVisible)}
        />
      </Items>

      <DropdownContainer>
        {dropdownVisible && <Dropdown items={dropdownItems} />}
      </DropdownContainer>
    </SideNavContainer>
  )
}

export { SideNav }
