import React, { useState, useEffect } from 'react'
import doubleArrow from '../Button/down.svg'
import { ShotAssetStatuses } from './DetailsComponents/ShotAssetStatuses'
import { VersionStatuses } from './DetailsComponents/VersionStatuses'
import { colors } from '../../styles'
import styled from '@emotion/styled'
import { SelectContainer, Wrap, InnerContainer, SelectedItem, Icon, IconWrap, DropdownContainer, UserProfileContainer } from './ProjectManagerStyles'

const WrapContainer = styled.div({
    marginTop: "0.2rem",
    marginBottom: "0.2rem"
})

let DropdownStatuses = (props) => {

    const [dropdownName, setDropdownName] = useState(props.innerName)

    let [dropdownVisible, setDropdownVisibility] = useState(true)
    let clickHandler = () => setDropdownVisibility(!dropdownVisible)

    useEffect(() => {
        setDropdownName(props.innerName)
    }, [props.innerName])

    return (
        <Wrap >
            <WrapContainer>
                <SelectContainer style={{ backgroundColor: colors.darkerGrey, border: 'none', height: '1.75rem', }}>
                    <InnerContainer  >
                        <SelectedItem style={{ fontSize: '0.8rem' }}>
                            {dropdownName}
                        </SelectedItem>
                        <IconWrap>
                            <Icon src={doubleArrow} onClick={clickHandler} rotated={dropdownVisible} />
                        </IconWrap>
                    </InnerContainer>
                </SelectContainer>
                <DropdownContainer>
                    {dropdownVisible && (
                        <div>
                            <UserProfileContainer style={{ marginTop: "0.1rem" }}
                            >
                                {dropdownName === "Shots/Assets statuses" && 
                                (<ShotAssetStatuses
                                    isEditable={props.isEditable}
                                    singleProject={props.singleProject && props.singleProject}
                                    createProjectStatusRequest={props.createProjectStatusRequest}
                                    updateProjectStatusRequest={props.updateProjectStatusRequest}
                                    deleteProjectStatusRequest={props.deleteProjectStatusRequest}
                                    globalStatuses={props.globalStatuses && props.globalStatuses}
                                    ></ShotAssetStatuses>)}

                                {dropdownName === "Version statuses" && 
                                (<VersionStatuses 
                                    isEditable={props.isEditable}
                                    singleProject={props.singleProject && props.singleProject}
                                    createProjectStatusRequest={props.createProjectStatusRequest}
                                    updateProjectStatusRequest={props.updateProjectStatusRequest}
                                    deleteProjectStatusRequest={props.deleteProjectStatusRequest}
                                    globalStatuses={props.globalStatuses && props.globalStatuses}
                                    ></VersionStatuses>)}

                            </UserProfileContainer>
                        </div>
                    )}
                </DropdownContainer>
            </WrapContainer>
        </Wrap>
    )
}

export { DropdownStatuses }