import React, { useState, useEffect } from 'react'
import { Field, FieldArray } from 'redux-form'
import { SelectOption } from './RenderFields'
import Button from '../../Button/Button'
import {
    RowTitles, Row, Label, Section, LabelSection, Text, CloseIcon
} from './DetailsComponentsStyles'
import { colors } from '../../../styles'
import closeIcon from './closeIcon.svg'

const RenderEpisodes = ({ fields }) => {

    let [items, setItems] = useState(fields ? fields : '')

    useEffect(() => {
        setItems(fields)
    }, [fields == undefined])

    if (fields !== undefined) {
        return (<React.Fragment>

            {fields.map((episodes, index) => {
                return (

                    <React.Fragment>
                        <div style={{
                            position: 'relative',
                            height: '20%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            background: colors.night,
                            borderBottom: '0.0625rem solid #4a4b4c',
                        }}>
                            <Section >
                                <Field
                                    name={`${episodes}.number`}
                                    type="text"
                                    component={renderField}
                                />
                            </Section>

                            <Section >
                                <Field
                                    name={`${episodes}.title`}
                                    type="text"
                                    component={renderField}
                                />
                            </Section>

                            <Section>
                                <Field
                                    name={`${episodes}.director`}
                                    type="text"
                                    component={renderField}
                                >
                                </Field>
                            </Section>

                            <Section><CloseIcon src={closeIcon} onClick={() => fields.remove(index)}></CloseIcon></Section>
                        </div>
                    </React.Fragment>
                )
            })}
            <Row>
                <Section><Button type='button' onClick={() => fields.push({})}
                    style={{ height: '2.5rem', fontWeight: 'bold' }}>Add Episode
             </Button></Section>
                <Section></Section>
                <Section></Section>
                <Section></Section>
            </Row>

        </React.Fragment>)
    }
    else return <div>""</div>
}

const renderField = ({
    input, label, type, meta: { touched, error, warning },
}) => (
        <div>
            <label>{label}</label>
            <div  >
                <input className="black-field-projects"   {...input} placeholder={label} type={type}
                />
            </div>
        </div>
    )

const Episodes = (props) => {

    if (props.singleProject) {
        return (
            <React.Fragment >
                {props.isEditable ? (
                    <React.Fragment>

                        {props.singleProject.episodes.length !== 0 ? (
                            <RowTitles >
                                <LabelSection><Label>Episode Number</Label></LabelSection>
                                <LabelSection><Label>Episode Title</Label></LabelSection>
                                <LabelSection><Label>Episode Director</Label></LabelSection>
                                <LabelSection></LabelSection>
                            </RowTitles>
                        ) : <div></div>}

                        <FieldArray name="episodes" component={RenderEpisodes} />

                    </React.Fragment>
                ) : (
                        <React.Fragment>
                            {props.singleProject.episodes.length !== 0 ? (
                                <RowTitles >
                                    <LabelSection><Label>Episode Number</Label></LabelSection>
                                    <LabelSection><Label>Episode Title</Label></LabelSection>
                                    <LabelSection><Label>Episode Director</Label></LabelSection>
                                    <LabelSection></LabelSection>
                                </RowTitles>
                            ) : <div style={{padding: '1rem'}}>There are currently no episodes</div>}

                            {Object.entries(props.singleProject.episodes).map(([key, value]) => {
                                return (
                                    <React.Fragment key={value.episodeId}>

                                        <Row>
                                            <Section >
                                                <Text >{value.number}</Text>
                                            </Section>
                                            <Section>
                                                <Text >{value.title}</Text>
                                            </Section>
                                            <Section>
                                                <Text >{value.director2}</Text>
                                            </Section>
                                            <Section></Section>
                                        </Row>
                                    </React.Fragment>
                                )
                            })}
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        )
    }
    else {
        return <div></div>
    }
}

export { Episodes }
