import { call, all, put, takeEvery } from 'redux-saga/effects'
import * as actions from '../actions/status'
import * as backend from '../api/backend/index'

// export function* fetchStatuses(backend) {
export function* fetchStatuses() {
  try {
    const statuses = yield call(backend.status.fetchStatuses)
    yield put(actions.fetchStatusesSuccess(statuses))
  } catch (e) {
    yield put(actions.fetchStatusesFailure(e))
  }
}

function* createStatus({ payload }) {
  try {
    const status = yield call(backend.status.createStatus, payload)
    yield put(actions.createStatusSuccess(status))
  } catch (e) {
    yield put(actions.createStatustFailure(e))
  }
}

function* updateStatus({ payload }) {
  try {
    const status = yield call(backend.status.updateStatus, payload)
    yield put(actions.updateStatusSuccess(status))
  } catch (e) {
    yield put(actions.updateStatusFailure(e))
  }
}

function* deleteStatus({ payload }) {
  try {
    const status = yield call(backend.status.deleteStatus, payload)
    yield put(actions.deleteStatusSuccess(status))
  } catch (e) {
    yield put(actions.deleteStatusFailure(e))
  }
}

function* createProjectStatus({ payload }) {
  try {
    const status = yield call(backend.status.createProjectStatus, payload)
    yield put(actions.createProjectStatusSuccess(status))
  } catch (e) {
    yield put(actions.createProjectStatustFailure(e))
  }
}

function* updateProjectStatus({ payload }) {
  try {
    const status = yield call(backend.status.updateProjectStatus, payload)
    yield put(actions.updateProjectStatusSuccess(status))
  } catch (e) {
    yield put(actions.updateProjectStatusFailure(e))
  }
}

function* deleteProjectStatus({ payload }) {
  try {
    const status = yield call(backend.status.deleteProjectStatus, payload)
    yield put(actions.deleteProjectStatusSuccess(status))
  } catch (e) {
    yield put(actions.deleteProjectStatusFailure(e))
  }
}

// export function* status(backend) {
export function* status() {
  yield all([
    yield takeEvery(actions.FETCH_STATUSES_REQUEST, fetchStatuses),
    yield takeEvery(actions.CREATE_STATUS_REQUEST, createStatus),
    yield takeEvery(actions.UPDATE_STATUS_REQUEST, updateStatus),
    yield takeEvery(actions.DELETE_STATUS_REQUEST, deleteStatus),

    yield takeEvery(actions.CREATE_PROJECT_STATUS_REQUEST, createProjectStatus),
    yield takeEvery(actions.UPDATE_PROJECT_STATUS_REQUEST, updateProjectStatus),
    yield takeEvery(actions.DELETE_PROJECT_STATUS_REQUEST, deleteProjectStatus),
  ])
}