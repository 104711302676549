import { schemas } from './schemas'
import { request } from './index'
import { normalize } from 'normalizr'

export const fetchShotVersion = async ({ shotVersionId }) => {
  let res = await request(`/shot-versions/${shotVersionId}`)
  return normalize(res, schemas.SHOT_VERSION)
}

export const updateShotVersion = async shotVersion => {
  let res = await request(`/shot-versions/${shotVersion.shotVersionId}`, {
    method: 'put',
    data: { ...shotVersion }
  })
  return normalize(res, schemas.SHOT_VERSION)
}
