import styled from '@emotion/styled'
import { colors} from '../../styles'

export const Row = styled.div({
    position: 'relative',
    width: '100%',
    background: colors.dark,
    display: 'flex',
    flexWrap: 'wrap'
})

export const Section = styled.div({
    position: 'relative',
    width: '25%',
    background: colors.dark,
})

export const Container = styled.div({
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    height: '30rem'
})

export const Label = styled.div({
    fontSize: "0.9rem",
    fontStyle: "bold",
    paddingBottom: '0.5rem',
    fontFamily: 'Heebo',
})

export const TextWrap = styled.div({
    position: 'relative',
    width:'90%',
    height: '5.5rem',
    padding: '0.5rem',
    paddingTop: '1rem',
    paddingLeft: '1rem',
    paddingRight: '1rem'
})

export const TextWrapReadonly = styled.div({
    position: 'relative',
    width:'100%',
    height: '5.5rem',
    padding: '0.5rem',
    paddingTop: '1rem',
    paddingLeft: '1rem',
    paddingRight: '1em'
})

export const Text = styled.div({
    position: 'relative',
    fontStyle: "bold",
    fontSize: "1.2rem",
    color: '#DCDCDC',
    paddingTop: '0.2rem',
    fontFamily: 'Heebo',
})

export const Icon = styled.img(() => ({
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  }))

  export const CloseIcon = styled.img({
    float: 'right'
})

export const Space = styled.div({
    width: '90%', padding: '0.5rem'
})