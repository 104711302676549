// import './fonts.css'
import colors from './colors'

let type = {
  main: '16px',
  padding: {
    paragraphBottom: '1.3rem',
    headingTop: ' 1.414rem',
    headingBottom: ' 0.5rem'
  },
  colors: {
    day: colors.day,
    grey: colors.grey,
    night: colors.night
  }
}

type.subHeader = {
  fontFamily: 'Heebo',
  fontSize: '0.875rem' /* 14pt */,
  lineHeight: '1.375rem',
  fontWeight: '700',
  color: type.colors.day
}

type.subHeaderBold = {
  ...type.subHeader /* 14pt */,
  fontWeight: 'bold'
}

// Defined as override in Sketch for statuses
type.subHeaderOverride = {
  ...type.subHeader /* 14pt */,
  fontSize: '0.625rem' /* 10pt */,
  lineHeight: '0.875rem' /* 14pt */,
  fontWeight: '400',
  color: type.colors.day
}

type.form = {
  ...type.subHeader /* 14pt */,
  color: type.colors.grey
}

type.formActive = {
  ...type.subHeader /* 14pt */
}

type.headerNav = {
  ...type.subHeader /* 14pt */,
  color: type.colors.grey,
  ':hover': {
    textDecorationColor: type.colors.grey
  }
}

type.headerNavActive = {
  ...type.subHeader /* 14pt */,
  color: type.colors.day,
  ':hover': {
    textDecoration: type.colors.day
  }
}

type.headerHead = {
  fontFamily: 'Heebo',
  fontSize: '0.8125rem' /* 13pt */,
  lineHeight: '1.1rem',
  fontWeight: '400',
  color: type.colors.day
}

type.contentOverride = {
  fontFamily: 'Heebo',
  fontSize: '0.75rem' /* 12pt */,
  lineHeight: '0.88888888rem',
  fontWeight: '400',
  color: type.colors.day
}

type.content = {
  fontFamily: 'Heebo',
  fontSize: '0.8125rem' /* 13pt */,
  lineHeight: '1.1rem',
  fontWeight: '400',
  color: type.colors.day
}

type.contentHead = {
  ...type.content /* 13pt */,
  fontWeight: 'bold'
}

type.labelBig = {
  fontFamily: 'Heebo',
  fontSize: '0.75rem' /* 12pt */,
  lineHeight: '1.3rem',
  color: type.colors.day,
  fontWeight: 'bold'
}

type.label = {
  fontFamily: 'Heebo',
  fontSize: '0.625rem' /* 8pt */,
  lineHeight: '1.3rem',
  color: type.colors.day,
  fontWeight: 'bold'
}

export default type
