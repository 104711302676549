import React from 'react'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import { normalize } from 'polished'

import { Nav } from '../Nav/Nav.jsx'
import { ToastListConnected } from '../ToastList/ToastList.jsx'
import { SidebarRouterConnected } from '../Sidebar/SidebarRouter'
import { MainRouterConnected } from '../Main/MainRouter'

import favicon from '../../assets/logos/logo-small.svg'

import { colors, type } from '../../styles'

const globalStyles = {
  ...normalize(),
  ...type.fonts,
  '*': {
    boxSizing: 'border-box'
  },
  html: {
    margin: '0',
    padding: '0',
    fontSize: type.main
  },
  body: {
    background: colors.darkGrey,
    margin: '0',
    height: '100%'
  },
  main: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  a: {
    textDecoration: `none ${colors.day}`,
    color: 'unset',
    ':hover': {
      textDecoration: `underline ${colors.day}`
    }
  },
  ul: {
    listStyle: 'none',
    padding: '0',
    margin: '0'
  },
  'h1, h2, h3, h4, h5, h6, p, q, i, label, strong, button': {
    color: 'red',
    margin: '0'
  }
}

const AppContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  width: '100vw'
})

const BodyContainer = styled.div({
  display: 'flex',
  width: '100%',
  height: '100%'
})

const Layout = () => {
  return (
    <AppContainer>
      <Global styles={css(globalStyles)} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Space Admin</title>
        <link rel="icon" type="image/png" href={favicon} />
      </Helmet>
      <Nav />
      <BodyContainer styles={css(globalStyles)}>
        <SidebarRouterConnected />
        <MainRouterConnected />
      </BodyContainer>
      <ToastListConnected />
    </AppContainer>
  )
}

export default Layout
