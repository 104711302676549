import React from 'react'
import Helmet from 'react-helmet'
import { Switch, Route, Redirect } from 'react-router'
import { connect } from 'react-redux'
import styled from '@emotion/styled'

import { LoginFormConnected } from '../Forms/types/LoginForm'
import { RetrievePasswordFormConnected } from '../Forms/types/RetrievePasswordForm'
import { ResetPasswordFormConnected } from '../Forms/types/ResetPasswordForm'
import { VerifyAccountFormConnected } from '../Forms/types/VerifyAccountForm'
import { isAuthenticated } from '../../reducers/auth'

import logo from '../../assets/logos/logo.svg'

const AuthContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%'
})

const Card = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '30rem'
})

const Logo = styled.img({
  margin: '0 0 2rem'
})

const Auth = ({ isAuthenticated }) => {
  return (
    <AuthContainer>
      {isAuthenticated && <Redirect to="/" />}

      <Helmet>
        <title>SA Auth</title>
      </Helmet>

      <Card>
        <Logo src={logo} alt="Space Admin Logo" />
        <Switch>
          <Route exact path="/auth/login" component={LoginFormConnected} />

          <Route
            exact
            path="/auth/retrieve"
            component={RetrievePasswordFormConnected}
          />

          <Route
            exact
            path="/auth/reset"
            component={ResetPasswordFormConnected}
          />

          <Route
            exact
            path="/auth/verify"
            component={VerifyAccountFormConnected}
          />
        </Switch>
      </Card>
    </AuthContainer>
  )
}

const mapStateToProps = state => {
  return {
    isAuthenticated: isAuthenticated(state)
  }
}

const AuthConnected = connect(mapStateToProps)(Auth)

export { Auth, AuthConnected }
