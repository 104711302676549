import React from 'react'
import styled from '@emotion/styled'

import { colors, type } from '../../styles'

const Container = styled.div(props => ({
  minWidth: '10rem',
  minHeight: '3rem',
  background: props.type !== 'error' ? colors.lightGrey : colors.red,
  padding: '1rem',
  margin: '1rem',
  ...type.content
}))

const Toast = ({ message, type }) => {
  return <Container type={type}>{message}</Container>
}

export default Toast
