import React, { useState, useEffect } from 'react'
import profilePicture from '../Project/birdman.png'
import star from './star.svg'
import signleArrow from '../Button/down.svg'
import {
    DetailsContainer, ListContainer, Label, Text, Icon,
    Wrap, ArrowWrap, StarIcon, ArrowIcon, AssignedProjectsContainer,
    ThirdSectionReadonly, FourthSectionReadonly, FirstSection, SecondSection
} from './AssignedProjectsStyles'
import { connect } from 'react-redux'
import {reduxForm, FieldArray } from 'redux-form'
import mySubmit from './DetailsComponents/mySubmit'
import { addMissingProperty } from './helperFunctions'
import { renderMembers } from './DetailsComponents/RenderFields'

let AssignedProjects = (props) => {

    const [isEditable, setIsEditable] = useState(props.editable)

    useEffect(() => {
        setIsEditable(props.editable)
    }, [props.editable])

    //archived projects
    useEffect(() => {
        props.fetchProjectsArchivedRequest()

    }, [props.fetchProjectsArchivedRequest, props.userProjects.userId, props.editable])

    let allProjectValues = Object.entries(props.projects).map(([key, value]) => {
        return value
    })

    let userProjects = Object.entries(props.userProjects).map(([key, value]) => {
        return value
    })

    useEffect(() => {
        const { initialize } = props
            let result = addMissingProperty(allProjectValues, userProjects)
            initialize({ memberProjects: result })
    }, [userProjects.length==0, props.userData.userId])

    const { handleSubmit } = props

    return (
        <form onSubmit={handleSubmit} >
            <AssignedProjectsContainer>
            {(() => {
                if (isEditable) {
                    return (
                        <React.Fragment>
                            <FieldArray name="memberProjects" component={renderMembers} />
                        </React.Fragment>
                    )
                } else {
                    return (
                        <React.Fragment>
                            {userProjects.map((userProject) => {
                                if (userProject.title !== undefined) {
                                    return (                                        
                                        <DetailsContainer key={userProject.projectId} >
                                            {/* <ListContainer style={{height: `${SecondSection.height=='4.5rem'}? '4.5' : 'auto'`}}> */}
                                            <ListContainer style={{height: 'auto'}}>

                                                <FirstSection  style={{height: 'auto'}}>
                                                    <Icon src={userProject.thumbnailURL?userProject.thumbnailURL: profilePicture}></Icon>
                                                </FirstSection >
                                                <SecondSection style={{height: 'auto'}}>
                                                    <Label style={{height: 'auto'}}>{userProject.title}</Label>
                                                    <Text>{userProject.director}</Text>
                                                </SecondSection>
                                                <ThirdSectionReadonly padding='0rem'>
                                                    <Label>{userProject.permissions}
                                                    </Label>
                                                </ThirdSectionReadonly>
                                                <FourthSectionReadonly>
                                                    <Wrap><StarIcon src={star}></StarIcon></Wrap>
                                                    <ArrowWrap><ArrowIcon src={signleArrow}></ArrowIcon></ArrowWrap>
                                                </FourthSectionReadonly>
                                            </ListContainer>
                                        </DetailsContainer>
                                    )
                                }
                            })}
                        </React.Fragment>
                    )
                }
            })()}
            </AssignedProjectsContainer>
        </form>
    )
}

AssignedProjects = reduxForm({
    form: 'assignedProjectsForm',
    onSubmit: mySubmit,
})(AssignedProjects)

AssignedProjects = connect(null)(AssignedProjects)

export { AssignedProjects } 