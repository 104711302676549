import React from 'react'
import { useInView } from 'react-intersection-observer'
import { connect } from 'react-redux'

import { ShotConnected } from './Shot'

const ShotPage = React.memo(({ shotIds, scrollEl, fetchShotListRequest }) => {
  const [viewRef, inView] = useInView({
    root: scrollEl.current,
    rootMargin: '2000px 0px 4000px',
    triggerOnce: true
  })

  return (
    <div ref={viewRef}>
      {shotIds.map(shotId => (
        <ShotConnected key={shotId} shotId={shotId} inView={inView} />
      ))}
    </div>
  )
})

const ShotPageConnected = connect(null)(ShotPage)

export { ShotPageConnected }
