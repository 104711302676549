import { type } from '../../../styles'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

// styles
const HR = styled.hr({
  width: '100%',
  borderColor: 'grey',
  margin: '0 0 2rem'
})

const Fieldset = styled.fieldset({
  maxWidth: '60rem',
  padding: '1rem 0',
  border: 'unset',
  margin: '0'
})

const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '1rem 2rem'
})

const LinkStyled = styled(Link)({
  ...type.headerNav
})

export { HR, Fieldset, Form, LinkStyled }
