import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from '@emotion/styled'

import rocket from './birdman.png'

import { getProject } from '../../reducers/project'
import { colors, type, units } from '../../styles'

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  background: colors.night,
  borderRadius: units.borderRadius
})

const ThumbnailContainer = styled.div({
  position: 'relative'
})

const Thumbnail = styled.img({
  width: '100%',
  display: 'block'
})

const DetailsContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '1fr 1fr',
  gridColumnGap: '1rem',
  width: '100%',
  padding: '1rem',
  flex: '1 1'
})

const SubHeader = styled.h2({
  ...type.subHeader,
  marginBottom: '0.5rem',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden'
})

const Text = styled.div({
  ...type.contentHead
})

const Label = styled.div({
  ...type.label,
  color: type.colors.grey
})

const Project = ({ project }) => {
  return (
    <>
      <ThumbnailContainer>
        <Thumbnail src={project.thumbnailURL? project.thumbnailURL: rocket} alt="" />
      </ThumbnailContainer>

      <DetailsContainer>
        <div>
          <Link to={{ pathname: `/project/${project.projectId}/shot/all` }}>
            <Label>Project Title</Label>
            <SubHeader>{project.title}</SubHeader>
          </Link>
        </div>

        <div>
          <Label>Project Type</Label>
          <SubHeader>{project.projectType}</SubHeader>
        </div>

        <div>
          <Label>Directed by</Label>
          <Text>{project.director}</Text>
        </div>

        <div>
          <Label>Shots</Label>
          <Text>{project.shots ? project.shots.length : '0'}</Text>
        </div>
      </DetailsContainer>
    </>
  )
}

const ProjectContainer = ({ project }) => {
  return (
    <Container>{project ? <Project project={project} /> : 'loading'}</Container>
  )
}

const mapStateToProps = (state, { projectId }) => {
  return {
    project: getProject(state, projectId)
  }
}

const ProjectConnected = connect(mapStateToProps)(ProjectContainer)

export { Project, ProjectConnected }
