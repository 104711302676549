import React, { useState, useEffect } from 'react'
import doubleArrow from '../Button/down.svg'

import { SelectContainer, Wrap, InnerContainer, SelectedItem, Icon, IconWrap, DropdownContainer, UserProfileContainer } from './ProjectManagerStyles'

let Dropdowns = (props) => {

  const [dropdownName, setDropdownName] = useState(props.innerName) //Why sometimes const and other times let?
  let [dropdownVisible, setDropdownVisibility] = useState(true)
  let clickHandler = () => setDropdownVisibility(!dropdownVisible)

  useEffect(() => { //Is mapping the prop to the state useful/neccesary?
    setDropdownName(props.innerName)
  }, [props.innerName])

  return (
    <Wrap >
      <SelectContainer >
        <InnerContainer  >
          <SelectedItem >
            {dropdownName}
          </SelectedItem>
          <IconWrap>
            <Icon src={doubleArrow} onClick={clickHandler} rotated={dropdownVisible} />
          </IconWrap>
        </InnerContainer>
      </SelectContainer>
      <DropdownContainer >
        {dropdownVisible && (
          <UserProfileContainer marginTop="0.1rem" name={dropdownName}>
            {props.children}
          </UserProfileContainer>
        )}
      </DropdownContainer>
    </Wrap>
  )
}

export { Dropdowns }