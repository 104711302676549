import { call, put, takeEvery, all } from 'redux-saga/effects'
import * as actions from '../actions/createUser'
import * as backend from '../api/backend/index'

function* createUserFunction({ payload }) {
    try {
      const userData = yield call(backend.user.createUser, payload)
      yield put(actions.createUserSuccess({ userData }))
    } catch (e) {
      yield put(actions.createUserFailure(e))
    }
  }

export function* createUserSaga() {
    yield all([
        takeEvery(actions.CREATE_USER_REQUEST, createUserFunction)
    ])
}