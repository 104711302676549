import {
  LOGIN_SUCCESS,
  LOGOUT,
  FETCH_USERS_SUCCESS,
  FETCH_USER_TO_VALIDATE_SUCCESS,
  LOGIN_SUCCESS_ID,
  VALIDATE_USER_SUCCESS
} from '../actions/auth'
import { omit } from 'lodash'

const auth = (state = { isAuthenticated: false }, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:

      return {
        ...state,
        isAuthenticated: true,
        currentUserId: action.payload,

      }
    case FETCH_USER_TO_VALIDATE_SUCCESS:

      return {
        ...state,
        isAuthenticated: false,
        isValidated: true,
        validationUser: action.payload.result
      }
    case VALIDATE_USER_SUCCESS:
      return {
        ...state,
        validationUser: null
      }

    case LOGOUT:
      let newState = omit(state, 'validationUser')
      return {
        ...newState,
        isAuthenticated: false,
        validationUser: null
      }
    default:
      return state
  }
}

export { auth }

export const isAuthenticated = state => {
  return state.ui.auth.isAuthenticated
}

export const getCurrentUser= state=> {
  console.log('state.ui.auth.currentUserId', state.ui.auth.currentUserId  )
  return state.ui.auth.currentUserId
}

