import React from 'react'
import { withRouter, Route, Switch } from 'react-router'
import { connect } from 'react-redux'

import { Sidebar } from './Sidebar.jsx'

const SidebarRouter = () => {
  return (
    <Switch>
      <Route
        path={[
          '/project/create',
          '/project/all',
          '/project/:projectId/shot/all',
          '/project/:projectId/shot/create',
          '/project/:projectId/edit',
          '/project/:projectId/shot/:shotId/edit',
          '/project/:projectId/techspecs/:extraInfo',
          '/project/:projectId/techspecs',
          '/users',
          '/users/:userId',
          '/account',
          '/user/create',
          '/projects/manager/:projectId/:extraInfo',
          '/projects/manager/:projectId',
          '/projects/manager',
          '/sites/:extraInfo',
          '/sites',

        ]}
        component={Sidebar}
      />
    </Switch>
  )
}

const mapStateToProps = state => ({})

const SidebarRouterConnected = withRouter(
  connect(mapStateToProps)(SidebarRouter)
)

export { SidebarRouter, SidebarRouterConnected }
