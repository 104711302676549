import React, { useState } from 'react'
import { InnerSelect } from './InnerSelect'

const OuterSelect = (props) => {
  let [dropdownVisible1, setDropdownVisibility1] = useState(true)
  let clickHandler1 = () => setDropdownVisibility1(!dropdownVisible1)

  let [dropdownVisible2, setDropdownVisibility2] = useState(true)
  let clickHandler2 = () => setDropdownVisibility2(!dropdownVisible2)


  return (
    <React.Fragment >
      {props.userData && (
        <InnerSelect innerName={'User Profile'} click={clickHandler1}
          rotated={dropdownVisible1} visibility={dropdownVisible1}
          userData={props.userData && props.userData}
          editable={props.editable ? props.editable : false}
          updateUserRequest={props.updateUserRequest}
          fetchSelectedUserRequest={props.fetchSelectedUserRequest}
          style={{    transition: 'border 1s ease 0.1s',
        }}
          />
      )}

      {props.userData && (
        <InnerSelect innerName={'Assigned Projects'} click={clickHandler2}
          rotated={dropdownVisible2} visibility={dropdownVisible2}
          editable={props.editable ? props.editable : false}
          updateUserRequest={props.updateUserRequest}
          userData={props.userData && props.userData}
          userProjects={props.userProjects && props.userProjects}
          marginBottom="0.1rem"
          fetchUserProjectsRequest={props.fetchUserProjectsRequest}
          projects={props.projects}
          updateProjectMembersRequest={props.updateProjectMembersRequest}
          fetchProjectsArchivedRequest={props.fetchProjectsArchivedRequest}
        />
      )}
    </React.Fragment>
  )
}

export { OuterSelect }
