import { math } from 'polished'

import type from './typography'
import colors from './colors'
import media from './media'

let units = {
  base: '1rem',
  maxWidth: '80rem',
  borderWidth: '1px',
  borderRadius: '2px',
  padding: {
    m: '1rem',
    s: '0.5rem'
  }
}

let layout = {
  left: '20%',
  right: '80%'
}

units = {
  ...units,
  half: math(`${units.base} / 2`),
  double: math(`${units.base} * 2`)
}

export { units, layout, colors, type, media }
