export const FETCH_SHOT_VERSION_REQUEST = 'FETCH_SHOT_VERSION_REQUEST'
export const FETCH_SHOT_VERSION_SUCCESS = 'FETCH_SHOT_VERSION_SUCCESS'
export const FETCH_SHOT_VERSION_FAILURE = 'FETCH_SHOT_VERSION_FAILURE'

export const UPDATE_SHOT_VERSION_REQUEST = 'UPDATE_SHOT_VERSION_REQUEST'
export const UPDATE_SHOT_VERSION_SUCCESS = 'UPDATE_SHOT_VERSION_SUCCESS'
export const UPDATE_SHOT_VERSION_FAILURE = 'UPDATE_SHOT_VERSION_FAILURE'

export const fetchShotVersionRequest = ({ shotVersionId }) => {
  return {
    type: FETCH_SHOT_VERSION_REQUEST,
    payload: { shotVersionId }
  }
}

export const fetchShotVersionSuccess = ({ entities, result }) => {
  return {
    type: FETCH_SHOT_VERSION_SUCCESS,
    payload: { entities, result }
  }
}

export const fetchShotVersionFailure = e => {
  return {
    type: FETCH_SHOT_VERSION_FAILURE,
    payload: e,
    error: true
  }
}

export const updateShotVersionRequest = shotVersion => {
  return {
    type: UPDATE_SHOT_VERSION_REQUEST,
    payload: shotVersion
  }
}

export const updateShotVersionSuccess = ({ entities, result }) => {
  return {
    type: UPDATE_SHOT_VERSION_SUCCESS,
    payload: { entities, result }
  }
}

export const updateShotVersionFailure = e => {
  return {
    type: UPDATE_SHOT_VERSION_FAILURE,
    payload: e,
    error: true
  }
}
