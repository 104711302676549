import { call, put, takeEvery, all } from 'redux-saga/effects'
import * as actions from '../actions/auth'
import * as backend from '../api/backend/index'

function* fetchSelectedtUser({payload}) {
    try {
      const user = yield call(backend.user.fetchSelectedUser, payload)
      yield put(actions.fetchSelectedUserSuccess({user}))
    } catch (e) {
      yield put(actions.fetchSelectedUserFailure(e))
    }
  }

  export function* fetchUserProfile() {
    yield all([
      takeEvery(actions.FETCH_SELECTED_USER_REQUEST, fetchSelectedtUser),
    ])
  }