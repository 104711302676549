export const CREATE_USER_REQUEST ='CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'

export const createUserRequest = ({ firstName, lastName,
                                    role, company,
                                    function:functionName, email,
                                    avatarURL }) => {
    return {
      type: CREATE_USER_REQUEST,
      payload: { firstName, lastName,
        role, company,
        function:functionName, email,
        avatarURL}
    }
  }
  
  export const createUserSuccess = (createdUser) => {
    return {
      type: CREATE_USER_SUCCESS,
      payload: createdUser
    }
  }
  
  export const createUserFailure = e => {
    return {
      type: CREATE_USER_FAILURE,
      payload: e,
      error: true
    }
  }