const onSubmitCreator = onSubmit => {
  return (values, { setSubmitting }) => {
    onSubmit(values)
    setSubmitting(true)
    setTimeout(() => {
      setSubmitting(false)
    }, 1000)
  }
}

export { onSubmitCreator }
