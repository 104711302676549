import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import styled from '@emotion/styled'
import { type, colors } from '../../styles'
import { getAllUserIds } from '../../reducers/user'

import { Select } from './SelectForTopSidebar'

// import { getCurrentUser } from '../../reducers/auth'
import { fetchUserToValidateRequest } from '../../actions/auth'
import { fetchSelectedUserRequest } from '../../actions/auth'
import qs from 'query-string'
import { getProjects } from '../../reducers/project'
import { fetchUserProjectsRequest } from '../../actions/project'

const Label = styled.label({
    ...type.labelBig,
    color: colors.lightGrey
})

const TopSidebarDropdownProjects = ({
    match,
    projects,
    fetchUserProjectsRequest
}) => {

    useEffect(() => {
            fetchUserProjectsRequest()
    }, [fetchUserProjectsRequest])

    const [projectName, setProjectName] = useState('');
    const [projectId, setProjectId] = useState(match.params.projectId)

    let projectValues = Object.entries(projects).map(([key, value]) => {
        return value
    })

    return (
        <>
            <Label>Select Project</Label>
            <Select 
                options={
                    [{ projectName: null }, ...projectValues].map(
                        (projectValue) => {
                            if (projectValue.title !== undefined) {
                                return {
                                    type: 'link-action',
                                    action: () => {
                                        return setProjectName(projectValue.title)
                                    },
                                    name: projectValue.title,
                                    path: `/projects/manager/${projectValue.projectId}`

                                }
                            }
                        }
                    )}
                selectedOption={projects[projectId] && projects[projectId].title}
            >
            </Select>
        </>
    )
}

const mapStateToProps = (state) => {

    return {
        projects: getProjects(state),
    }
}

const TopSidebarDropdownProjectsConnected = withRouter(
    connect(
        mapStateToProps,
        {
            fetchUserProjectsRequest
        }
    )(TopSidebarDropdownProjects)
)

export { TopSidebarDropdownProjectsConnected }
