let colors = {
  night: 'black',
  darkGrey: '#111213',
  darkerGrey: '#333435',
  grey: '#4a4b4c',
  lightGrey: '#969798',
  day: '#f8f8f8',

  green: '#668b3f',
  red: ' #b64c49',
  purple: ' #5569b8',
  blue: '#3a8590',
  yellow: '#bd9530'
}

colors = {
  ...colors,
  theme1: colors.green,
  theme2: colors.red,
  theme3: colors.purple,
  theme4: colors.blue,
  theme5: colors.yellow
}

export default colors
