import { schema } from 'normalizr'

const userSchema = new schema.Entity('users', {}, { idAttribute: 'userId' })

const projectSchema = new schema.Entity(
  'projects',
  {},
  { idAttribute: 'projectId' }
)

const statusSchema = new schema.Entity(
  'status',
  {},
  { idAttribute: 'statusId' }
)

const tagSchema = new schema.Entity('tag', {}, { idAttribute: 'tagId' })

const shotVersionSchema = new schema.Entity(
  'shotVersions',
  {},
  { idAttribute: 'shotVersionId' }
)

const shotSchema = new schema.Entity(
  'shots',
  { tags: [tagSchema], status: statusSchema },
  { idAttribute: 'shotId' }
)

export const schemas = {
  USER: userSchema,
  USERS: [userSchema],
  PROJECT: projectSchema,
  PROJECTS: [projectSchema],
  SHOT: shotSchema,
  SHOTS: [shotSchema],
  SHOT_VERSION: shotVersionSchema,
  SHOT_VERSIONS: [shotVersionSchema],
  STATUS: statusSchema,
  STATUSES: [statusSchema],
  TAG: tagSchema,
  TAGS: [tagSchema]
}
