import React from 'react'
import styled from '@emotion/styled'

import { colors, units, type } from '../../styles'

const ButtonEl = styled.button({
  display: 'flex',
  justifyContent: 'center',
  height: '3rem',
  width: '100%',
  alignItems: 'center',

  outline: 'unset',
  cursor: 'pointer',

  background: colors.darkerGrey,
  border: 'none',
  borderRadius: units.borderRadius,

  ...type.subHeader,

  '&:hover': {
    background: colors.day,
    color: type.colors.night,
    textDecoration: 'underline',
    textDecorationColor: type.colors.night
  }
})

const Button = ({ children, ...props }) => {
  return <ButtonEl {...props}>{children}</ButtonEl>
}

export default Button
