import { setSaturation } from "polished"

export const FETCH_STATUSES_REQUEST = 'FETCH_STATUSES_REQUEST'
export const FETCH_STATUSES_SUCCESS = 'FETCH_STATUSES_SUCCESS'
export const FETCH_STATUSES_FAILURE = 'FETCH_STATUSES_FAILURE'
export const CREATE_STATUS_REQUEST = 'CREATE_STATUS_REQUEST'
export const CREATE_STATUS_SUCCESS = 'CREATE_STATUS_SUCCESS'
export const CREATE_STATUS_FAILURE = 'CREATE_STATUS_FAILURE'
export const UPDATE_STATUS_REQUEST = 'UPDATE_STATUS_REQUEST'
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS'
export const UPDATE_STATUS_FAILURE = 'UPDATE_STATUS_FAILURE'
export const DELETE_STATUS_REQUEST = 'DELETE_STATUS_REQUEST'
export const DELETE_STATUS_SUCCESS = 'DELETE_STATUS_SUCCESS'
export const DELETE_STATUS_FAILURE = 'DELETE_STATUS_FAILURE'

export const CREATE_PROJECT_STATUS_REQUEST = 'CREATE_PROJECT_STATUS_REQUEST'
export const CREATE_PROJECT_STATUS_SUCCESS = 'CREATE_PROJECT_STATUS_SUCCESS'
export const CREATE_PROJECT_STATUS_FAILURE = 'CREATE_PROJECT_STATUS_FAILURE'
export const UPDATE_PROJECT_STATUS_REQUEST = 'UPDATE_PROJECT_STATUS_REQUEST'
export const UPDATE_PROJECT_STATUS_SUCCESS = 'UPDATE_PROJECT_STATUS_SUCCESS'
export const UPDATE_PROJECT_STATUS_FAILURE = 'UPDATE_PROJECT_STATUS_FAILURE'
export const DELETE_PROJECT_STATUS_REQUEST = 'DELETE_PROJECT_STATUS_REQUEST'
export const DELETE_PROJECT_STATUS_SUCCESS = 'DELETE_PROJECT_STATUS_SUCCESS'
export const DELETE_PROJECT_STATUS_FAILURE = 'DELETE_PROJECT_STATUS_FAILURE'

//statuses endpoints are for both Shot/Asset statuses and Version statuses
export const fetchStatusesRequest = () => {
  return {
    type: FETCH_STATUSES_REQUEST
  }
}

export const fetchStatusesSuccess = ({ entities, result }) => {
  return {
    type: FETCH_STATUSES_SUCCESS,
    payload: { entities, result }
  }
}

export const fetchStatusesFailure = e => {
  return {
    type: FETCH_STATUSES_FAILURE,
    payload: e,
    error: true
  }
}

export const createStatusRequest = status => {
  return {
    type: CREATE_STATUS_REQUEST,
    payload: status
  }
}

export const createStatusSuccess = () => {
  return {
    type: CREATE_STATUS_SUCCESS
  }
}

export const createStatustFailure = e => {
  return {
    type: CREATE_STATUS_FAILURE,
    payload: e,
    error: true
  }
}

export const updateStatusRequest = status => {
  return {
    type: UPDATE_STATUS_REQUEST,
    payload: status
  }
}

export const updateStatusSuccess = () => {
  return {
    type: UPDATE_STATUS_SUCCESS
  }
}

export const updateStatusFailure = e => {
  return {
    type: UPDATE_STATUS_FAILURE,
    payload: e,
    error: true
  }
}

export const deleteStatusRequest = statusId => {
  return {
    type: DELETE_STATUS_REQUEST,
    payload: statusId
  }
}

export const deleteStatusSuccess = statusId => {
  return {
    type: DELETE_STATUS_SUCCESS,
    payload: statusId
  }
}

export const deleteStatusFailure = e => {
  return {
    type: DELETE_STATUS_FAILURE,
    payload: e,
    error: true
  }
}

export const createProjectStatusRequest = status => {
  return {
    type: CREATE_PROJECT_STATUS_REQUEST,
    payload: status
  }
}

export const createProjectStatusSuccess = () => {
  return {
    type: CREATE_PROJECT_STATUS_SUCCESS
  }
}

export const createProjectStatustFailure = e => {
  return {
    type: CREATE_PROJECT_STATUS_FAILURE,
    payload: e,
    error: true
  }
}

export const updateProjectStatusRequest = status => {
  return {
    type: UPDATE_PROJECT_STATUS_REQUEST,
    payload: status
  }
}

export const updateProjectStatusSuccess = () => {
  return {
    type: UPDATE_PROJECT_STATUS_SUCCESS
  }
}

export const updateProjectStatusFailure = e => {
  return {
    type: UPDATE_PROJECT_STATUS_FAILURE,
    payload: e,
    error: true
  }
}

export const deleteProjectStatusRequest = status => {
  return {
    type: DELETE_PROJECT_STATUS_REQUEST,
    payload: status
  }
}

export const deleteProjectStatusSuccess = statusId => {
  return {
    type: DELETE_PROJECT_STATUS_SUCCESS,
    payload: statusId
  }
}

export const deleteProjectStatusFailure = e => {
  return {
    type: DELETE_PROJECT_STATUS_FAILURE,
    payload: e,
    error: true
  }
}