import { colors } from '../../../styles'
import styled from '@emotion/styled'

export const RowTitles = styled.div({
    position: 'relative',
    height: '1.7rem',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    background: colors.night,
})

export const Row = styled.div({
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    background: colors.night,
})

export const Label = styled.div({
    fontSize: "0.9rem",
    fontStyle: "bold",
    color: '#969798',
    fontFamily: 'Heebo',
})

export const Section = styled.div({
    position: 'relative',
    padding: '0.8rem',
    width: '25%',
    background: colors.dark,
})
export const LabelSection = styled.div({
    position: 'relative',
    paddingTop: '0.7rem',
    paddingLeft: '0.8rem',
    paddingRight: '0.8rem',
    width: '25%',
    background: colors.dark,
    fontFamily: 'Heebo',
})

export const Text = styled.div({
    fontSize: "1rem",
    color: '#DCDCDC',
    fontFamily: 'Heebo',
})

export const Icon = styled.img({ width: '6%', color: 'grey', paddingRight: '0.5rem' })
export const CloseIcon = styled.img({
    float: 'right'
})
export const WrapRight = styled.div({ width: '94%' })
export const WrapLeft = styled.div({ width: '6%' })
export const SmallSquare = styled.div({ width: '0.8rem', height: '0.8rem', borderRadius: '0.1rem' })

export const Container = styled.div({
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
})

export const getListStyle = () => ({
    position: 'relative',
    height: '20%',
    width: '100%',
    background: colors.night,
  });
  
  export const getItemStyle = (isDragging, draggableStyle) => ({
    position: 'relative',
    height: '20%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    background: colors.night,
    borderBottom: '0.0625rem solid #4a4b4c',
    ...draggableStyle
  });