import React, { useState, useEffect } from 'react'
import doubleArrow from '../Button/down.svg'
import { SelectContainer, Wrap, InnerContainer, SelectedItem, Icon, IconWrap, DropdownContainer, UserProfileContainer } from './TechSpecsStyles'
import { Row, Section, Container, Label, TextWrapReadonly, Text } from './DropdownsStyles'

let Dropdowns = (props) => {

  const [dropdownName, setDropdownName] = useState(props.innerName)

  let [dropdownVisible, setDropdownVisibility] = useState(true)
  let clickHandler = () => setDropdownVisibility(!dropdownVisible)

  useEffect(() => {
    setDropdownName(props.innerName)
  }, [props.innerName])

  return (
    <Wrap >
      <SelectContainer >
        <InnerContainer  >
          <SelectedItem >
            {props.oneSpec.techSpecId}
          </SelectedItem>
          <IconWrap>
            <Icon src={doubleArrow} onClick={clickHandler} rotated={dropdownVisible} />
          </IconWrap>
        </InnerContainer>
      </SelectContainer>
      <DropdownContainer >
        {dropdownVisible && (
          <UserProfileContainer marginTop="0.1rem" name={dropdownName}
          >
            <Container>

              <Row>
                <Section>
                  <TextWrapReadonly >
                    <Label>Frame Aspect Ratio</Label>
                    <Text>{props.oneSpec.frameAspectRatio}</Text>
                  </TextWrapReadonly>
                </Section>

                <Section>
                  <TextWrapReadonly >
                    <Label>Framerate</Label>
                    <Text>{props.oneSpec.framerate}</Text>
                  </TextWrapReadonly>
                </Section>

                <Section>
                  <TextWrapReadonly >
                    <Label>Pixel Aspect Ratio</Label>
                    <Text>{props.oneSpec.pixelAspectRatio}</Text>
                  </TextWrapReadonly>
                </Section>

                <Section>
                  <TextWrapReadonly >
                    <Label>Resolution Height</Label>
                    <Text>{props.oneSpec.resolutionHeight}</Text>
                  </TextWrapReadonly>
                </Section>

              </Row>

              <Row>
                <Section>
                  <TextWrapReadonly >
                    <Label>Resolution Width</Label>
                    <Text>{props.oneSpec.resolutionWidth}</Text>
                  </TextWrapReadonly>
                </Section>

                <Section>
                  <TextWrapReadonly >
                    <Label>Input Outoput</Label>
                    <Text>{props.oneSpec.inputOutput}</Text>
                  </TextWrapReadonly>
                </Section>

                <Section>
                  <TextWrapReadonly >
                    <Label>Handles In</Label>
                    <Text>{props.oneSpec.handlesIn}</Text>
                  </TextWrapReadonly>
                </Section>

                <Section>
                  <TextWrapReadonly >
                    <Label>Handles Out</Label>
                    <Text>{props.oneSpec.handlesOut}</Text>
                  </TextWrapReadonly>
                </Section>

              </Row>

              <Row>
                <Section>
                  <TextWrapReadonly >
                    <Label>Object Type</Label>
                    <Text>{props.oneSpec.objectType}</Text>
                  </TextWrapReadonly>
                </Section>

                <Section>
                  <TextWrapReadonly >
                    <Label>Color Space</Label>
                    <Text>{props.oneSpec.colorSpace}</Text>
                  </TextWrapReadonly>
                </Section>

                <Section>
                  <TextWrapReadonly >
                    <Label>Look Up Table</Label>
                    <Text>{props.oneSpec.lookUpTable}</Text>
                  </TextWrapReadonly>
                </Section>

                <Section>
                  <TextWrapReadonly >
                    <Label>Video Codec</Label>
                    <Text>{props.oneSpec.videoCodec}</Text>
                  </TextWrapReadonly>
                </Section>

              </Row>

              <Row>
                <Section>
                  <TextWrapReadonly >
                    <Label>Audio Codec</Label>
                    <Text>{props.oneSpec.audioCodec}</Text>
                  </TextWrapReadonly>
                </Section>

                <Section>
                  <TextWrapReadonly >
                    <Label>Container</Label>
                    <Text>{props.oneSpec.container}</Text>
                  </TextWrapReadonly>
                </Section>

                <Section>
                  <TextWrapReadonly >
                    <Label>File Name Format</Label>
                    <Text>{props.oneSpec.filenameFormat}</Text>
                  </TextWrapReadonly>
                </Section>

                <Section>
                  <TextWrapReadonly >
                    <Label>Notes</Label>
                    <Text>{props.oneSpec.notes}</Text>
                  </TextWrapReadonly>
                </Section>

              </Row>

              <Row>
                <Section>
                  <TextWrapReadonly >
                    <Label>Interlaced</Label>
                    <Text>{props.oneSpec.isInterlaced== false ? 'No' : 'Yes'}</Text>
                  </TextWrapReadonly>
                </Section>

                <Section>
                  <TextWrapReadonly >
                    <Label>Private</Label>
                    <Text>{props.oneSpec.isPrivate== false ? 'No' : 'Yes'}</Text>
                  </TextWrapReadonly>
                </Section>

                <Section>
                  <TextWrapReadonly >
                    <Label>Stereo</Label>
                    <Text>{props.oneSpec.isStereo== false ? 'No' : 'Yes'}</Text>
                  </TextWrapReadonly>
                </Section>

                <Section>
                  <TextWrapReadonly >
                    <Label>XR</Label>
                    <Text>{props.oneSpec.isXR== false ? 'No' : 'Yes'}</Text>
                  </TextWrapReadonly>
                </Section>

              </Row>

            </Container>
          </UserProfileContainer>
        )}
      </DropdownContainer>
    </Wrap>
  )
}

export { Dropdowns }