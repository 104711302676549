function globalStatusesSubmit(values, dispatch, props) {

    let statusUpdateAndCreate = values.globalStatusesArray.filter(function (obj) { return props.globalStatuses.indexOf(obj) == -1; });
    
    statusUpdateAndCreate.map((value) => {
    
      if (value.statusId) {
        props.updateStatusRequest({ statusId: value.statusId, title: value.title, color: value.color })
      }
      else {
        props.createStatusRequest({ title: value.title, color: value.color })
      }
    })
    
    let array4 = props.globalStatuses.filter(function (obj) { return values.globalStatusesArray.indexOf(obj) == -1; });
    let arrayDeleted = array4.filter(o => !statusUpdateAndCreate.find(o2 => o.statusId === o2.statusId))
    
    arrayDeleted.map((value) => {
      props.deleteStatusRequest(value.statusId)
    })
}

export default globalStatusesSubmit

