export const CREATE_EPISODE_REQUEST = 'CREATE_EPISODE_REQUEST'
export const CREATE_EPISODE_SUCCESS = 'CREATE_EPISODE_SUCCESS'
export const CREATE_EPISODE_FAILURE = 'CREATE_EPISODE_FAILURE'
export const UPDATE_EPISODE_REQUEST = 'UPDATE_EPISODE_REQUEST'
export const UPDATE_EPISODE_SUCCESS = 'UPDATE_EPISODE_SUCCESS'
export const UPDATE_EPISODE_FAILURE = 'UPDATE_EPISODE_FAILURE'
export const DELETE_EPISODE_REQUEST = 'DELETE_EPISODE_REQUEST'
export const DELETE_EPISODE_SUCCESS = 'DELETE_EPISODE_SUCCESS'
export const DELETE_EPISODE_FAILURE = 'DELETE_EPISODE_FAILURE'

export const createEpisodeRequest = episode => {
    return {
      type: CREATE_EPISODE_REQUEST,
      payload: episode
    }
  }
  
  export const createEpisodeSuccess = () => {
    return {
      type: CREATE_EPISODE_SUCCESS
    }
  }
  
  export const createEpisodeFailure = e => {
    return {
      type: CREATE_EPISODE_FAILURE,
      payload: e,
      error: true
    }
  }
  
  export const updateEpisodeRequest = episode => {
    return {
      type: UPDATE_EPISODE_REQUEST,
      payload: episode
    }
  }
  
  export const updateEpisodeSuccess = () => {
    return {
      type: UPDATE_EPISODE_SUCCESS
    }
  }
  
  export const updateEpisodeFailure = e => {
    return {
      type: UPDATE_EPISODE_FAILURE,
      payload: e,
      error: true
    }
  }
  
  export const deleteEpisodeRequest = episodeId => {
    return {
      type: DELETE_EPISODE_REQUEST,
      payload: episodeId
    }
  }
  
  export const deleteEpisodeSuccess = episode => {
    return {
      type: DELETE_EPISODE_SUCCESS,
      payload: episode
    }
  }
  
  export const deleteEpisodeFailure = e => {
    return {
      type: DELETE_EPISODE_FAILURE,
      payload: e,
      error: true
    }
  }