import React, { useCallback } from 'react'
import { connect } from 'react-redux'

import styled from '@emotion/styled'
import { cover } from 'polished'
import { useDropzone } from 'react-dropzone'

import { updateShotThumbRequest } from '../../actions/shot'
import rocket from './rocket.png'

const Thumbnail = styled.img({
  position: 'absolute',
  minHeight: '100%',
  minWidth: '100%',
  height: '100%',
  left: '50%',
  transform: 'translate(-50%, 0)'
})

const DropIcon = styled.div({
  position: 'absolute',
  ...cover(),
  background: 'rgba(255,255,255, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

const ThumbDropzone = ({ shot, updateShotThumbRequest }) => {
  const onDrop = useCallback(
    acceptedFiles => {
      updateShotThumbRequest({ shotId: shot.shotId, files: acceptedFiles })
    },
    [updateShotThumbRequest, shot]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Thumbnail src={shot.thumbnailURL || rocket} alt="" />
      {isDragActive && <DropIcon>DROP</DropIcon>}
    </div>
  )
}

const ThumbDropzoneConnected = connect(
  null,
  {
    updateShotThumbRequest
  }
)(ThumbDropzone)

export { ThumbDropzone, ThumbDropzoneConnected }
