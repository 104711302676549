import { FETCH_STATUSES_SUCCESS } from '../actions/status'
import { combineReducers } from 'redux'
import { union } from 'lodash'

export const statusesById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_STATUSES_SUCCESS:
      return { ...state, ...action.payload.entities.status }
    default:
      return state
  }
}

function allStatuses(state = [], action) {
  switch (action.type) {
    case FETCH_STATUSES_SUCCESS:
      return union([], state, action.payload.result)
    default:
      return state
  }
}

export const statuses = combineReducers({
  byId: statusesById,
  allIds: allStatuses
})

export const getStatus = (state, statusId) => {
  return state.entities.statuses.byId[statusId]
}

export const getStatuses = state => {
  return Object.values(state.entities.statuses.byId)
}
