import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { mergeWith } from 'lodash'

import Button from '../../Button/Button'
import { FieldFull } from '../components/Fields'
import { Fieldset, Form } from '../components/Layout'
import { onSubmitCreator } from '../helpers/onSubmitCreator'

import {
  createShotRequest,
  fetchShotRequest,
  updateShotRequest
} from '../../../actions/shot'
import { getShot } from '../../../reducers/shot'

const shotInitialValues = {
  shotCode: '',
  shotLength: '',
  editId: '',
  adminNote: '',
  shotDescription: '',
  shotTags: []
}

const ShotSchema = Yup.object().shape({
  shotCode: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  shotLength: Yup.number().max(99999, 'Too High!'),
  editId: Yup.string().max(10, 'Too Long!'),
  adminNote: Yup.string().max(100, 'Too Long!'),
  shotDescription: Yup.string().max(10000, 'Too Long!'),
  shotTags: Yup.array()
})

const ShotForm = ({
  projectId,
  shot,
  onSubmit,
  submitText,
  deleteShotRequest
}) => {
  return (
    <Formik
      initialValues={shot || { ...shotInitialValues, projectId }}
      validationSchema={ShotSchema}
      onSubmit={onSubmitCreator(onSubmit)}
    >
      {({ isSubmitting, handleReset, handleSubmit }) => (
        <Form onReset={handleReset} onSubmit={handleSubmit}>
          <Fieldset>
            <FieldFull name="shotCode" label="Shot Code" />
            <FieldFull name="shotLength" label="Length" />
            <FieldFull name="editId" label="Edit Id" />
            <FieldFull name="adminNote" label="Administrative Note" />
            <FieldFull name="shotDescription" label="Description" />
          </Fieldset>
          {/* Attempt at tags, but need full objects first */}
          {/* <fieldset css={styles['fieldset']}>
              <FieldArray
                name="shotTags"
                render={arrayHelpers => (
                  <div>
                    {values.shotTags.map((tags, index) => (
                      <div key={index}>
                        <Field name={`shotTags[${index}].name`} />
                        <button
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          -
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => arrayHelpers.push({ name: '', age: '' })}
                    >
                      +
                    </button>
                  </div>
                )}
              />
              / >
            </fieldset> */}
          <Fieldset>
            <Button data-type="large" type="submit" disabled={isSubmitting}>
              {isSubmitting ? `${submitText}ing Shot` : `${submitText}e Shot`}
            </Button>
            {shot && (
              <Fieldset>
                <Button
                  type="button"
                  data-type="large"
                  onClick={() => {
                    deleteShotRequest(shot.shotId)
                  }}
                >
                  Delete
                </Button>
              </Fieldset>
            )}
          </Fieldset>
        </Form>
      )}
    </Formik>
  )
}

const ShotFormWrapper = ({
  projectId,
  shotId,
  shot,
  fetchShotRequest,
  updateShotRequest,
  createShotRequest,
  deleteShotRequest
}) => {
  // make sure shot is available
  useEffect(() => {
    shotId && fetchShotRequest({ shotId })
  }, [shotId, fetchShotRequest])

  // [TODO] API SHOULD NOT RETURN NULL
  let mergedShot = mergeWith({}, shotInitialValues, shot, (a, b) =>
    b === null ? a : undefined
  )

  if (shotId) {
    return shot ? (
      <ShotForm
        shot={mergedShot}
        onSubmit={updateShotRequest}
        submitText="Updat"
      />
    ) : (
      'loading data'
    )
  } else {
    return (
      <ShotForm
        projectId={projectId}
        onSubmit={createShotRequest}
        submitText="Creat"
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let { projectId, shotId } = ownProps.match.params
  return {
    shotId,
    projectId,
    shot: getShot(state, shotId)
  }
}

const ShotFormConnected = withRouter(
  connect(
    mapStateToProps,
    {
      createShotRequest,
      updateShotRequest,
      fetchShotRequest
    }
  )(ShotFormWrapper)
)

export { ShotForm, ShotFormConnected }
