import React, { useCallback } from 'react'
import { connect } from 'react-redux'

import styled from '@emotion/styled'
import { cover } from 'polished'
import { useDropzone } from 'react-dropzone'

import { updateProjectPictureRequest } from '../../actions/project'
import rocket from './rocket.png'

const Thumbnail = styled.img({
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%)',
  height: '100%',
  width: '100%',
  objectFit: 'cover'
})

const DropIcon = styled.div({
  position: 'absolute',
  ...cover(),
  background: 'rgba(255,255,255, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

const ThumbDropzoneProjectPicture = ({ picture, updateProjectPictureRequest, projectId }) => {
  const onDrop = useCallback(
    acceptedFiles => {
        updateProjectPictureRequest({ projectId: projectId, files: acceptedFiles })
    },
    [updateProjectPictureRequest, picture]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Thumbnail src={picture || rocket} alt="" />
      {isDragActive && <DropIcon>DROP</DropIcon>}
    </div>
  )
}

const ThumbDropzoneProjectPictureConnected = connect(
  null,
  {
    updateProjectPictureRequest
  }
)(ThumbDropzoneProjectPicture)

export { ThumbDropzoneProjectPicture, ThumbDropzoneProjectPictureConnected }
