import {
  FETCH_SHOTS_BY_PROJECT_ID_SUCCESS,
  FETCH_SHOT_SUCCESS,
  FETCH_SHOT_LIST_SUCCESS,
  UPDATE_SHOT_SUCCESS,
  UPDATE_SHOT_THUMB_SUCCESS
} from '../actions/shot'
import { combineReducers } from 'redux'
import { filter, union } from 'lodash'
import { createSelector } from 'reselect'

const shotById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SHOTS_BY_PROJECT_ID_SUCCESS:
    case FETCH_SHOT_LIST_SUCCESS:
    case UPDATE_SHOT_THUMB_SUCCESS:
    case FETCH_SHOT_SUCCESS:
    case UPDATE_SHOT_SUCCESS:
      return { ...state, ...action.payload.entities.shots }
    default:
      return state
  }
}

const allShots = (state = [], action) => {
  switch (action.type) {
    case FETCH_SHOTS_BY_PROJECT_ID_SUCCESS:
    case UPDATE_SHOT_THUMB_SUCCESS:
    case FETCH_SHOT_SUCCESS:
    case UPDATE_SHOT_SUCCESS:
      if (action.payload && action.payload.result) {
        return union([], state, action.payload.result)
      }
      return state
    default:
      return state
  }
}

export const shots = combineReducers({
  byId: shotById,
  allIds: allShots
})

// selectors
export const getShot = createSelector(
  (state, shotId) => {
    return state.entities.shots.byId[shotId]
  },
  shot => shot
)

export const getAllShotIds = state => {
  return state.entities.shots.allIds
}

export const getFullShots = state => {
  return state.entities.shots.byId
}

export const getShotIdsByProjectId = (state, projectId) => {
  return filter(state.entities.shots.byId, shot => {
    return shot.projectId === parseInt(projectId)
  }).map(shot => shot.shotId)
}

export const getShotIds = (state, { projectId, statusId }) => {
  return filter(state.entities.shots.byId, shot => {
    let hasCorrectProjectId = projectId ? shot.projectId === projectId : true
    let hasCorrectStatusId = statusId ? shot.status === statusId : true
    return hasCorrectProjectId && hasCorrectStatusId
  }).map(shot => shot.shotId)
}
