import React, { useState, useEffect } from 'react'
import Button from '../../Button/Button'
import { Field, FieldArray } from 'redux-form'
import {
    RowTitles, Row, Label, Section, LabelSection, Text,
    Icon, CloseIcon, WrapRight, WrapLeft, SmallSquare, getListStyle, getItemStyle
} from './DetailsComponentsStyles'
import reorderIcon from '../reorderIcon.svg'
import { colors } from '../../../styles'
import { ColorsSelect, SelectOptionStatuses } from './RenderFields'
import closeIcon from './closeIcon.svg'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const RenderVersionStatuses = ({ fields, statuses, globalStatuses }) => {

    let dropdownStatuses = globalStatuses.map((status) => {
        let dropdownStatus = {}
        dropdownStatus.value = status.statusId
        dropdownStatus.label = status.title.charAt(0).toUpperCase() + status.title.slice(1)
        return dropdownStatus
    })

    let [items, setItems] = useState(fields ? fields : '')

    useEffect(() => {
        setItems(fields)
    }, [fields == undefined])

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        fields.move(result.source.index, result.destination.index)
    }

    if (fields !== undefined) {
        return (<React.Fragment><DragDropContext onDragEnd={onDragEnd} style={{
            position: 'relative',
            width: '100%',
            background: colors.night,
        }}>

            <Droppable droppableId="droppable"
                style={{
                    position: 'relative',
                    width: '100%',
                    background: colors.night,
                }}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle()}
                    >

                        {fields.map((availableVersionStatuses, index) => {

                            return (
                                <Draggable key={index} draggableId={`${availableVersionStatuses}.tagId`} index={index}
                                >
                                    {(provided, snapshot) => (

                                        <div style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            <div style={{
                                                position: 'relative',
                                                height: '20%',
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                background: colors.night,
                                                borderBottom: '0.0625rem solid #4a4b4c',
                                            }}>

                                                <Section style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <Icon src={reorderIcon} {...provided.dragHandleProps}
/>
                                                    <WrapRight>
                                                        <Field
                                                            name={`${availableVersionStatuses}.statusId`}
                                                            component={SelectOptionStatuses}
                                                            options={dropdownStatuses}
                                                            statuses={statuses && statuses}
                                                        />
                                                    </WrapRight>
                                                </Section>

                                                <Section>
                                                    <Field
                                                        name={`${availableVersionStatuses}.color`}
                                                        component={ColorsSelect}
                                                        options={[
                                                            { label: 'blue', value: '#374890' },
                                                            { label: 'darkY', value: '#B68A2B' },
                                                            { label: 'red', value: '#853533' },
                                                            { label: 'green', value: '#327A85' },
                                                            { label: 'lightY', value: '#BBEAA6' },
                                                            { label: 'black', value: '#000000' },
                                                            { label: 'grey', value: '#323232' },
                                                            { label: 'purple', value: '#843364' },
                                                        ]}
                                                    >
                                                    </Field>
                                                </Section>
                                                <Section>

                                                </Section>
                                                <Section><CloseIcon src={closeIcon} onClick={() => fields.remove(index)}></CloseIcon></Section>
                                            </div>

                                        </div>
                                    )}
                                </Draggable>
                            )
                        })}
                        {provided.placeholder}
                    </div>)}
            </Droppable>
        </DragDropContext>

            <Row>
                <Section><Button type='button' onClick={() => fields.push({})} style={{ height: '2.5rem', fontWeight: 'bold' }}>Add version status</Button></Section>
                <Section></Section>
                <Section></Section>
                <Section></Section>
            </Row>
        </React.Fragment>)
    }
    else return <div>""</div>
}

const VersionStatuses = (props) => {

    if (props.singleProject) {
        return (
            <React.Fragment >
                {props.isEditable ? (
                    <React.Fragment>

                        {props.singleProject.availableVersionStatuses.length !== 0 ? (
                            <RowTitles >
                                <LabelSection style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <WrapLeft></WrapLeft>
                                    <WrapRight><Label >Version status title</Label></WrapRight>
                                </LabelSection>
                                <LabelSection><Label>Select color</Label></LabelSection>
                                <LabelSection><Label></Label></LabelSection>
                                <LabelSection></LabelSection>
                            </RowTitles>
                        ) : <div></div>}

                        <FieldArray name="availableVersionStatuses"
                            component={RenderVersionStatuses}
                            statuses={props.singleProject.availableVersionStatuses}
                            globalStatuses={props.globalStatuses}
                        />

                    </React.Fragment>
                ) : (
                        <React.Fragment>

                            {props.singleProject.availableVersionStatuses.length !== 0 ? (
                                <RowTitles >
                                    <LabelSection style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '1.6rem' }}>
                                        <WrapLeft></WrapLeft>
                                        <WrapRight><Label >Version status title</Label></WrapRight>
                                    </LabelSection>
                                    <LabelSection><Label></Label></LabelSection>
                                    <LabelSection><Label></Label></LabelSection>
                                    <LabelSection></LabelSection>
                                </RowTitles>
                            ) : <div style={{ padding: '1rem' }}>There are currently no version statuses</div>}

                            {Object.entries(props.singleProject.availableVersionStatuses).map(([key, value]) => {
                                return (
                                    <React.Fragment key={value.statusId}>

                                        <Row>
                                            <Section style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '0.8rem' }}>
                                                <SmallSquare style={{ backgroundColor: value.color, paddingLeft: '0.2rem' }} />
                                                <Text style={{ paddingLeft: '0.8rem' }}>{value.title}</Text>
                                            </Section>
                                            <Section></Section>
                                            <Section></Section>
                                            <Section></Section>
                                        </Row>
                                    </React.Fragment>
                                )
                            })}
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        )
    }
    else { return <div></div> }
}

export { VersionStatuses }
