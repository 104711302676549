import { FETCH_SITES_SUCCESS, UPDATE_SITES_SUCCESS, FETCH_CURRENT_SITE_SUCCESS } from '../actions/sites'

const site = (state = {}, action) => {
    switch (action.type) {
      case FETCH_CURRENT_SITE_SUCCESS:
        state = action.payload
        return state
      default:
        return state
    }
  }

export { site }

export const getCurrentSite = state=> {
  return state.entities.site
}