import React from 'react'
import styled from '@emotion/styled'

import { units, type } from '../../styles'
import down from './down.svg'

const ButtonEl = styled.button(active => ({
  display: 'flex',
  justifyContent: 'center',
  height: '2rem',
  width: '2rem',

  outline: 'unset',
  cursor: 'pointer',

  background: 'unset',
  border: 'none',
  borderRadius: units.borderRadius,

  ...type.subHeader,

  '&:hover': {
    [Icon]: {
      transform: `scale(1.5) ${active ? 'rotate(180deg)' : 'rotate(0deg)'}`
    }
  }
}))

const Icon = styled.img(({ active }) => ({
  position: 'relative',
  width: '0.9rem',
  height: '0.9rem',
  transition: 'right 0.25s ease, transform 0.25s ease',
  transform: `scale(1) ${active ? 'rotate(180deg)' : 'rotate(0deg)'}`
}))

const Expander = ({ active, children, ...props }) => {
  return (
    <ButtonEl {...props} active={active}>
      <Icon src={down} active={active} />
    </ButtonEl>
  )
}

export { Expander }
