import { call, put, takeEvery, all } from 'redux-saga/effects'
import * as actions from '../actions/auth'

function* checkAuthentication() {
  try {
    if (window.localStorage.getItem('SA_TOKEN')) {
      yield put(actions.loginSuccess())
    }
  } catch (e) {
    yield put(actions.loginFailure(e))
  }
}


export function* login(api, { payload }) {
  try {
    const { token } = yield call(api.user.fetchToken, { payload })
    const { userId }=yield call(api.user.fetchToken, {payload})
    window.localStorage.setItem('SA_TOKEN', token)
    yield put(actions.loginSuccess(userId))
    yield put(actions.fetchUsersRequest())
    // yield put(actions.loginSuccessId(userId))

  } catch (e) {
    yield put(actions.loginFailure(e))
  }
}

function* fetchUser(api) {
  try {
    const user = yield call(api.user.fetchUsers)
    yield put(actions.fetchUsersSuccess(user))
  } catch (e) {
    yield put(actions.fetchUsersFailure(e))
  }
}

function* requestPasswordRetrieval(api, { payload }) {
  try {
    yield call(api.user.requestPasswordRetrieval, { payload })
    yield put(actions.passwordRetrievalSuccess())
  } catch (e) {
    yield put(actions.passwordRetrievalFailure(e))
  }
}

function* fetchUserToValidate(api, { payload }) {
  try {
    const user = yield call(api.user.requestUserToValidate, { payload })
    yield put(actions.fetchUserToValidateSuccess(user))
  } catch (e) {
    yield put(actions.fetchUserToValidateFailure(e))
  }
}

function* validateUser(api, { payload }) {
  try {
    const user = yield call(api.user.validateUser, { payload })
    yield put(actions.fetchUserToValidateSuccess(user))
  } catch (e) {
    yield put(actions.fetchUserToValidateFailure(e))
  }
}

function* updatePicture(api, { payload }) {
  try {
    const picture = yield call(api.user.updatePictureThumb, payload)
    yield put(actions.updateProfilePictureSuccess({ picture }))
  } catch (e) {
    yield put(actions.updateProfilePictureFailure(e))
  }
}


export function* user(api) {
  yield all([
    takeEvery(actions.VALIDATE_USER_REQUEST, validateUser, api),
    takeEvery(actions.FETCH_USER_TO_VALIDATE_REQUEST, fetchUserToValidate, api),
    takeEvery(
      actions.PASSWORD_RETRIEVAL_REQUEST,
      requestPasswordRetrieval,
      api
    ),
    takeEvery(actions.FETCH_USERS_REQUEST, fetchUser, api),
    takeEvery(actions.LOGIN_REQUEST, login, api),
    takeEvery(actions.CHECK_AUTENTICATION, checkAuthentication, api),
    takeEvery(actions.UPDATE_PROFILE_PICTURE_REQUEST, updatePicture, api)
  ])
}
