import React, { useState, useEffect } from 'react'
import { reduxForm, Field, FieldArray } from 'redux-form'
import { connect } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from '@emotion/styled'

import {
    RowTitles, Row, Label, Section, LabelSection,
    Icon, CloseIcon, WrapRight, WrapLeft, getItemStyle, getListStyle
} from '../../ProjectsManager/DetailsComponents/DetailsComponentsStyles'

import reorderIcon from '../reorderIcon.svg'
import { colors } from '../../../styles'
import closeIcon from '../closeIcon.svg'
import Button from '../../Button/Button'

const Container2 = styled.div({
    position: 'relative',
    height: '20%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    background: colors.night,
    borderBottom: '0.0625rem solid #4a4b4c',
})

const Container = styled.div({
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
})

const renderField = ({
    input, label, type, meta: { touched, error, warning },
}) => (
        <div >
            <label>{label}</label>
            <div >
                <input className="black-field-projects" {...input} placeholder={label} type={type}
                />
            </div>
        </div>
    )

const RenderHeader = () => {
    return (
        <RowTitles >
            <LabelSection style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '0.2rem' }}>
                <WrapLeft></WrapLeft>
                <WrapRight><Label>Tag</Label></WrapRight>
            </LabelSection>
            <LabelSection><Label></Label></LabelSection>
            <LabelSection><Label></Label></LabelSection>
            <LabelSection><Label></Label></LabelSection>
        </RowTitles>
    )
}

const RenderTags = ({ fields }) => {
    const onDragEnd = (result) => {
        if (result.destination) fields.move(result.source.index, result.destination.index)
    }

    return (
        <React.Fragment>
            <DragDropContext onDragEnd={onDragEnd} style={{
                position: 'relative',
                width: '100%',
                background: colors.night,
            }}>

                <Droppable droppableId="droppable"
                    style={{
                        position: 'relative',
                        width: '100%',
                        background: colors.night,
                    }}>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle()}
                        >
                            {fields.map((tag, index) => {
                                return (
                                    <Draggable key={index} draggableId={`${tag}.tagId`} index={index}>
                                        {(provided, snapshot) => (
                                            <div style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                            >
                                                <Container2>
                                                    <React.Fragment>
                                                        <Section style={{ display: 'flex', flexWrap: 'wrap', }}>
                                                            <Icon src={reorderIcon} {...provided.dragHandleProps} />
                                                            <WrapRight>
                                                                <Field
                                                                    name={`${tag}.tagId`}
                                                                    type="text"
                                                                    component={renderField}
                                                                />
                                                            </WrapRight>
                                                        </Section>
                                                        <Section></Section>
                                                        <Section></Section>
                                                        <Section>
                                                            <CloseIcon src={closeIcon} onClick={() => fields.remove(index)}></CloseIcon>
                                                        </Section>
                                                    </React.Fragment>
                                                </Container2>
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </div>)}
                </Droppable>
            </DragDropContext>

            <Row>
                <Section>
                    <Button type='button' onClick={() => fields.push({})}
                        style={{ height: '2.5rem', fontWeight: 'bold' }}>Add Tag
                    </Button>
                </Section>
                <Section></Section>
                <Section></Section>
                <Section></Section>
            </Row>
        </React.Fragment >
    )
}

let DefaultTags = (props) => {
    useEffect(() => {
        if (props.projectDefaultTags !== undefined && props.projectDefaultTags.length !== 0) {
            props.initialize({ projectDefaultTags: props.projectDefaultTags })
        }
    }, [props.projectDefaultTags == undefined])

    if (props.projectDefaultTags == undefined) return (
        <div style={{ padding: '1rem' }}>There are currently no default tags</div>
    )

    return (
        <React.Fragment >
            <form onSubmit={props.handleSubmit} style={{ width: '100%' }}>
                {props.isEditable ? (
                    <Container>
                        <RenderHeader></RenderHeader>
                        <FieldArray name="projectDefaultTags" component={RenderTags} />
                    </Container>
                ) : (
                        <Container>
                            <RenderHeader></RenderHeader>
                            {Object.entries(props.projectDefaultTags).map(([key, value]) => {
                                return (
                                    <React.Fragment key={value.tagId}>
                                        <Row>
                                            <Section>
                                                <Button style={{ height: '2.5rem', backgroundColor: value.color, color: 'black', float: 'left' }}>{value.title}</Button>
                                            </Section>
                                            <Section></Section>
                                            <Section></Section>
                                            <Section></Section>
                                        </Row>
                                    </React.Fragment>
                                )
                            })}
                        </Container>
                    )
                }
            </form>
        </React.Fragment>
    )
}

function defaultTagsSubmit(values, dispatch, props) {
    console.log("SUBMIT OCCURED", values);
    console.log("SUBMIT OCCURED - PROPS", props);
}

DefaultTags = reduxForm({
    form: 'DefaultTagsForm',
    onSubmit: defaultTagsSubmit,
})(DefaultTags)

const mapStateToProps = (state) => {
    return {

    }
}
DefaultTags = connect(mapStateToProps, {

})(DefaultTags)

export default DefaultTags