import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { colors, units, type } from '../../styles'

const ddKeyframes = keyframes`
0% {
  opacity: 0;
  transform: scaleY(0)
}
80% {
  opacity: 0.5;
  transform: scaleY(1.1)
}
100% {
  opacity: 1;
  transform: scaleY(1)
}
`

export const SelectContainer = styled.div({
    position: 'relative',
    width: '100%',
    height: '2.25rem',
    background: colors.grey,
    border: `1px solid ${colors.grey}`,
    borderRadius: units.borderRadius,
    outline: '0',
    ':focus': {
      ...type.formActive
    },
  })
  
  export const Wrap = styled.div({
    marginBottom: '0.5rem',
    position: 'relative',
    width: '100%',
  })
  
  export const InnerContainer = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '0rem 0rem 0rem 1rem'
  })
  
  export const SelectedItem = styled.label({
    fontSize: "0.9rem",
    color: "white",
    fontWeight: "bold"
  })
  
  export const Icon = styled.img(({ rotated }) => ({
    width: '0.8rem',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transitionProperty: 'transform',
    transitionDuration: '0.2s',
    transform: ` scale(1) translate(-50%, -50%) ${rotated ? 'rotate(0deg)' : 'rotate(90deg)'}`,
  }))
  
  export const IconWrap = styled.div({
    height: '2rem',
    width: '3rem',
    position: 'relative'
  
  })
  
  export const DropdownContainer = styled.div({
    position: 'relative',
  })
  
  export const Button = styled.button({
    height: "2rem", width: "7rem", form: 'userManagerForm', type: "submit", value: 'Submit',
  
  })
  
  export const UserProfileContainer = styled.div(({ name }) => ({
    position: 'relative',
    width: '100%',
    background: colors.night,
    marginTop: "0.1rem",
    display: 'flex',
    flexWrap: 'wrap',
    animation: `${ddKeyframes} .7s ease-in-out forwards`,
    transformOrigin: 'top center'
  }))
  
  export const SiteManagerContainer = styled.div({
    flex: '1 1 100%',
    width: '100%',
    padding: '1rem',
    color: colors.lightGrey,
    fontFamily: 'Heebo',
})

export const BorderHeader = styled.div({
    borderBottom: '0.0625rem solid #4a4b4c',
    marginBottom: '1rem'
})

export const Header = styled.div({
    display: 'flex',
    padding: '1rem 0',
    fontFamily: 'Heebo',
})

export const HeaderItem = styled.span({
    ...type.subHeader,
    padding: '0 1rem',
    'first-of-type': {
        paddingLeft: '0'
    },
    fontSize: "18px",
    fontFamily: 'Heebo',
})
