import React from 'react'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import * as Yup from 'yup'

import Button from '../../Button/Button'
import { FieldFull } from '../components/Fields'
import { Fieldset, Form, HR, LinkStyled } from '../components/Layout'
import { onSubmitCreator } from '../helpers/onSubmitCreator'

import { passwordRetrievalRequest } from '../../../actions/auth'

const RetrieveInitialValues = { email: '' }
const RetrieveSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required')
})

const RetrievePasswordForm = ({ passwordRetrievalRequest }) => {
  return (
    <Formik
      initialValues={RetrieveInitialValues}
      validationSchema={RetrieveSchema}
      onSubmit={onSubmitCreator(passwordRetrievalRequest)}
    >
      {({ isSubmitting, handleReset, handleSubmit }) => (
        <Form onReset={handleReset} onSubmit={handleSubmit}>
          <Fieldset>
            <FieldFull name="email" label="Email" autoComplete="email" />
          </Fieldset>
          <Fieldset>
            <Button data-type="large" type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Requesting' : 'Request Retrieval'}
            </Button>
          </Fieldset>
          <Fieldset>
            <HR />
            <LinkStyled to="/auth/login">Login?</LinkStyled>
          </Fieldset>
        </Form>
      )}
    </Formik>
  )
}

const RetrievePasswordFormConnected = connect(
  null,
  {
    passwordRetrievalRequest
  }
)(RetrievePasswordForm)

export { RetrievePasswordForm, RetrievePasswordFormConnected }
