import React from 'react'
import { Field, ErrorMessage } from 'formik'

import { Label } from './Label'
import { StyledErrorMessage } from './Error'
import { Input } from './Input'

const FieldFull = ({ name, label, type, autoComplete }) => (
  <div>
    <Label>{label}</Label>
    <Field
      name={name}
      render={({ field }) => (
        <Input {...field} autoComplete={autoComplete} type={type} />
      )}
    />
    <ErrorMessage name={name} component={StyledErrorMessage} />
  </div>
)

export { FieldFull }
