import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

import { DetailsContainer, FirstSection, SecondSection, 
  ThirdSection, Label, TextWrap, Text, FourthSection, SecurityDetailsContainer } from './DetailsStyles'
import { ButtonChoose } from './RenderFields'
import { Field} from 'redux-form'
// import { FourthSection } from '../AssignedProjectsStyles'

const DetailsContainerSecurity = styled(DetailsContainer)({
  height: '6rem'
})

let SecurityDetails = (props) => {

  const [isEditable, setIsEditable] = useState(props.editable)

  useEffect(() => {
    setIsEditable(props.editable)
  }, [props.editable])

  return (
    <SecurityDetailsContainer>
      {(() => {
        if (isEditable) {
          return (
            <DetailsContainerSecurity >
              <FirstSection height='6rem'>
              </FirstSection>
              <SecondSection height='6rem'>
                <TextWrap>
                  <Label>Password</Label>
                  <Text>.....................</Text>
                </TextWrap>
              </SecondSection>
              <ThirdSection height='6rem'>
                <TextWrap>
                  <Label>Account Disabled</Label>
                  <Field
                    name="receivesChangelogs"
                    component={ButtonChoose}
                  />
                </TextWrap>
              </ThirdSection>
              <FourthSection></FourthSection>
            </DetailsContainerSecurity>
          )
        } else {
          return (
            <DetailsContainerSecurity height='6rem'>

              {props.userData &&
                <DetailsContainer height='6rem'>
                  <FirstSection height='6rem'>
                  </FirstSection>

                  <SecondSection height='6rem'>
                    <TextWrap>
                      <Label>Password</Label>
                      <Text>.....................</Text>

                    </TextWrap>
                  </SecondSection>

                  <ThirdSection height='6rem'>
                    <TextWrap>
                      <Label>Account Disabled</Label>
                      <Text
                        type="text"
                      >{props.userData.receivesChangelogs==false? 'No': 'Yes'}</Text>
                    </TextWrap>

                  </ThirdSection>
                  <FourthSection></FourthSection>
                </DetailsContainer>
              }
            </DetailsContainerSecurity>
          )
        }
      })()}
    </SecurityDetailsContainer>
  )
}

export { SecurityDetails }