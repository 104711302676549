export const SET_PROJECT_SHOT_SELECTION = 'SET_PROJECT_SHOT_SELECTION'
export const SET_USER_MANAGER_SELECTION = 'SET_USER_MANAGER_SELECTION'
export const SET_USER_MANAGER_SELECTION_SUCCESS = 'SET_USER_MANAGER_SELECTION_SUCCESS'
export const SET_USER_MANAGER_SELECTION_FAILURE = 'SET_USER_MANAGER_SELECTION_FAILURE'

export const setProjectShotSelection = (projectId, selection) => {
  return {
    type: SET_PROJECT_SHOT_SELECTION,
    payload: { projectId, selection }
  }
}

export const setUserManagerSelection = (userId) => {
  return {
    type: SET_USER_MANAGER_SELECTION,
    payload: userId
  }
}

export const setUserManagerSelectionSuccess = (userId) => {
  return {
    type: SET_USER_MANAGER_SELECTION_SUCCESS,
    payload: userId
  }
}

export const setUserManagerSelectionFailure = e => {
  return {
    type: SET_USER_MANAGER_SELECTION_FAILURE,
    payload: e,
    error: true
  }
}