import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import styled from '@emotion/styled'

import { type, colors } from '../../styles'

import { fetchUsersRequest } from '../../actions/auth'
import { getAllUserIds } from '../../reducers/user'
import { setUserManagerSelection } from '../../actions/ui'

import { Select } from './SelectForTopSidebar'

const Label = styled.label({
  ...type.labelBig,
  color: colors.lightGrey
})

const TopSidebarDropdownUsers = ({
  selectedUser,
  users = [],
  fetchUsersRequest,
  setUserManagerSelection,
  match,
}) => {

  const [userId, setUserId] = useState(match.params.userId)

  useEffect(() => {
    fetchUsersRequest()
  }, [fetchUsersRequest, userId])

  const [userName, setUserName] = useState('');

  let userValues = Object.entries(users).map(([key, value]) => {
    return value
  })

  return (
    <>
      <Label>Select User</Label>
      <Select
        options={
          [{ userName: null, title: 'No selection' }, ...userValues].map(
            (userValue) => {
              if (userValue.firstName !== undefined) {
                return {
                  type: 'link-action',
                  action: () => {
                    setUserName(userValue.firstName + ' ' + userValue.lastName)
                    return setUserManagerSelection(userValue.userId)

                  },
                  name: userValue.firstName + ' ' + userValue.lastName,
                  path: `/users/${userValue.userId}`
                }
              }
            }
          )}
        selectedOption={selectedUser[userId] && selectedUser[userId].firstName + " " + selectedUser[userId].lastName}
      >
      </Select>
    </>
  )
}

const mapStateToProps = (state) => {

  return {
    users: getAllUserIds(state),
    selectedUser: getAllUserIds(state),
  }
}

const TopSidebarDropdownConnected = withRouter(
  connect(
    mapStateToProps,
    {
      fetchUsersRequest,
      setUserManagerSelection,
    }
  )(TopSidebarDropdownUsers)
)

export { TopSidebarDropdownConnected }
