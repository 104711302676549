import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Route, Switch, withRouter } from 'react-router'
import styled from '@emotion/styled'

import Button from '../Button/Button'

import { colors } from '../../styles'
import { TopSidebarDropdownConnected } from './TopSidebarDropdownUsers'
import { TopSidebarDropdownProjectsConnected } from './TopSidebarDropdownProjects'
import {setTechSpecsRequest} from '../../actions/techSpecs'

const ButtonGroupContainerTop = styled.div({
  width: '100%',
  padding: '1rem',
})

const ButtonGroupContainerBottom = styled.div({
  width: '100%',
  padding: '0rem 1rem 1rem 1rem',
})

const DropDownContainer = styled.div({
  width: '100%',
  padding: '1rem',
})

const Wrap = styled.div({ borderBottom: `0.0625rem solid ${colors.grey}` })

const TopSidebarSection = ({ match, setTechSpecsRequest }) => {

  return (
    <Switch>

      <Route
        path={[`/projects/manager/:projectId`, `/projects/manager`]}
        component={() => {
          return (
            <Wrap>
              <DropDownContainer>
                <TopSidebarDropdownProjectsConnected />
              </DropDownContainer>
            </Wrap>
          )
        }}
      />

      <Route
        path={[`/users/:userId`, `/users`]}
        component={() => {
          return (
            <Wrap>
              <DropDownContainer>
                <TopSidebarDropdownConnected />
              </DropDownContainer>
            </Wrap>
          )
        }}
      />

    </Switch>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {}
}

const TopSidebarSectionConnected = withRouter(
  connect(mapStateToProps, {setTechSpecsRequest})(TopSidebarSection)
)

export { TopSidebarSection, TopSidebarSectionConnected }
