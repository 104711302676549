import { request } from './index'

export const fetchSites = async () => {
  let res = await request(`/sites`)
  return res
}

export const fetchCurrentSite = async () => {
  let res = await request(`/sites/1?detailed=true`)
  return res
}

export const updateSites = async site => {
  let res = await request(`/sites/${site.siteId}`, {
    method: 'put',
    data: site
  })
  return res
}