import React, { useState } from 'react'
import styled from '@emotion/styled'

import { colors, units, type } from '../../../styles'
import doubleArrow from './Dropdown.svg'

import { Dropdown } from '../../Dropdown/Dropdown'

// Might be done semantically, but for now a div avoids those styling issues
const SelectContainer = styled.div({
  position: 'relative',
  width: '100%',
  height: '3rem',
  background: colors.darkGrey,
  border: `0.0625rem solid ${colors.grey}`,
  borderRadius: units.borderRadius,
  outline: '0',
  ':focus': {
    ...type.formActive
  }
})

const InnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  padding: '0 1rem'
})

const SelectedItem = styled.label({
  ...type.formActive
})

const Icon = styled.img({ width: '0.8rem' })

const DropdownContainer = styled.div({
  position: 'relative'
})

const Select = ({ options, selectedOption }) => {
  let [dropdownVisible, setDropdownVisibility] = useState(false)
  let clickHandler = () => setDropdownVisibility(!dropdownVisible)
  let leaveHandler = () => setDropdownVisibility(false)

  return (
    <SelectContainer onClick={clickHandler} onMouseLeave={leaveHandler}>
      <InnerContainer>
        <SelectedItem>
          {selectedOption ? selectedOption.title : 'No selection'}
        </SelectedItem>
        <Icon src={doubleArrow} />
      </InnerContainer>
      <DropdownContainer>
        {dropdownVisible && (
          <Dropdown
            items={options.map(option => {
              return {
                ...option,
                action: () => {
                  option.action()
                }
              }
            })}
          />
        )}
      </DropdownContainer>
    </SelectContainer>
  )
}

export { Select }
