import styled from '@emotion/styled'

import { colors, units, type } from '../../../styles'

const Input = styled.input({
  ...type.formActive,
  width: '100%',
  padding: units.base,

  background: colors.darkGrey,
  border: `0.0625rem solid ${colors.grey}`,
  borderRadius: units.borderRadius,
  outline: '0'
})

export { Input }
