import {
  FETCH_USERS_SUCCESS,
  FETCH_USER_TO_VALIDATE_SUCCESS,
  UPDATE_PROFILE_PICTURE_SUCCESS
} from '../actions/auth'
import { UPDATE_USER_SUCCESS} from '../actions/editUser'
import { combineReducers } from 'redux'
import { merge, union } from 'lodash'

export const usersById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USERS_SUCCESS:
    case FETCH_USER_TO_VALIDATE_SUCCESS:
      if (action.payload && action.payload.entities) {
        return merge({}, state, action.payload.entities.users)
      }
      return state
    case UPDATE_USER_SUCCESS:
    case UPDATE_PROFILE_PICTURE_SUCCESS:
      if (action.payload && action.payload.userData && action.payload.userData.entities) {
        return merge({}, state, action.payload.userData.entities.users)
      }
    default:
      return state
  }
}

function allUsers(state = [], action) {
  switch (action.type) {
    case FETCH_USERS_SUCCESS:
    case FETCH_USER_TO_VALIDATE_SUCCESS:
    case UPDATE_PROFILE_PICTURE_SUCCESS:
      if (action.payload && action.payload.result) {
        return union([], state, action.payload.result)
      }
      return state
    default:
      return state
  }
}

export const getUser = (state, user) => {
  return state.entities.users.byId[user]
}

export const getAllUserIds = state => {
  return state.entities.users.byId
}

export const users = combineReducers({
  byId: usersById,
  allIds: allUsers,
})