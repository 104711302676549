import React, { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { chunk } from 'lodash'
import styled from '@emotion/styled'

import { ShotListHeader } from './ShotListHeader'
import { ShotPageConnected } from './ShotPage'
import { units } from '../../styles'
import { getProject } from '../../reducers/project'
import { getShotIds } from '../../reducers/shot'
import { fetchShotsByProjectIdRequest } from '../../actions/shot'
import { fetchStatusesRequest } from '../../actions/status'
import { fetchTagsRequest } from '../../actions/tag'
import { getProjectShotSelection } from '../../reducers/selection'

let Container = styled.div({
  width: '100%',
  padding: `${units.base} 0 80vh`,
  height: '100%',
  overflow: 'scroll',
})

const ShotList = ({
  projectId,
  project,
  shotIds,
  fetchShotsByProjectIdRequest,
  fetchStatusesRequest,
  fetchTagsRequest
}) => {
  useEffect(() => {
    fetchShotsByProjectIdRequest(projectId)
    fetchStatusesRequest()
    fetchTagsRequest()
  }, [
    projectId,
    fetchShotsByProjectIdRequest,
    fetchStatusesRequest,
    fetchTagsRequest
  ])

  let scrollEl = useRef(null)

  return !project ? (
    'cannot find project'
  ) : (
    <Container ref={scrollEl}>
      <Helmet>
        <title>SA Shots: {project ? project.title : ''}</title>
      </Helmet>
      <ShotListHeader />
      {shotIds && (
        <div className={['scrollContainer']}>
          {chunk(shotIds, 50).map((shotIdsChunk, index) => (
            <ShotPageConnected
              key={index}
              shotIds={shotIdsChunk}
              scrollEl={scrollEl}
            />
          ))}
        </div>
      )}
    </Container>
  )
}

const mapStateToProps = (state, { match }) => {
  let projectId = parseInt(match.params.projectId)
  let project = getProject(state, match.params.projectId)

  let getSelectedStatus = getProjectShotSelection(state, {
    projectId,
    selection: 'statusId'
  })

  let shotIds = getShotIds(state, { projectId, statusId: getSelectedStatus })

  return {
    projectId,
    project: project,
    shotIds
  }
}

const ShotListConnected = connect(
  mapStateToProps,
  { fetchShotsByProjectIdRequest, fetchStatusesRequest, fetchTagsRequest }
)(ShotList)

export { ShotList, ShotListConnected }
