export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export const setEditUserSelection = (editable) => {
    return {
      type: EDIT_USER_REQUEST,
      payload: editable
    }
  }
  
  export const setEditUserSelectionSuccess = (editable) => {
    return {
      type: EDIT_USER_SUCCESS,
      payload: editable
    }
  }
  
  export const setEditUserSelectionFailure = e => {
    return {
      type: EDIT_USER_FAILURE,
      payload: e,
      error: true
    }
  }

  export const updateUserRequest = (userData) => {
    return {
      type: UPDATE_USER_REQUEST,
      payload: userData
    }
  }
  
  export const updateUserSuccess = ({ userData }) => {
    return {
      type: UPDATE_USER_SUCCESS,
      payload: {userData }
    }
  }
  
  export const updateUserFailure = e => {
    return {
      type: UPDATE_USER_FAILURE,
      payload: e,
      error: true
    }
  }