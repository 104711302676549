export function mergeObjectsInUnique(array, property) {

    const newArray = new Map();
  
    array.forEach((item) => {
      const propertyValue = item[property];
      newArray.has(propertyValue) ? newArray.set(propertyValue, { ...item, ...newArray.get(propertyValue) }) : newArray.set(propertyValue, item);
    });
  
    return Array.from(newArray.values());
  }

export function addMissingProperty(allProjectValues, userProjects) {
    let arr3 = [...allProjectValues, ...userProjects];                                
    let merged = mergeObjectsInUnique(arr3, "projectId")

    let newList = merged.map(proj =>{ 
        if(!proj.hasOwnProperty('permissions')) {
            return Object.assign({}, proj, {permissions: "", assigned: false})
        }
        else return Object.assign({}, proj, {assigned: true})

     } )
    return newList
}