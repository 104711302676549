import { combineReducers } from 'redux'
import { union } from 'lodash'
import { createSelector } from 'reselect'

import {
  FETCH_SHOT_VERSION_SUCCESS,
  UPDATE_SHOT_VERSION_SUCCESS
} from '../actions/shotVersion'

const shotVersionById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SHOT_VERSION_SUCCESS:
    case UPDATE_SHOT_VERSION_SUCCESS:
      return { ...state, ...action.payload.entities.shotVersions }
    default:
      return state
  }
}

const allShotVersions = (state = [], action) => {
  switch (action.type) {
    case FETCH_SHOT_VERSION_SUCCESS:
      if (action.payload && action.payload.result) {
        return union([], state, action.payload.result)
      }
      return state
    default:
      return state
  }
}

export const shotVersions = combineReducers({
  byId: shotVersionById,
  allIds: allShotVersions
})

export const getShotVersion = createSelector(
  (state, shotVersionId) => {
    return state.entities.shotVersions.byId[shotVersionId]
  },
  shotVersion => shotVersion
)
