import React, { useState } from 'react'
import styled from '@emotion/styled'

import { TopSidebarSectionConnected } from './TopSidebarSection'
import { MiddleSidebarSection } from './Middle/MiddleSidebarSection'
import { BottomSidebarSectionConnected } from './BottomSidebarSection'

import { colors } from '../../styles'
import leftArrow from './Left.svg'

const sidebarWidth = '20vw'
const closebarWidth = '1rem'

const SidebarContainer = styled.aside(({ visible }) => ({
  position: 'relative',
  display: 'flex',
  flex: visible ? `0 0 ${sidebarWidth}` : `0 0 ${closebarWidth}`,
  transition: 'flex 1s ease 0.1s',
  overflow: 'hidden',
  minWidth: visible ?'280px': `0 0 ${closebarWidth}`,
  flex: visible ? `0 0 ${sidebarWidth}` : `0 0 ${closebarWidth}`,
}))

const MainContainer = styled.div(({ visible }) => ({
  position: 'relative',
  left: visible ? `0vw` : `calc(-${sidebarWidth} + ${closebarWidth})`,
  transition: 'left 1s ease 0.1s',
  flex: `0 0 calc(${sidebarWidth})`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minWidth: visible ?'280px': `0 0 ${closebarWidth}`,
  left: visible ? `0vw` : `calc(-${sidebarWidth} + ${closebarWidth})`,
}))

const Icon = styled.img(({ visible }) => ({
  position: 'relative',
  width: '0.9rem',
  height: '0.9rem',
  transition: 'right 0.25s ease, transform 0.25s ease',
  transform: `scale(1) ${visible ? 'rotate(0deg)' : 'rotate(180deg)'}`
}))

const CloseBar = styled.div(({ visible }) => ({
  position: 'absolute',
  right: 0,
  transition: 'border 1s ease 0.1s',
  flex: `0 0 ${closebarWidth}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  borderRight: `0.0625rem solid ${colors.grey}`,
  cursor: 'pointer',
  '&:hover': {
    borderRight: `0.0625rem solid ${colors.day}`,
    [Icon]: {
      transform: `scale(1.5) ${visible ? 'rotate(0deg)' : 'rotate(180deg)'}`
    }
  }
}))

const Sidebar = () => {
  let [visible, setVisibility] = useState(true)
  let visiblityHandler = () => setVisibility(!visible)

  return (
    <SidebarContainer visible={visible}>
      <MainContainer visible={visible}>
        <TopSidebarSectionConnected />
        <MiddleSidebarSection />
        <BottomSidebarSectionConnected />
      </MainContainer>
      <CloseBar onClick={visiblityHandler} visible={visible}>
        <Icon src={leftArrow} visible={visible} />
      </CloseBar>
    </SidebarContainer>
  )
}

export { Sidebar }
