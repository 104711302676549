import { call, all, put, takeEvery } from 'redux-saga/effects'
import * as actions from '../actions/shotVersion'
import * as backend from '../api/backend/index'

function* fetchShotVersion({ payload }) {
  try {
    const shotVersions = yield call(
      backend.shotVersion.fetchShotVersion,
      payload
    )
    yield put(actions.fetchShotVersionSuccess(shotVersions))
  } catch (e) {
    yield put(actions.fetchShotVersionFailure(e))
  }
}

function* updateShotVersion({ payload }) {
  try {
    const shotVersion = yield call(
      backend.shotVersion.updateShotVersion,
      payload
    )
    yield put(actions.updateShotVersionSuccess(shotVersion))
  } catch (e) {
    yield put(actions.updateShotVersionFailure(e))
  }
}

export function* shotVersionSaga() {
  yield all([
    yield takeEvery(actions.FETCH_SHOT_VERSION_REQUEST, fetchShotVersion),
    yield takeEvery(actions.UPDATE_SHOT_VERSION_REQUEST, updateShotVersion)
  ])
}
