import { colors, units, type } from '../../../styles'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

const ddKeyframes = keyframes`
0% {
  opacity: 0;
  transform: scaleY(0)
}
80% {
  opacity: 0.5;
  transform: scaleY(1.1)
}
100% {
  opacity: 1;
  transform: scaleY(1)
}
`
export const DetailsContainer = styled.div({
    position: 'relative',
    width: '100%',
    height: '18rem',
    background: colors.night,
    borderRadius: units.borderRadius,
    outline: '0',
    ':focus': {
      ...type.formActive
    },
    marginTop: "0.1rem",
    display: 'flex',
  })

export const FirstSection = styled.div({
    flex: '1',
    position: 'relative',
    width: '15%',
    height: '18rem',
    background: colors.dark,
})

export const SecondSection = styled.div({
    position: 'relative',
    width: '38%',
    height: '18rem',
    background: colors.dark,
})

export const ThirdSection = styled.div({
    position: 'relative',
    width: '38%',
    height: '18rem',
    background: colors.dark,
})

export const FourthSection = styled.div({
  position: 'relative',
  width: '9%',
  height: '18rem',
  background: colors.dark,
})

export const Label = styled.div({
    fontSize: "0.9rem",
    fontStyle: "bold",
    fontFamily: 'Heebo',
})

export const TextWrap = styled.div({
    position: 'relative',
    height: '6rem',
    padding: '1rem',
})

export const Icon = styled.img(() => ({
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
    borderRadius: '50%',
    height: '8rem',
    width: '8rem',
    padding: '1rem',
    objectFit: 'cover'
  }))

export const Text = styled.div({
    position: 'relative',
    fontSize: "1.2rem",
    fontStyle: "bold",
    color: colors.day,
    paddingTop: '0.2rem',
    fontFamily: 'Heebo',
  })
  
  export const PersonalDetailsContainer = styled.div({
    position: 'relative',
    width: '100%',
    animation: `${ddKeyframes} .7s ease-in-out forwards`,
    transformOrigin: 'top center'
})

export const PreferenceDetailsContainer = styled.div({
  position: 'relative',
  width: '100%',
  animation: `${ddKeyframes} .7s ease-in-out forwards`,
  transformOrigin: 'top center',
})

export const SecurityDetailsContainer = styled.div({
  position: 'relative',
  height:'6rem',
  width: '100%',
  animation: `${ddKeyframes} .7s ease-in-out forwards`,
  transformOrigin: 'top center'
})