import { colors, units, type } from '../../styles'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

const ddKeyframes = keyframes`
0% {
  opacity: 0;
  transform: scaleY(0)
}
80% {
  opacity: 0.5;
  transform: scaleY(1.1)
}
100% {
  opacity: 1;
  transform: scaleY(1)
}
`
export const DetailsContainer = styled.div({
    position: 'relative',
    width: '100%',
    background: colors.darkGrey,
    borderRadius: units.borderRadius,
    outline: '0',
    ':focus': {
        ...type.formActive
    },
    marginTop: "0.1rem",
    fontFamily: 'Heebo',
})

export const ListContainer = styled.div({
    position: 'relative',
    width: '100%',
    height: '4.5rem',
    background: colors.night,
    borderRadius: units.borderRadius,
    outline: '0',
    ':focus': {
        ...type.formActive
    },
    marginTop: "0.1rem",
    display: 'flex',
    fontFamily: 'Heebo',
})

export const Label = styled.div({
    height: '2rem',
    paddingTop: '0.5rem',
    paddingLeft: '1rem',
    fontSize: "1.2rem",
    fontStyle: "bold",
    color: colors.day,
    fontFamily: 'Heebo',
    //i do not know if this auto is needed
    height: 'auto'
})

export const Text = styled.div({
    height: '2rem',
    padding: '0.2rem',
    paddingLeft: '1rem',
    fontSize: "0.8rem",
    color: colors.day,
    fontFamily: 'Heebo',
})

export const Icon = styled.img(() => ({
    position: 'absolute',
    width: '100%',
    minHeight: '4.5rem',
    height:'auto',
    objectFit: 'cover'
}))

export const Wrap = styled.div(() => ({
    position: 'relative',
    width: '100%',
    height: '50%',
    float: 'right',
}))
export const ArrowWrap = styled.div(() => ({
    position: 'relative',
    width: '100%',
    height: '50%',
    float: 'right',
}))

export const AssignProject = styled.div(() => ({
    width: '70%',
    float: 'left',
    padding: '0.5rem',
    fontSize: "0.9rem",
    color: colors.day,
    fontStyle: "bold",
}))

export const StarIcon = styled.img(() => ({
    position: 'relative',
    width: '1rem',
    float: 'right',
    padding: '0.1rem',
    margin: '0.5rem 0.7rem 0.2rem',
    height: '50%',

}))
export const ArrowIcon = styled.img(() => ({
    position: 'relative',
    width: '1rem',
    height: '50%',
    float: 'right',
    padding: '0.1rem',
    margin: '0.2rem 0.7rem 0.5rem'
}))

export const ThirdSectionReadonly = styled.div({
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    width: '42%',
    height: '4.5rem',
    background: colors.dark,
})

export const ThirdSection = styled.div({
    paddingLeft: '1rem',
    position: 'relative',
    width: '24.5%',
    height: '4.5rem',
    background: colors.dark,

        justifyContent: "left",
    alignItems: "center",
})

export const ThirdPlusFourth = styled.div({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: '42%',
    height: '4.5rem',
    background: colors.dark,
})

export const FourthSection = styled.div({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: '17.5%',
    height: '4.5rem',
    fontSize: '0.9rem',
    background: colors.dark,
})

export const FourthSectionReadonly = styled.div({
    position: 'relative',
    width: '5%',
    height: '4.5rem',
    background: colors.dark,
})

export const FifthSection = styled.div({
    position: 'relative',
    width: '5%',
    height: '4.5rem',
    background: colors.dark,
})

export const TextWrap = styled.div({
    position: 'relative',
    height: '6rem',
    padding: '1rem',
})

export const ClickButton = styled.button({
    float: 'left',
    padding: '0.5rem',
    color: colors.day,
    height: '1rem',
    width: '1rem',
    type: 'button'
})

export const FirstSection = styled.div({
    flex: '1',
    position: 'relative',
    width: '15%',
    height: '4.5rem',
    background: colors.dark,
})

export const SecondSection = styled.div({
    position: 'relative',
    width: '38%',
    height: '4.5rem',
    background: colors.dark,
})

export const AssignedProjectsContainer = styled.div({
    position: 'relative',
    width: '100%',
    animation: `${ddKeyframes} .7s ease-in-out forwards`,
    transformOrigin: 'top center'
})