import { combineReducers } from 'redux'
import { EDIT_USER_SUCCESS, UPDATE_USER_SUCCESS} from '../actions/editUser'

const editUser =(state={}, action) =>{
    switch (action.type) {
        case EDIT_USER_SUCCESS:
            return action.payload
        default:
            return state
    }
}

const updateUser=(state={}, action)=> {

    switch(action.type) {
        case UPDATE_USER_SUCCESS:
            return action.payload
        default:
            return state
    }
}

export const getUpdatedUser = (state) => {
    return state.ui.editUserButton.update
}

export const getEditUserSelected =(state)=> {
    return state.ui.editUserButton.edit.payload
}

export const editUserButton = combineReducers({
    edit: editUser,
    update: updateUser
})