import { request } from './index'
import { schemas } from './schemas'
import { normalize } from 'normalizr'

export const fetchProjectByBearer = async () => {
  let res = await request(`/projects`)
  return normalize(res, schemas.PROJECTS)
}

export const fetchProjectsArchived = async () => {
  let res = await request(`/projects?includeArchived=true`)
  return normalize(res, schemas.PROJECTS)
}

export const fetchFullProject = async (projectId) => {
  let res = await request(`/projects/${projectId}`)
  return normalize(res, schemas.PROJECT)
}

export const createProject = async project => {
  let res = await request('/projects', {
    method: 'post',
    data: project
  })
  return res
}

export const updateProject = async project => {
  let res = await request(`/projects/${project.projectId}`, {
    method: 'put',
    data: project
  })
  return res
}

export const deleteProject = async projectId => {
  let res = await request(`/projects/${projectId}`, {
    method: 'delete'
  })
  return res
}

//new
export const updateProjectMembers = async project => {
  let res = await request(`/projects/${project.projectId}/members?action=${project.action}`, {
    method: 'post',
    data: {userId: project.userId, permissions: project.permissions}
  })
  return res
}

export const updateProjectThumb = async ({ projectId, files }) => {
  let formData = new FormData()
  formData.append('files[0]', files[0])
  let res = await request(`/projects/${projectId}/upload-thumb`, {
    method: 'post',
    data: formData
  })
  return res
}