import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

import signleArrow from '../Button/down.svg'
import PersonalDetails from './DetailsComponents/PersonalDetails'
import { SecurityDetails } from './DetailsComponents/SecurityDetails'
import { PreferencesDetails } from './DetailsComponents/PreferencesDetails'
import { InnerContainer } from './Select/SelectStyles'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import mySubmit from './DetailsComponents/mySubmit'
import {
  UserProfileContainer, DropdownContainer, SelectContainer,
  WrapContainer, IconWrap, SelectedItem
} from './UserManagerStyles'

const Icon = styled.img(({ rotated }) => ({
  width: '0.8rem',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transitionProperty: 'transform',
  transitionDuration: '0.2s',
  transform: ` scale(1) translate(-50%, -50%) ${rotated ? 'rotate(0deg)' : 'rotate(90deg)'}`,
}))

let UserProfile = (props) => {

  let [dropdownVisible1, setDropdownVisibility1] = useState(true)
  let clickHandler1 = () => {
    setDropdownVisibility1(!dropdownVisible1)
  }
  let [dropdownVisible2, setDropdownVisibility2] = useState(true)
  let clickHandler2 = () => {
    setDropdownVisibility2(!dropdownVisible2)
  }
  let [dropdownVisible3, setDropdownVisibility3] = useState(true)
  let clickHandler3 = () => {
    setDropdownVisibility3(!dropdownVisible3)
  }
  const [isEditable, setIsEditable] = useState(props.editable)

  useEffect(() => {
    setIsEditable(props.editable)
  }, [props.editable])

  useEffect(() => {
    const { initialize } = props
    initialize(props.userData)
  }, [props.userData.userId])

  const { handleSubmit } = props

  return (
    <form onSubmit={handleSubmit}>

      <UserProfileContainer marginTop="0.1rem">

        <WrapContainer marginTop='0.1rem !important' >
          <SelectContainer >
            <InnerContainer style={{height: '1.75rem'}}>
              <SelectedItem>
                {'Personal Details'}
              </SelectedItem>
              <IconWrap onClick={clickHandler1}>
                <Icon src={signleArrow} rotated={dropdownVisible1} />
              </IconWrap>
            </InnerContainer>
          </SelectContainer>
          <DropdownContainer>
            {(dropdownVisible1) && (<PersonalDetails 
              userData={props.userData && props.userData}
              editable={props.editable ? props.editable : false}
              updateUserRequest={props.updateUserRequest}
              handleSubmit={props.handleSubmit}
              fetchSelectedUserRequest={props.fetchSelectedUserRequest}
            />)}
          </DropdownContainer>
        </WrapContainer>

        <WrapContainer>
          <SelectContainer >
            <InnerContainer style={{height: '1.75rem'}}>
              <SelectedItem>
                {'Security'}
              </SelectedItem>
              <IconWrap onClick={clickHandler2}>
                <Icon src={signleArrow} rotated={dropdownVisible2} />
              </IconWrap>
            </InnerContainer>
          </SelectContainer>
          <DropdownContainer>
            {(dropdownVisible2) && (<SecurityDetails userData={props.userData && props.userData}
              editable={props.editable ? props.editable : false}
              handleSubmit={props.handleSubmit}
            />)}
          </DropdownContainer>
        </WrapContainer>

        <WrapContainer>
          <SelectContainer >
            <InnerContainer style={{height: '1.75rem'}}>
              <SelectedItem>
                {'Preferences'}
              </SelectedItem>
              <IconWrap onClick={clickHandler3}>
                <Icon src={signleArrow} rotated={dropdownVisible3} />
              </IconWrap>
            </InnerContainer>
          </SelectContainer>
          <DropdownContainer>
            {(dropdownVisible3) && (<PreferencesDetails userData={props.userData && props.userData}
              editable={props.editable ? props.editable : false}
              handleSubmit={props.handleSubmit}
            />)}
          </DropdownContainer>
        </WrapContainer>

      </UserProfileContainer>
    </form>
  )
}

UserProfile = reduxForm({
  form: 'userProfileDataForm',
  onSubmit: mySubmit,

})(UserProfile)

UserProfile = connect(null)(UserProfile)

export { UserProfile }