import { call, all, put, takeEvery } from 'redux-saga/effects'
import * as actions from '../actions/project'
import * as backend from '../api/backend/index'

function* deleteProject({ type, payload }) {
  try {
    const project = yield call(backend.project.deleteProject, payload)
    yield put(actions.deleteProjectSuccess(project))
  } catch (e) {
    yield put(actions.deleteProjectFailure(e))
  }
}

function* updateProject({ payload }) {
  try {
    const project = yield call(backend.project.updateProject, payload)
    yield put(actions.updateProjectSuccess(project))
  } catch (e) {
    yield put(actions.updateProjectFailure(e))
  }
}

function* updateProjectMembers({ payload }) {
  try {
    const project = yield call(backend.project.updateProjectMembers, payload)
    yield put(actions.updateProjectMembersSuccess(project))
  } catch (e) {
    yield put(actions.updateProjectMembersFailure(e))
  }
}

function* createProject({ payload }) {
  try {
    const project = yield call(backend.project.createProject, payload)
    yield put(actions.createProjectSuccess(project))
  } catch (e) {
    yield put(actions.createProjectFailure(e))
  }
}

function* fetchFullProject( {payload} ) {

  try {
    const projects = yield call(backend.project.fetchFullProject, payload)
    yield put(actions.fetchFullProjectSuccess(projects))
  } catch (e) {
    yield put(actions.fetchFullProjectFailure(e))
  }
}

function* fetchUserProjects() {
  try {
    const projects = yield call(backend.project.fetchProjectByBearer)
    yield put(actions.fetchUserProjectsSuccess(projects))
  } catch (e) {
    yield put(actions.fetchUserProjectsFailure(e))
  }
}

function* fetchProjectsArchived() {
  try {
    const projects = yield call(backend.project.fetchProjectsArchived)
    yield put(actions.fetchProjectsArchivedSuccess(projects))
  } catch (e) {
    yield put(actions.fetchProjectsArchivedFailure(e))
  }
}

function* updateProjectPicture({ payload }) {
  try {
    const project = yield call(backend.project.updateProjectThumb, payload)
    yield put(actions.updateProjectPictureSuccess({ project }))
  } catch (e) {
    yield put(actions.updateProjectPictureFailure(e))
  }
}

export function* projectRootSaga() {
  yield all([
    yield takeEvery(actions.FETCH_USER_PROJECTS_REQUEST, fetchUserProjects),
    yield takeEvery(actions.FETCH_FULL_PROJECT_REQUEST, fetchFullProject),
    yield takeEvery(actions.CREATE_PROJECT_REQUEST, createProject),
    yield takeEvery(actions.UPDATE_PROJECT_REQUEST, updateProject),
    yield takeEvery(actions.UPDATE_PROJECT_MEMBERS_REQUEST, updateProjectMembers),
    yield takeEvery(actions.DELETE_PROJECT_REQUEST, deleteProject),
    yield takeEvery(actions.FETCH_PROJECTS_ARCHIVED_REQUEST, fetchProjectsArchived),
    yield takeEvery(actions.UPDATE_PROJECT_PICTURE_REQUEST, updateProjectPicture)
  ])
}
