import React from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'

import Button from '../../Button/Button'
import { FieldFull } from '../components/Fields'
import { Fieldset, Form, HR } from '../components/Layout'
import { onSubmitCreator } from '../helpers/onSubmitCreator'

import { createUserRequest } from '../../../actions/createUser'

// doing some simple validations
const userInitialValues = {
  firstName: '',
  lastName: '',
  role: '',
  company: '',
  function: '',
  email: '',
  avatarURL: '',
}

const UserSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(3, 'Too Short!')
    .max(20, 'Too Long!'),
  role: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!'),
  company: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!'),
  function: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!'),
  email: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!'),
  avatarURL: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!'),
})
// actual component
const CreateUserForm = ({
  onSubmit,
  createUserRequest
}) => {
  return (
    <Formik
      initialValues={userInitialValues}
      validationSchema={UserSchema}
      onSubmit={onSubmitCreator(createUserRequest)}
    >
      {({ isSubmitting, handleReset, handleSubmit }) => (
      <Form onReset={handleReset} onSubmit={handleSubmit}>
        <Fieldset>
          <FieldFull
            name="firstName"
            label="Name"
            autoComplete="name"
            readOnly
          />
          <FieldFull
            name="lastName"
            label="Surname"
            autoComplete="surname"
          // type="password"
          />
          <FieldFull
            name="role"
            label="Role"
            autoComplete="role"
          // type="password"
          />            
          <FieldFull
            name="company"
            label="Company"
            autoComplete="company"
          />
          <FieldFull
            name="function"
            label="Function"
            autoComplete="function"
          />
          <FieldFull
            name="email"
            label="Email"
            autoComplete="email"
          />
          <FieldFull
            name="avatarURL"
            label="Avatar Url"
            autoComplete="avatarURL"
          />        
        </Fieldset>

        <Fieldset>
          <Button data-type="large" type="submit" >
            {/* {'Create User'} */}
            {isSubmitting ? 'Creating User' : 'Create User'}
          </Button>
        </Fieldset>

        <Fieldset>
          <HR />
          {/* <LinkStyled to="/auth/retrieve"> Retrieve Password?</LinkStyled> */}
        </Fieldset>
      </Form>
      )}
    </Formik>
  )
}

// HOC for redux
const CreateUserFormConnected = connect(
  null,
  {
    createUserRequest
  }
)(CreateUserForm)

export { CreateUserForm, CreateUserFormConnected }
