import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'

import { getShot } from '../../reducers/shot'
import { fetchShotRequest } from '../../actions/shot'

import { colors, type } from '../../styles/'

const StyledNavLink = styled(NavLink)({
  ...type.subHeader,
  padding: '0 1rem',
  borderRight: `0.0625rem solid ${type.colors.grey}`,
  ':last-of-type': {
    borderRight: 'unset'
  },
  color: colors.grey,

  '&.active': {
    color: colors.day
  }
})

const Label = styled.span({
  color: colors.grey
})

const ShotTitle = ({ shot, shotId, projectId, fetchShotRequest }) => {
  useEffect(() => {
    fetchShotRequest({ shotId })
  }, [shotId, fetchShotRequest])
  return (
    <StyledNavLink to={{ pathname: `/project/${projectId}/shot/${shotId}` }}>
      <Label>shot:</Label> {shot && shot.shotCode}
    </StyledNavLink>
  )
}

const mapStateToProps = (state, ownProps) => {
  let { shotId, projectId } = ownProps.match.params
  return {
    shot: getShot(state, shotId),
    shotId,
    projectId
  }
}

const ShotTitleConnected = connect(
  mapStateToProps,
  { fetchShotRequest }
)(ShotTitle)

export { ShotTitle, ShotTitleConnected }
