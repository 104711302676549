import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'

import { DropdownConnected } from './Dropdown'

import { getStatus } from '../../reducers/status'
import { units, colors, type } from '../../styles'

const Wrapper = styled.div({
  width: '100%',
  position: 'relative'
})

const StatusContainer = styled.div(({ backgroundColor }) => {
  return {
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'space-between',
    padding: units.padding.s,

    background: backgroundColor || colors.grey,
    borderRadius: units.borderRadius,

    cursor: 'pointer',

    ':hover': {
      filter: 'brightness(1.2)'
    }
  }
})

const SubHeading = styled.h3({
  ...type.subHeaderBold
})

const Status = ({ status, statusHandler }) => {
  let [dropdownIsVisible, setDropdownVisibility] = useState(false)

  return (
    <Wrapper
      onClick={() => {
        setDropdownVisibility(!dropdownIsVisible)
      }}
      onMouseLeave={() => {
        setDropdownVisibility(false)
      }}
    >
      <StatusContainer backgroundColor={status && status.color}>
        <SubHeading>{status ? status.title : 'No status'}</SubHeading>
      </StatusContainer>

      {dropdownIsVisible && <DropdownConnected statusHandler={statusHandler} />}
    </Wrapper>
  )
}

const mapStateToProps = (state, { statusId }) => {
  return {
    status: getStatus(state, statusId)
  }
}

const StatusConnected = connect(mapStateToProps)(Status)

export { Status, StatusConnected }
