import React, { useState } from 'react'
import { SelectForProjectsManager as Select } from '../../Forms/components/SelectForProjectsManager'
import { SelectForProjectsManagerTags } from '../../Forms/components/SelectForProjectsManagerTags'
import { SelectColors } from '../../Forms/components/SelectColors'
import { Text } from './DetailsComponentsStyles'

export const SelectOption = (props) => {
  const [selected, setSelected] = useState('')

  if (props && props.options) {

    const { input } = props

    return (
      <div >
        <Select
          key={props.input}
          className='black-select-projects'
          options={[...props.options].map(
            option => {
              return {
                type: 'action',
                action: () => {

                  props.input.onChange(option.value)
                  setSelected(option.label)
                },
                name: option.label
              }
            }
          )}

          input={input && input}
          selectedOption={selected}

        ></Select>
      </div>
    )
  }
  return <div></div>
}

export const SelectOptionTags = (props) => {

  let currentTag = props.projTags && props.projTags.filter(function (obj) {
    return obj.tagId == props.input.value;
  });

  const [selected, setSelected] = useState('')

  if (props && props.options) {

    const { input } = props

    return (
      <div >
        <SelectForProjectsManagerTags
          key={props.input}
          className='black-select-projects'
          options={[...props.options].map(
            option => {
              return {
                type: 'action',
                action: () => {

                  props.input.onChange(option.value)
                  setSelected(option.label)
                },
                name: option.label
              }
            }
          )}

          input={input && input}
          selectedOption={selected}
          title={currentTag[0] ? currentTag[0].title : ''}

        ></SelectForProjectsManagerTags>
      </div>
    )
  }
  return <div></div>
}

export const SelectOptionStatuses = (props) => {

  let currentStatus = props.statuses && props.statuses.filter(function (obj) {
    return obj.statusId == props.input.value;
  });

  const [selected, setSelected] = useState('')

  if (props && props.options) {

    const { input } = props

    return (
      <div >
        <SelectForProjectsManagerTags
          key={props.input}
          className='black-select-projects'
          options={[...props.options].map(
            option => {
              return {
                type: 'action',
                action: () => {

                  props.input.onChange(option.value)
                  setSelected(option.label)
                },
                name: option.label
              }
            }
          )}

          input={input && input}
          selectedOption={selected}
          title={currentStatus[0] ? currentStatus[0].title : ''}

        ></SelectForProjectsManagerTags>
      </div>
    )
  }
  return <div></div>
}

export const SelectOptionUsers = (props) => {

  let currentUser = props && props.allUsersValues.filter(function (obj) {
    return obj.userId == props.input.value;
  });

  const [selected, setSelected] = useState('')

  if (props && props.options) {

    const { input } = props

    if (props.meta.initial) {
      return (
        <div style={{paddingLeft:'0.2rem'}}>
          <Text>{currentUser[0] ? (currentUser[0].firstName + ' ' + currentUser[0].lastName) : ''}</Text>
        </div>
      )
    } else {
      return (
        <div >
          <SelectForProjectsManagerTags
            key={props.input}
            className='black-select-projects'
            options={[...props.options].map(
              option => {
                return {
                  type: 'action',
                  action: () => {

                    props.input.onChange(option.value)
                    setSelected(option.label)
                  },
                  name: option.label
                }
              }
            )}

            input={input && input}
            selectedOption={selected}
            title={currentUser[0] ? (currentUser[0].firstName + ' ' + currentUser[0].lastName) : ''}

          ></SelectForProjectsManagerTags>
        </div>
      )
    }
  }
  return <div></div>
}


export const ColorsSelect = (props) => {
  const [selected, setSelected] = useState('')

  if (props && props.options) {

    const { input } = props

    return (
      <div >
        <SelectColors
          key={props.input}
          className='black-select-projects'
          options={[...props.options].map(
            option => {
              return {
                type: 'action',
                action: () => {

                  props.input.onChange(option.value)
                  setSelected(option.label)
                },
                name: '',
                //new
                color: option.value
              }
            }
          )}

          input={input && input}
          selectedOption={selected}

        ></SelectColors>
      </div>
    )
  }
  return <div></div>
}

export const ButtonChoose = ({
  input,
  meta: { touched, error, warning },
}) => (

    <div >
      <div className='black-button-field-projects' >
        <button type='button' className={input.value ? 'grey-button' : 'black-button'} onClick={() => input.onChange(true)}>Yes</button>
        <button type='button' className={input.value ? 'black-button' : 'grey-button'} onClick={() => input.onChange(false)}>No</button>
      </div>
    </div >
  )