import React from 'react'
import styled from '@emotion/styled'
import { ellipsis } from 'polished'

import { type, colors } from '../../styles'

const List = styled.ul({
  display: 'grid',
  gridTemplateColumns: '10rem 15rem 15rem 10rem 1fr',
  padding: '1rem 0 1.6666rem 0',
  borderBottom: `0.0625rem solid ${colors.grey}`
})

const ListItem = styled.span({
  ...type.subHeader,
  width: '100%',
  paddingLeft: '1rem',
  ...ellipsis('100%')
})

const ListItemPadded = styled(ListItem)({
  paddingLeft: '1.5rem'
})

const ShotListHeader = () => {
  return (
    <List>
      <ListItem>Title</ListItem>
      <ListItem>VFX ID</ListItem>
      <ListItemPadded>Status</ListItemPadded>
      <ListItem>Details</ListItem>
      <ListItem>Description</ListItem>
    </List>
  )
}

export { ShotListHeader }
