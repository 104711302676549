import axios from 'axios'

import * as project from './project'
import * as user from './user'
import * as shot from './shot'
import * as shotVersion from './shotVersion'
import * as status from './status'
import * as tag from './tag'
import * as userManager from './userManager'
import * as episode from './episode'
import * as sites from './sites'
import * as techSpecs from './techSpecs'

export { shot, shotVersion, project, user, status, 
        tag, userManager, episode, sites, techSpecs }

/* 
The request function configures the request library (axios) with a a baseURL and a token. 
*/
export const request = async (endpoint, options = {}) => {
  let instance = axios.create({
    baseURL: 'https://api.staging.spaceadmin.nl'
  })

  let token = window.localStorage.getItem('SA_TOKEN')

  if (token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }

  let res = await instance.request(endpoint, options)
  return res.data
}
