import { call, all, put, takeEvery } from 'redux-saga/effects'
import * as actions from '../actions/shot'
import * as backend from '../api/backend/index'

import { SET_PROJECT_SHOT_SELECTION } from '../actions/ui'

// creating shots
function* createShot({ payload }) {
  try {
    const shot = yield call(backend.shot.createShot, payload)
    yield put(actions.createShotSuccess(shot))
  } catch (e) {
    yield put(actions.createShotFailure(e))
  }
}

// read shots
function* fetchShotsByProjectId(action) {
  try {
    const shots = yield call(backend.shot.fetchShotsByProjectId, action.payload)

    yield put(
      actions.fetchShotsByProjectIdSuccess({
        ...shots,
        request: action.payload
      })
    )
  } catch (e) {
    yield put(actions.fetchShotsByProjectIdFailure(e))
  }
}

function* fetchShotList({ payload: { shotIds } }) {
  try {
    const shots = yield call(backend.shot.fetchListOfShots, { shotIds })
    yield put(actions.fetchShotListSuccess(shots))
  } catch (e) {
    yield put(actions.fetchShotListFailure(e))
  }
}

function* fetchShot({ payload }) {
  try {
    const shots = yield call(backend.shot.fetchShot, payload)
    yield put(actions.fetchShotSuccess(shots))
  } catch (e) {
    yield put(actions.fetchShotFailure(e))
  }
}

// updating shots
function* updateShot({ payload }) {
  try {
    const shot = yield call(backend.shot.updateShot, payload)
    yield put(actions.updateShotSuccess({ shot }))
  } catch (e) {
    yield put(actions.updateShotFailure(e))
  }
}

function* updateShotThumb({ payload }) {
  try {
    const shot = yield call(backend.shot.updateShotThumb, payload)
    yield put(actions.updateShotThumbSuccess({ shot }))
  } catch (e) {
    yield put(actions.updateShotThumbFailure(e))
  }
}

function* deleteShot({ payload }) {
  try {
    const shot = yield call(backend.shot.deleteShot, payload)
    yield put(actions.deleteShotSuccess(shot))
  } catch (e) {
    yield put(actions.deleteShotFailure(e))
  }
}

// export shot saga
export function* shotSaga() {
  yield all([
    yield takeEvery(actions.CREATE_SHOT_REQUEST, createShot),
    yield takeEvery(actions.FETCH_SHOT_REQUEST, fetchShot),
    yield takeEvery(actions.FETCH_SHOT_LIST_REQUEST, fetchShotList),
    yield takeEvery(
      actions.FETCH_SHOTS_BY_PROJECT_ID_REQUEST,
      fetchShotsByProjectId
    ),
    yield takeEvery(SET_PROJECT_SHOT_SELECTION, fetchShotsByProjectId),
    yield takeEvery(actions.UPDATE_SHOT_REQUEST, updateShot),
    yield takeEvery(actions.UPDATE_SHOT_THUMB_REQUEST, updateShotThumb),
    yield takeEvery(actions.DELETE_SHOT_REQUEST, deleteShot)
  ])
}
