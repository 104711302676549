import {FETCH_SELECTED_USER_PROJECTS_SUCCESS} from '../actions/userManager'
import { combineReducers } from 'redux'

export const selectedUserProjects = (state = {}, action) => {
    switch (action.type) {
        case FETCH_SELECTED_USER_PROJECTS_SUCCESS:
            return action.payload.projects.projects

        default:
            return state
    }
}

export const getSelectedUserProjects = (state, userId) => {
    return state.entities.userManager.allProjects
}

export const userManager = combineReducers({
    allProjects: selectedUserProjects
})