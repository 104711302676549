import React, { useState, useEffect } from 'react'
import doubleArrow from '../Button/down.svg'
import { SelectContainer, Wrap, InnerContainer, SelectedItem, Icon, IconWrap, DropdownContainer, UserProfileContainer } from './TechSpecsStyles'
import { Row, Section, Container, Label, TextWrap, CloseIcon , Space} from './DropdownsStyles'
import {  Field } from 'redux-form'
import { Draggable } from "react-beautiful-dnd";
import { colors } from '../../styles'
import closeIcon from './closeIcon.svg'

const renderField = ({
    input, label, type, meta: { touched, error, warning },
}) => (
        <div >
            <label>{label}</label>
            <div >
                <input className="black-field-projects" {...input} placeholder={label} type={type}
                />
            </div>
        </div>
    )

const renderNameField = ({
    input, label, type, meta: { touched, error, warning },
}) => (
        <div >
            <label>{label}</label>
            <div >
                <div  {...input} placeholder={label} type={type}
                >{input.value}
                </div>
            </div>
        </div>
    )

export const ButtonChoose = ({
    input,
    meta: { touched, error, warning },
}) => (

        <div >
            <div className='black-button-field-projects' >
                <button type='button' className={input.value ? 'grey-button-projects' : 'black-button-projects'} onClick={() => input.onChange(true)}>Yes</button>
                <button type='button' className={input.value ? 'black-button-projects' : 'grey-button-projects'} onClick={() => input.onChange(false)}>No</button>
            </div>
        </div >
    )

let DropdownsEditable = (props, { propsMatch, fields,isCreateSpecs }) => {

    const [dropdownName, setDropdownName] = useState(props.innerName)

    let [dropdownVisible, setDropdownVisibility] = useState(true)
    let clickHandler = () => setDropdownVisibility(!dropdownVisible)

    useEffect(() => {
        setDropdownName(props.innerName)
    }, [props.innerName])

    const getItemStyle = (isDragging, draggableStyle) => ({
        position: 'relative',
        height: '20%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        background: colors.night,
        borderBottom: '0.0625rem solid #4a4b4c',
        ...draggableStyle
    });

    return (
        <Draggable key={props.index} draggableId={`${props.singleSpec}.techSpecId`} 
        index={props.index} 
        >
            {(provided, snapshot) => (

                <div style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                )}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div style={{
                        position: 'relative',
                        height: '20%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        background: colors.night,
                        borderBottom: '0.0625rem solid #4a4b4c',
                    }}>
                        <Wrap >
                            <SelectContainer >
                                <InnerContainer  >
                                    <SelectedItem >
                                        <Field
                                            name={`${props.singleSpec}.techSpecId`}
                                            type="text"
                                            component={renderNameField}
                                        />
                                    </SelectedItem>
                                    <Space ></Space>

                                    <CloseIcon src={closeIcon} onClick={() => props.fields.remove(props.index)}></CloseIcon>

                                    <IconWrap>
                                        <Icon src={doubleArrow} onClick={clickHandler} rotated={dropdownVisible} />
                                    </IconWrap>
                                </InnerContainer>
                            </SelectContainer>
                            <DropdownContainer >
                                {dropdownVisible && (
                                    <UserProfileContainer marginTop="0.1rem" name={dropdownName}
                                    >

                                        <Container>
                                            <Row>
                                                <Section>

                                                    <TextWrap >
                                                        <Label>Frame Aspect Ratio</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.frameAspectRatio`}
                                                            type="text"
                                                            component={renderField}
                                                        />
                                                    </TextWrap>

                                                </Section>

                                                <Section>
                                                    <TextWrap >
                                                        <Label>Frame Rate</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.framerate`}
                                                            type="text"
                                                            component={renderField}
                                                        />
                                                    </TextWrap>
                                                </Section>

                                                <Section>
                                                    <TextWrap >
                                                        <Label>Pixel Aspect Ratio</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.pixelAspectRatio`}
                                                            type="text"
                                                            component={renderField}
                                                        />
                                                    </TextWrap>
                                                </Section>

                                                <Section>
                                                    <TextWrap >
                                                        <Label>Resolution Height</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.resolutionHeight`}
                                                            type="text"
                                                            component={renderField}
                                                        />
                                                    </TextWrap>
                                                </Section>

                                            </Row>

                                            <Row>
                                                <Section>

                                                    <TextWrap >
                                                        <Label>Resolution Width</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.resolutionWidth`}
                                                            type="text"
                                                            component={renderField}
                                                        />
                                                    </TextWrap>

                                                </Section>

                                                <Section>
                                                    <TextWrap >
                                                        <Label>Input Output</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.inputOutput`}
                                                            type="text"
                                                            component={renderField}
                                                        />
                                                    </TextWrap>
                                                </Section>

                                                <Section>
                                                    <TextWrap >
                                                        <Label>Handles In</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.handlesIn`}
                                                            type="text"
                                                            component={renderField}
                                                        />
                                                    </TextWrap>
                                                </Section>

                                                <Section>
                                                    <TextWrap >
                                                        <Label>Handles Out</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.handlesOut`}
                                                            type="text"
                                                            component={renderField}
                                                        />
                                                    </TextWrap>
                                                </Section>

                                            </Row>

                                            <Row>
                                                <Section>

                                                    <TextWrap >
                                                        <Label>Object Type</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.objectType`}
                                                            type="text"
                                                            component={renderField}
                                                        />
                                                    </TextWrap>

                                                </Section>

                                                <Section>
                                                    <TextWrap >
                                                        <Label>Color Space</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.colorSpace`}
                                                            type="text"
                                                            component={renderField}
                                                        />
                                                    </TextWrap>
                                                </Section>

                                                <Section>
                                                    <TextWrap >
                                                        <Label>Look Up Table</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.lookUpTable`}
                                                            type="text"
                                                            component={renderField}
                                                        />
                                                    </TextWrap>
                                                </Section>

                                                <Section>
                                                    <TextWrap >
                                                        <Label>Video Codec</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.videoCodec`}
                                                            type="text"
                                                            component={renderField}
                                                        />
                                                    </TextWrap>
                                                </Section>

                                            </Row>

                                            <Row>
                                                <Section>

                                                    <TextWrap >
                                                        <Label>Audio Codec</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.audioCodec`}
                                                            type="text"
                                                            component={renderField}
                                                        />
                                                    </TextWrap>

                                                </Section>

                                                <Section>
                                                    <TextWrap >
                                                        <Label>Container</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.container`}
                                                            type="text"
                                                            component={renderField}
                                                        />
                                                    </TextWrap>
                                                </Section>

                                                <Section>
                                                    <TextWrap >
                                                        <Label>File Name Format</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.filenameFormat`}
                                                            type="text"
                                                            component={renderField}
                                                        />
                                                    </TextWrap>
                                                </Section>

                                                <Section>
                                                    <TextWrap >
                                                        <Label>Notes</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.notes`}
                                                            type="text"
                                                            component={renderField}
                                                        />
                                                    </TextWrap>
                                                </Section>

                                            </Row>

                                            <Row>
                                                <Section>

                                                    <TextWrap >
                                                        <Label>Interlaced</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.isInterlaced`}
                                                            component={ButtonChoose}
                                                        />
                                                    </TextWrap>

                                                </Section>

                                                <Section>
                                                    <TextWrap >
                                                        <Label>Private</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.isPrivate`}
                                                            component={ButtonChoose}
                                                        />
                                                    </TextWrap>
                                                </Section>

                                                <Section>
                                                    <TextWrap >
                                                        <Label>Stereo</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.isStereo`}
                                                            component={ButtonChoose}
                                                        />
                                                    </TextWrap>
                                                </Section>

                                                <Section>
                                                    <TextWrap >
                                                        <Label>XR</Label>
                                                        <Field
                                                            name={`${props.singleSpec}.isXR`}
                                                            component={ButtonChoose}
                                                        />
                                                    </TextWrap>
                                                </Section>

                                            </Row>

                                        </Container>

                                    </UserProfileContainer>
                                )}
                            </DropdownContainer>
                        </Wrap>
                    </div>

                </div>
            )}
        </Draggable>
    )
}

export { DropdownsEditable }