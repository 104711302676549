function mySubmit(values, dispatch, props) {

    let arr=values.techSpecsArray.map((value) => {
        return value.techSpecId
    })

    props.reorderTechSpecsRequest({orderedTechSpecIds: arr, projectId: values.techSpecsArray[0].projectId})

    values.techSpecsArray.map((value) => {
        if(value.techSpecId) {
            props.updateTechSpecsRequest(value)
        }
    })

    let allTechSpecs = Object.entries(props.allSpecs && props.allSpecs).map(([key, value]) => {
        return value
    })
     let arrayDeletedMembers = allTechSpecs.filter(o => !values.techSpecsArray.find(o2 => o.techSpecId === o2.techSpecId))

     arrayDeletedMembers.map((deleted)=> {
         props.deleteTechSpecsRequest(deleted.techSpecId)
     })

}

export default mySubmit