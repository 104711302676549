import React from 'react'
import { Container, Row, Section, Text } from './DetailsComponentsStyles'
import { Field } from 'redux-form'
import styled from '@emotion/styled'

const Label = styled.div({
    fontSize: "0.9rem",
    fontStyle: "bold",
    paddingBottom: '0.5rem'
})

const renderField = ({
    input, label, type, meta: { touched, error, warning },
}) => (
        <div>
            <label>{label}</label>
            <div  >
                <input className="black-field-projects"   {...input} placeholder={label} type={type}
                />
            </div>
        </div>
    )

const Company = (props) => {
    return (
        <React.Fragment>
            {(() => {
                if (props.isEditable) {
                    return (
                        <Container>
                            <Row>
                                <Section>
                                    <Label>Company Name</Label>
                                    <Field
                                        name='companyName'
                                        type="text"
                                        component={renderField}
                                    />
                                </Section>
                                <Section>
                                    <Label>Company Address</Label>
                                    <Field
                                        name='companyAddress'
                                        type="text"
                                        component={renderField}
                                    />
                                </Section>
                                <Section>
                                    <Label>Company Country</Label>
                                    <Field
                                        name='companyCountry'
                                        type="text"
                                        component={renderField}
                                    />
                                </Section>

                                <Section>
                                    <Label>Company Zip Code</Label>
                                    <Field
                                        name='companyZipCode'
                                        type="text"
                                        component={renderField}
                                    />
                                </Section>
                            </Row>
                        </Container>
                    )
                } else {
                    return (
                        <Container>
                            <Row>
                                <Section>
                                    <Label>Company Name</Label>
                                    <Text>{props.site.companyName}</Text>
                                </Section>
                                <Section>
                                    <Label>Company Address</Label>
                                    <Text>{props.site.companyAddress}</Text>
                                </Section>
                                <Section>
                                    <Label>Company Country</Label>
                                    <Text>{props.site.companyCountry}</Text>
                                </Section>
                                <Section>
                                    <Label>Company Zip Code</Label>
                                    <Text>{props.site.companyZipCode}</Text>
                                </Section>
                            </Row>
                        </Container>
                    )
                }
            })()}
        </React.Fragment>
    )
}

export default Company
