import { all } from 'redux-saga/effects'

import { user } from './user'
import { projectRootSaga } from './project'
import { notificationRootSaga } from './notification'
import { shotSaga } from './shot'
import { shotVersionSaga } from './shotVersion'
import { status } from './status'
import { tagRootSaga } from './tag'
import { selection } from './selection'
import { fetchUserProfile } from './fetchUserProfile'
import { userManager } from './userManager'
import { editUserSelection } from './editUser'
import { updateUserSaga } from './updateUser'
import { createUserSaga } from './createUser'
import { episodeRootSaga } from './episode'
import { sitesRootSaga } from './sites'
import { techSpecsRootSaga } from './techSpecs'

export default function* rootSaga(api) {
  yield all([
    user(api),
    notificationRootSaga(),
    projectRootSaga(),
    shotSaga(),
    shotVersionSaga(),
    status(),
    tagRootSaga(),
    episodeRootSaga(),
    selection,
    fetchUserProfile(api),
    userManager(),
    editUserSelection(),
    updateUserSaga(),
    createUserSaga(),
    sitesRootSaga(),
    techSpecsRootSaga()
  ])
}
