import React from 'react'
import { Route, Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from '@emotion/styled'

import { ShotTitleConnected } from './ShotTitle'
import { getProject } from '../../reducers/project'

import logo from '../../assets/logos/logo-small.svg'
import { colors, type, layout } from '../../styles'

const RightContainer = styled.div({
  flex: `0 0 ${layout.right}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 1rem 0 0',
  minWidth: '600px'
})

const StyledNavLink = styled(NavLink)({
  ...type.subHeader,
  padding: '0 1rem',
  borderRight: `0.0625rem solid ${type.colors.grey}`,
  ':last-of-type': {
    borderRight: 'unset'
  },
  color: colors.lightGrey,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
    color: colors.day
  },
  '&.active': {
    color: colors.day
  }
})

const LogoLinkContainer = styled(Link)({
  ':only-child': {
    marginLeft: 'auto'
  }
})

const LogoIcon = styled.img({
  height: '2rem'
})

const LinksContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between'
})

const NavLinks = ({ projectId, shotId }) => {
  return (
    <LinksContainer>
      <StyledNavLink
        to={`/project/${projectId}/shot/all`}
        activeClassName={'active'}
      >
        Shots
      </StyledNavLink>
      <Route
        path="/project/:projectId(\d+)/shot/:shotId(\d+)"
        component={ShotTitleConnected}
      />
      <StyledNavLink
        to={`/project/${projectId}/edit`}
        activeClassName={'active'}
      >
        Configure
      </StyledNavLink>

      <StyledNavLink
        to={`/project/${projectId}/techspecs`}
        activeClassName={'active'}
      >
        Tech Specs
      </StyledNavLink>
    </LinksContainer>
  )
}

const mapStateToProps = (state, ownProps) => {
  let { projectId, shotId } = ownProps.match.params
  return {
    project: getProject(state, projectId),
    projectId,
    shotId
  }
}

const NavLinksConnected = connect(mapStateToProps)(NavLinks)

const MainNav = () => {
  return (
    <RightContainer>
      <Route path="/project/:projectId(\d+)" component={NavLinksConnected} />
      {/* <Route path="/project/#/techspecs" /> */}
      <Route path="/users/:userId(\d+)" />
      <Route path="/projects/manager" />
      <Route path="/project/manager/:projectId(\d+)" />
      <Route path="/sites" />


      <LogoLinkContainer to="/">
        <LogoIcon src={logo} alt="logo" />
      </LogoLinkContainer>
    </RightContainer>
  )
}

export { MainNav, NavLinks, NavLinksConnected }
