import { call, put, takeEvery, all } from 'redux-saga/effects'
import * as actions from '../actions/editUser'
import * as backend from '../api/backend/index'

function* updateUserOnEdit({ payload }) {
    try {
      const userData = yield call(backend.user.updateUser, payload)
      yield put(actions.updateUserSuccess({ userData }))
    } catch (e) {
      yield put(actions.updateUserFailure(e))
    }
  }

export function* updateUserSaga() {
    yield all([
        takeEvery(actions.UPDATE_USER_REQUEST, updateUserOnEdit)
    ])
}