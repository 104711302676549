import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { getProject } from '../../reducers/project'
import { fetchUserProjectsRequest } from '../../actions/project'

const ProjectTitle = ({ project, projectId, fetchUserProjectsRequest }) => {
  useEffect(() => {
    fetchUserProjectsRequest()
  }, [projectId, fetchUserProjectsRequest])

  return (
    <Link to={{ pathname: `/project/${projectId}/shot/all` }}>
      {project && (project.shortTitle ? project.shortTitle : project.title)}
    </Link>
  )
}

const mapStateToProps = (state, ownProps) => {
  let { projectId } = ownProps.match.params
  return {
    project: getProject(state, projectId),
    projectId
  }
}

const ProjectTitleConnected = connect(
  mapStateToProps,
  { fetchUserProjectsRequest }
)(ProjectTitle)

export { ProjectTitle, ProjectTitleConnected }
