import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Route, Switch, withRouter } from 'react-router'
import styled from '@emotion/styled'
import Button from '../Button/Button'
import { setEditUserSelection } from '../../actions/editUser'
import { setTechSpecsRequest } from '../../actions/techSpecs'
import { submit } from 'redux-form'
import { reset } from 'redux-form';
import { getUserManagerSelection } from '../../reducers/selection'

const ButtonGroupContainerTop = styled.div(() => ({
  width: '100%',
  padding: '1rem',
}))
const ButtonGroupContainerBottom = styled.div(() => ({
  width: '100%',
  padding: '0rem 1rem 1rem 1rem',
}))

const BottomSidebarSection = ({ match, setEditUserSelection, submit, reset,
  setTechSpecsRequest, userIdSelection }) => {

  let [buttonsVisible, setButtonsVisible] = useState(false)
  let clickHandler = () => {
    setButtonsVisible(!buttonsVisible)
  }

  let [buttonNotVisible, setButtonNotVisible] = useState(true)
  let clickHandler2 = () => {
    setButtonNotVisible(!buttonNotVisible)
  }

  return (
    <Switch>
      <Route
        path="/project/all"
        component={() => {
          return (
            <ButtonGroupContainerTop>
              <Link to="/project/create">
                <Button data-type="large">Create Project</Button>
              </Link>
            </ButtonGroupContainerTop>
          )
        }}
      />

      <Route
        path={[`/project/:projectId/techspecs/:extraInfo`, `/project/:projectId/techspecs`]}
        component={() => {
          let editingMode = false;
          if (match.params.extraInfo === 'edit') editingMode = true;

          return (
            <div>
              {editingMode == false && (
                <div>
                  <ButtonGroupContainerTop>
                    <Link to={`/project/${match.params.projectId}/techspecs/edit`}>
                      <Button data-type="large" onClick={() => {
                        return setTechSpecsRequest(1)
                      }}>Create Tech Specs</Button>
                    </Link>
                  </ButtonGroupContainerTop>
                  <ButtonGroupContainerBottom>
                    <Link to={`/project/${match.params.projectId}/techspecs/edit`}>
                      <Button data-type="large">Edit Tech Specs</Button>
                    </Link>
                  </ButtonGroupContainerBottom>
                </div>
              )}

              {editingMode == true && (
                <React.Fragment>
                  <ButtonGroupContainerTop style={{paddingBottom: '0rem'}}>
                    <Link to={`/project/${match.params.projectId}/techspecs/edit`}>
                      <Button data-type="large" onClick={() => {
                        return setTechSpecsRequest(1)
                      }}>Create Tech Specs</Button>
                    </Link>
                  </ButtonGroupContainerTop>

                  <ButtonGroupContainerTop>
                    <Link to={`/project/${match.params.projectId}/techspecs/`}>
                      <Button data-type="large" type="button"
                        onClick={() => {
                          return submit('techSpecsForm'), setTechSpecsRequest(0)
                        }}>Submit Changes</Button>
                    </Link>

                  </ButtonGroupContainerTop>
                  <ButtonGroupContainerBottom>
                    <Link to={`/project/${match.params.projectId}/techspecs/`}>
                      <Button data-type="large" onClick={() => {
                        return setTechSpecsRequest(0)
                      }}>Discard Changes</Button>
                    </Link>

                  </ButtonGroupContainerBottom>
                </React.Fragment>
              )}
            </div>
          )
        }}
      />

      <Route
        path={`/project/:projectId/shot/all`}
        component={() => {
          return (
            <div>
              <ButtonGroupContainerTop>
                <Link to={`/project/${match.params.projectId}/shot/create`}>
                  <Button data-type="large">Create Shot</Button>
                </Link>
              </ButtonGroupContainerTop>

              <ButtonGroupContainerBottom>
                <Link to={`/project/:projectId`}>
                  <Button data-type="large">Manage Project</Button>
                </Link>
              </ButtonGroupContainerBottom>
            </div>
          )
        }}
      />

      <Route
        path={[`/projects/manager/:projectId/:extraInfo`, `/projects/manager/:projectId`, `/projects/manager`]}
        component={() => {
          let editingMode = false;
          if (match.params.extraInfo === 'edit') editingMode = true;
          return (
            <div>
              {editingMode == false && match.params.projectId !== undefined && (
                <React.Fragment>
                  <ButtonGroupContainerTop>
                    <Link to="/project/create">
                      <Button data-type="large">Create Project</Button>
                    </Link>
                  </ButtonGroupContainerTop>

                  <ButtonGroupContainerBottom>
                    <Link to={`/projects/manager/${match.params.projectId}/edit`}>
                      <Button data-type="large">Edit Project</Button>
                    </Link>

                  </ButtonGroupContainerBottom>
                </React.Fragment>
              )}

              {editingMode == true && (
                <React.Fragment>
                  <ButtonGroupContainerTop>
                    <Link to={`/projects/manager/${match.params.projectId}`}>
                      <Button data-type="large" type="button"
                        onClick={() => {
                          return submit('projectManagerForm')
                        }}>Submit Changes</Button>
                    </Link>

                  </ButtonGroupContainerTop>
                  <ButtonGroupContainerBottom>
                    <Link to={`/projects/manager/${match.params.projectId}`}>
                      <Button data-type="large">Discard Changes</Button>
                    </Link>

                  </ButtonGroupContainerBottom>
                </React.Fragment>
              )}
            </div>
          )
        }}
      />

      <Route
        path={[`/sites/:extraInfo`, `/sites`,]}
        component={() => {
          let editingMode = false;
          if (match.params.extraInfo === 'edit') editingMode = true;
          return (
            <div>
              {editingMode == false && (
                <ButtonGroupContainerTop>
                  <Link to={`/sites/edit`}>
                    <Button data-type="large">Edit Site</Button>
                  </Link>
                </ButtonGroupContainerTop>
              )}

              {editingMode == true && (
                <React.Fragment>
                  <ButtonGroupContainerTop>
                    <Link to={`/sites`}>
                      <Button data-type="large" type="button"
                        onClick={() => {
                          return submit('GlobalTagsForm'), submit('GlobalStatusesForm'), submit('DropdownSiteDetailsForm')
                        }}>Submit Changes</Button>
                    </Link>

                  </ButtonGroupContainerTop>
                  <ButtonGroupContainerBottom>
                    <Link to={`/sites`}>
                      <Button data-type="large">Discard Changes</Button>
                    </Link>
                  </ButtonGroupContainerBottom>
                </React.Fragment>
              )}
            </div>
          )
        }}
      />

      <Route
        path={[`/users/:userId`, `/users`]}
        component={() => {
          return (
            <div>
              {(buttonNotVisible) && userIdSelection !== undefined && (
                <div>
                  <ButtonGroupContainerTop>
                    <Link to="/user/create">
                      <Button data-type="large">Create User</Button>
                    </Link>
                  </ButtonGroupContainerTop>

                  <ButtonGroupContainerBottom>
                    <Button data-type="large" onClick={() => {
                      clickHandler()
                      clickHandler2()
                      return setEditUserSelection(true)
                    }
                    }>Edit User</Button>
                  </ButtonGroupContainerBottom>
                </div>
              )}

              {(buttonsVisible) && (
                <div style={{ paddingTop: '1rem' }}>
                  <ButtonGroupContainerBottom paddingBottom='1rem'  >
                    <Button type="button"
                      onClick={() => {
                        clickHandler()
                        clickHandler2()
                        return submit('assignedProjectsForm'), submit('userProfileDataForm'), setEditUserSelection(false)
                      }}
                    >Save Changes</Button>
                  </ButtonGroupContainerBottom>

                  <ButtonGroupContainerBottom>
                    <Button data-type="large" onClick={() => {
                      clickHandler()
                      clickHandler2()
                      return setEditUserSelection(false), reset('assignedProjectsForm'), reset('userProfileDataForm')
                    }
                    }>Discard Changes</Button>
                  </ButtonGroupContainerBottom>
                </div>
              )}
            </div>
          )
        }}
      />
    </Switch>
  )
}

const mapStateToProps = (state, ownProps) => {
  return { userIdSelection: getUserManagerSelection(state) }
}

const BottomSidebarSectionConnected = withRouter(
  connect(mapStateToProps, { setEditUserSelection, submit, reset, setTechSpecsRequest })(BottomSidebarSection)
)

export { BottomSidebarSection, BottomSidebarSectionConnected }
