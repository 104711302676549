/* 

Constants and action creators for Flux standard actions (FSA's)

The structure should generally be like this:

{
  type: ACTION_TYPE,    // referenced action constant
  payload: ANY          // as simple as possible, payload should be error if error = true
  error: BOOLEAN        // speaks for itself
  meta: ANY             // extra stuff, like authentication tokens
}

These actions decribe what can happen in the larger application, only actions are transmitted
between the parts of the application and eventually (may) cause non-local state changes and side effects. 

The constants are exported seperately because these must be matched exactly in other parts of
the application. Importing these makes typos fail loudly, instead of just ignoring the action. 

*/

export const CHECK_AUTENTICATION = 'CHECK_AUTENTICATION'

export const LOGIN_REQUEST = 'LOGIN_REQUESTED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGIN_SUCCESS_ID = 'LOGIN_SUCCESS_ID'

export const LOGOUT = 'LOGOUT'

export const PASSWORD_RETRIEVAL_REQUEST = 'PASSWORD_RETRIEVAL_REQUEST'
export const PASSWORD_RETRIEVAL_SUCCESS = 'PASSWORD_RETRIEVAL_SUCCESS'
export const PASSWORD_RETRIEVAL_FAILURE = 'PASSWORD_RETRIEVAL_FAILURE'

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'

export const FETCH_USER_TO_VALIDATE_REQUEST = 'FETCH_USER_TO_VALIDATE_REQUEST'
export const FETCH_USER_TO_VALIDATE_SUCCESS = 'FETCH_USER_TO_VALIDATE_SUCCESS'
export const FETCH_USER_TO_VALIDATE_FAILURE = 'FETCH_USER_TO_VALIDATE_FAILURE'

export const VALIDATE_USER_REQUEST = 'VALIDATE_USER_REQUEST'
export const VALIDATE_USER_SUCCESS = 'VALIDATE_USER_SUCCESS'
export const VALIDATE_USER_FAILURE = 'VALIDATE_USER_FAILURE'

export const FETCH_SELECTED_USER_REQUEST = 'FETCH_SELECTED_USER_REQUEST'
export const FETCH_SELECTED_USER_SUCCESS = 'FETCH_SELECTED_USER_SUCCESS'
export const FETCH_SELECTED_USER_FAILURE = 'FETCH_SELECTED_USER_FAILURE'

export const UPDATE_PROFILE_PICTURE_REQUEST = 'UPDATE_PROFILE_PICTURE_REQUEST'
export const UPDATE_PROFILE_PICTURE_SUCCESS = 'UPDATE_PROFILE_PICTURE_SUCCESS'
export const UPDATE_PROFILE_PICTURE_FAILURE = 'UPDATE_PROFILE_PICTURE_FAILURE'

export const checkAuthentication = () => {
  return {
    type: CHECK_AUTENTICATION
  }
}

export const loginRequest = ({ email, password }) => {
  return {
    type: LOGIN_REQUEST,
    payload: { email, password }
  }
}

export const loginRequestTest = () => {
  return loginRequest({
    email: 'rjkorteschiel@gmail.com',
    email: 'frank@planetx.nl',
    // password: 'test12345'
    password: 'test1234'

  })
}

export const loginSuccess = (userId) => {
  return {
    type: LOGIN_SUCCESS,
    payload: userId
  }
}


export const loginFailure = e => {
  return {
    type: LOGIN_FAILURE,
    payload: e,
    error: true
  }
}

export const fetchUsersRequest = () => {
  return {
    type: FETCH_USERS_REQUEST
  }
}

export const fetchUsersSuccess = ({ entities, result }) => {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: { entities, result }
  }
}

export const fetchUsersFailure = e => {
  return {
    type: FETCH_USERS_FAILURE,
    payload: e,
    error: true
  }
}

export const fetchSelectedUserRequest = personId => {
  return {
    type: FETCH_SELECTED_USER_REQUEST,
    payload: {personId}
  }
}

export const fetchSelectedUserSuccess = person => {
  return {
    type: FETCH_SELECTED_USER_SUCCESS,
    payload: {person}
  }
}

export const fetchSelectedUserFailure = e => {
  return {
    type: FETCH_SELECTED_USER_FAILURE,
    payload: e,
    error: true
  }
}

export const passwordRetrievalRequest = ({ email }) => {
  return {
    type: PASSWORD_RETRIEVAL_REQUEST,
    payload: { email }
  }
}

export const passwordRetrievalSuccess = () => {
  return {
    type: PASSWORD_RETRIEVAL_SUCCESS
  }
}

export const passwordRetrievalFailure = e => {
  return {
    type: PASSWORD_RETRIEVAL_FAILURE,
    payload: e,
    error: true
  }
}

export const fetchUserToValidateRequest = validationToken => {
  return {
    type: FETCH_USER_TO_VALIDATE_REQUEST,
    payload: { validationToken }
  }
}

export const fetchUserToValidateSuccess = user => {
  return {
    type: FETCH_USER_TO_VALIDATE_SUCCESS,
    payload: user
  }
}

export const fetchUserToValidateFailure = e => {
  return {
    type: FETCH_USER_TO_VALIDATE_FAILURE,
    payload: e,
    error: true
  }
}

export const validateUserRequest = ({ validationToken }, user) => {
  return {
    type: VALIDATE_USER_REQUEST,
    payload: { validationToken, user }
  }
}

export const validateUserSuccess = () => {
  return {
    type: VALIDATE_USER_SUCCESS
  }
}

export const validateUserFailure = e => {
  return {
    type: VALIDATE_USER_FAILURE,
    payload: e,
    error: true
  }
}

export const logout = e => {
  return {
    type: LOGOUT
  }
}

export const updateProfilePictureRequest = ({ userId, files }) => {
  return {
    type: UPDATE_PROFILE_PICTURE_REQUEST,
    payload: { userId, files }
  }
}

export const updateProfilePictureSuccess = ({ picture }) => {
  return {
    type: UPDATE_PROFILE_PICTURE_SUCCESS,
    payload: { ...picture }
  }
}

export const updateProfilePictureFailure = e => {
  return {
    type: UPDATE_PROFILE_PICTURE_FAILURE,
    payload: e,
    error: true
  }
}
