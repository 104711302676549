import styled from '@emotion/styled'

import { colors, units, type } from '../../../styles'

export const SelectContainer = styled.div({
    position: 'relative',
    width: '100%',
    height: '2.25rem',
    background: colors.grey,
    border: `0.0625rem solid ${colors.grey}`,
    borderRadius: units.borderRadius,
    outline: '0',
    ':focus': {
        ...type.formActive
    },
})

export const Wrap = styled.div({
    marginBottom: '0.5rem',
})

export const InnerContainer = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '0rem 0rem 0rem 1rem'
})

export const SelectedItem = styled.label({
    ...type.headerNavActive,
    fontFamily: 'Heebo',
})

export const IconWrap = styled.div({
    height: '2rem',
    width: '3rem',
    position: 'relative',
})

export const DropdownContainer = styled.div({
    position: 'relative',
})

