import React, { useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import styled from '@emotion/styled'

import { Select } from '../../Forms/components/Select'
import { getStatus, getStatuses } from '../../../reducers/status'
import { getProjectShotSelection } from '../../../reducers/selection'
import { setProjectShotSelection } from '../../../actions/ui'
import { type, colors } from '../../../styles'
import { fetchFullProjectRequest } from '../../../actions/project'
import { getSingleProject } from '../../../reducers/project'

const Label = styled.label({
  ...type.labelBig,
  color: colors.lightGrey
})

const Container = styled.div({
  marginBottom: '1.5rem'
})

const Projects = ({
  setProjectShotSelection,
  statuses = [],
  selectedStatus,
  projectId,
  match,
  singleProject,
  fetchFullProjectRequest
}) => {

  const [project, setProjectId] = useState(match.params.project)

  useEffect(() => {
    setProjectId(match.params.project)
    if (project !== undefined) {
        fetchFullProjectRequest(project)
    }
}, [project, fetchFullProjectRequest, match.params.project, singleProject==undefined])

  return (
    <>
      <Container>
        <Label>Status</Label>
        <Select
          options={[{ statusId: null, title: 'No selection' }, ...statuses].map(
            status => {
              return {
                type: 'action',
                action: () => {
                  return setProjectShotSelection(projectId, {
                    statusId: status.statusId
                  })
                },
                name: status.title
              }
            }
          )}
          selectedOption={selectedStatus}
        ></Select>
      </Container>

      <Container>
        <Label>Tags</Label>
        <Select
          options={[{ statusId: null, title: 'No selection' }, ...statuses].map(
            status => {
              return {
                type: 'action',
                action: () => {
                  return setProjectShotSelection(projectId, {
                    statusId: status.statusId
                  })
                },
                name: status.title
              }
            }
          )}
          selectedOption={selectedStatus}
        ></Select>
      </Container>

    </>
  )
}

const mapStateToProps = (state, { match }) => {
  let projectId = match.params.projectId
  let statuses = getStatuses(state)

  let selectedStatusId = getProjectShotSelection(state, {
    projectId,
    selection: 'statusId'
  })

  let selectedStatus = getStatus(state, selectedStatusId)

  let singleProject = getSingleProject(state, match.params.projectId)

  return { projectId, statuses, selectedStatus, singleProject }
}

const ProjectsConnected = withRouter(
  connect(
    mapStateToProps,
    { setProjectShotSelection, fetchFullProjectRequest }
  )(Projects)
)

export { ProjectsConnected }
