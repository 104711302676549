import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { units, colors, type } from '../../styles'

import { fetchTagsRequest } from '../../actions/tag'
import { updateShotRequest } from '../../actions/shot'
import { getTags } from '../../reducers/tag'
import { getShot } from '../../reducers/shot'

const DropdownContainer = styled.div({
  position: 'absolute',
  top: '100%',
  width: '13.375rem',
  borderRadius: units.borderRadius,
  background: colors.darkerGrey,
  zIndex: 10
})

const TagsList = styled.ul({
  display: 'flex',
  flexDirection: 'column'
})

const ListItem = styled.li({
  borderRadius: units.borderRadius,
  padding: '0.5rem',
  ...type.labelBig,
  color: type.colors.day,
  background: colors.darkerGrey,
  ':hover': {
    filter: 'brightness(2)',
    cursor: 'pointer'
  }
})

const TagDropdown = ({ shot, tags, fetchTagsRequest, updateShotRequest }) => {
  useEffect(() => {
    fetchTagsRequest()
  }, [fetchTagsRequest])

  let handleAddTag = tag => {
    updateShotRequest({
      shotId: shot.shotId,
      shotTags: [
        ...shot.tags.map(shotTag => ({ tagId: shotTag })),
        { tagId: tag.tagId }
      ]
    })
  }

  return (
    <DropdownContainer>
      <TagsList>
        {tags.map(tag => {
          return (
            <ListItem
              key={tag.tagId}
              onClick={() => {
                handleAddTag(tag)
              }}
            >
              {tag.title}
            </ListItem>
          )
        })}
      </TagsList>
    </DropdownContainer>
  )
}

const mapStateToProps = (state, { shotId }) => {
  let tags = getTags(state)
  let shot = getShot(state, shotId)
  return {
    tags,
    shot
  }
}

const TagDropdownConnected = connect(
  mapStateToProps,
  { fetchTagsRequest, updateShotRequest }
)(TagDropdown)

export { TagDropdown, TagDropdownConnected }
