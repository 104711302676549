import styled from '@emotion/styled'

import { colors, units, type } from '../../../styles'

const StyledErrorMessage = styled.div({
  padding: `${units.half} ${units.base}`,
  background: colors.red,
  ...type.form,
  color: type.colors.day
})

export { StyledErrorMessage }
