import uuidv1 from 'uuid/v1'

export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'

export const createNotification = (message, { type } = { type: 'default' }) => {
  return {
    type: CREATE_NOTIFICATION,
    payload: {
      notificationID: uuidv1(),
      message,
      type,
      timestamp: Date.now
    }
  }
}

export const deleteNotification = notificationID => {
  return {
    type: DELETE_NOTIFICATION,
    payload: {
      notificationID
    }
  }
}
