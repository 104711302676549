import React from 'react'
import styled from '@emotion/styled'

import { MainNav } from './MainNav'
import { SideNav } from './SideNav'
import { colors } from '../../styles'

const NavContainer = styled.header({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  width: '100%',
  height: '3.5rem',

  background: colors.night,
  borderBottom: `0.0625rem solid ${colors.grey}`
})

const Nav = () => {
  return (
    <NavContainer>
      <SideNav />
      <MainNav />
    </NavContainer>
  )
}

export { Nav }
