import React, { useState } from 'react'
import { SelectWithoutTitle as Select } from '../../Forms/components/SelectWithoutTitle'
import { SelectAssignUsers} from '../../Forms/components/SelectAssignUsers'
import { Field} from 'redux-form'
import {
  DetailsContainer, ListContainer, Label, Text, Icon,
  Wrap, ArrowWrap, StarIcon, ArrowIcon, ThirdSection,
  FourthSection, AssignProject, FifthSection, FirstSection, SecondSection
} from '../AssignedProjectsStyles'
import profilePicture from '../../Project/birdman.png'
import star from './../star.svg'
import signleArrow from '../../Button/down.svg'
import { colors} from '../../../styles'

import { fromPairs } from 'lodash'

export const ButtonChoose = ({
  input,
  meta: { touched, error, warning },
}) => (

    <div >
      <div className='black-button-field' >
        <button type='button' className={input.value ? 'grey-button' : 'black-button'} onClick={() => input.onChange(true)}>Yes</button>
        <button type='button' className={input.value ? 'black-button' : 'grey-button'} onClick={() => input.onChange(false)}>No</button>
      </div>
    </div >
  )

export const ButtonAssign = ({
  input,
  meta: { touched, error, warning },
}) => {
  const [checked, setChecked] = React.useState(input.value);

  return (
  <div >
    <div  >
      <input type='checkbox' style={{zoom:1.5}} checked={checked} value={checked} onClick={() => input.onChange(!checked)} onChange={() => setChecked(!checked)}></input>
    </div>
  </div >)
}


export const Id = ({
  input,
  meta: { touched, error, warning },
}) => (

    <div value={input.value} onSubmit={() => input.onSubmit(input.value)}>
    </div >
  )

export const SelectThird = (props) => {
  const [selected, setSelected] = useState('')

  if (props && props.options) {

    const { input } = props

    return (
      <div >
        <Select
          key={props.input}
          className='black-select'
          options={[...props.options].map(
            option => {
              return {
                type: 'action',
                action: () => {

                  props.input.onChange(option.value)
                  setSelected(option.label)
                },
                name: option.label
              }
            }
          )}

          input={input && input}
          selectedOption={selected}

        ></Select>
      </div>
    )
  }
  return <div></div>
}

export const SelectAssign = (props) => {
  const [selected, setSelected] = useState('')

  if (props && props.options) {

    console.log("PROPS OPTIONS", props.options)
    const { input } = props

    return (
      <div >
        <SelectAssignUsers
          key={props.input}
          className='black-select'
          options={[...props.options].map(
            option => {
              return {
                type: 'action',
                action: () => {

                  props.input.onChange(option.value)
                  setSelected(option.label)
                },
                name: option.label
              }
            }
          )}

          input={input && input}
          selectedOption={selected}

        ></SelectAssignUsers>
      </div>
    )
  }
  return <div></div>
}

export const renderMembers = ({ fields }) => {
  if (fields !== undefined) {
    return (<div>

      {fields.map((memberProject, index) => {

        return (

          <DetailsContainer key={index}>
            <ListContainer style={{height: 'auto'}}>
              <FirstSection >
                <Icon src={fields.get(index).thumbnailURL?fields.get(index).thumbnailURL:profilePicture}></Icon>
              </FirstSection >
              <SecondSection style={{height: 'auto'}}>
                <Label style={{height: 'auto'}}>{fields.get(index).title}</Label>
                <Text>{fields.get(index).director}</Text>
              </SecondSection>

              <ThirdSection  >
                <Field
                  name={`${memberProject}.permissions`}
                  component={SelectAssign}
                  options={[
                    { label: 'Owner', value: 'Owner' },
                    { label: 'Supervisor', value: 'Supervisor' },
                    { label: 'Artist', value: 'Artist' },
                    { label: 'External', value: 'External' }]}
                  
                  // style={{position: 'relative',
                  // height: '20%',
                  // // width: '80%',
                  // display: 'flex',
                  // flexWrap: 'wrap',
                  // background: colors.night,
                  // borderBottom: '0.0625rem solid #4a4b4c',}}
                >
                </Field>
              </ThirdSection>
              <FourthSection>

                <Field name={`${memberProject}.assigned`} component={ButtonAssign} />

                <AssignProject>Assign Project</AssignProject>
              </FourthSection>

              <FifthSection>
                <Wrap><StarIcon src={star}></StarIcon></Wrap>
                <ArrowWrap><ArrowIcon src={signleArrow}></ArrowIcon></ArrowWrap>
              </FifthSection>
            </ListContainer>
          </DetailsContainer>
        )
      })}

    </div>)
  }
  else return <div>""</div>
}
