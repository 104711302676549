import { schemas } from './schemas'
import { request } from './index'
import { normalize } from 'normalizr'

export const createShot = async shot => {
  let res = await request('/shots', {
    method: 'post',
    data: shot
  })
  return res
}

export const fetchShotsByProjectId = async ({ projectId }) => {
  let res = await request(`/projects/${projectId}/shots`)
  return normalize(res, schemas.SHOTS)
}

export const fetchShot = async ({ shotId }) => {
  let res = await request(`/shots/${shotId}`)
  return normalize(res, schemas.SHOT)
}

export const fetchListOfShots = async ({ shotIds }) => {
  let res = await request(`/shots/multiple/${shotIds.valueOf()}`, {
    method: 'get',
    data: shotIds
  })
  return normalize(res.results, schemas.SHOTS)
}

export const updateShot = async shot => {
  let res = await request(`/shots/${shot.shotId}`, {
    method: 'put',
    data: { ...shot }
  })
  return normalize(res, schemas.SHOT)
}

export const updateShotThumb = async ({ shotId, files }) => {
  let formData = new FormData()
  formData.append('files[0]', files[0])
  let res = await request(`/shots/${shotId}/upload-thumb`, {
    method: 'post',
    data: formData
  })

  return normalize(res, schemas.SHOT)
}
