import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import { type } from '../../styles'

const Container = styled.div({
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center'
})

const blink = keyframes({
  '0%': {
    opacity: '.2',
    transform: 'scale(1)'
  },
  '20%': {
    opacity: '1',
    transform: 'scale(4)'
  },
  '100%': {
    opacity: '.2',
    transform: 'scale(1)'
  }
})

const LoadingIcon = styled.h3({
  ...type.subHeaderBold,
  span: {
    animation: `${blink} 1.4s infinite both`
  },
  'span:nth-of-type(2)': {
    animationDelay: '.2s'
  },
  'span::nth-of-type(3)': {
    animationDelay: '.4s'
  }
})

const Spinner = () => (
  <Container>
    <LoadingIcon>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </LoadingIcon>
  </Container>
)

export { Spinner }
