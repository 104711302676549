export const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST'
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS'
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE'
export const CREATE_TAG_REQUEST = 'CREATE_TAG_REQUEST'
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS'
export const CREATE_TAG_FAILURE = 'CREATE_TAG_FAILURE'
export const UPDATE_TAG_REQUEST = 'UPDATE_TAG_REQUEST'
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS'
export const UPDATE_TAG_FAILURE = 'UPDATE_TAG_FAILURE'
export const DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST'
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS'
export const DELETE_TAG_FAILURE = 'DELETE_TAG_FAILURE'

export const FETCH_PROJECT_TAGS_REQUEST='FETCH_PROJECT_TAGS_REQUEST'
export const FETCH_PROJECT_TAGS_SUCCESS = 'FETCH_PROJECT_TAGS_SUCCESS'
export const FETCH_PROJECT_TAGS_FAILURE = 'FETCH_PROJECT_TAGS_FAILURE'

export const CREATE_GLOBAL_TAG_REQUEST = 'CREATE_GLOBAL_TAG_REQUEST'
export const CREATE_GLOBAL_TAG_SUCCESS = 'CREATE_GLOBAL_TAG_SUCCESS'
export const CREATE_GLOBAL_TAG_FAILURE = 'CREATE_GLOBAL_TAG_FAILURE'
export const UPDATE_GLOBAL_TAG_REQUEST = 'UPDATE_GLOBAL_TAG_REQUEST'
export const UPDATE_GLOBAL_TAG_SUCCESS = 'UPDATE_GLOBAL_TAG_SUCCESS'
export const UPDATE_GLOBAL_TAG_FAILURE = 'UPDATE_GLOBAL_TAG_FAILURE'
export const DELETE_GLOBAL_TAG_REQUEST = 'DELETE_GLOBAL_TAG_REQUEST'
export const DELETE_GLOBAL_TAG_SUCCESS = 'DELETE_GLOBAL_TAG_SUCCESS'
export const DELETE_GLOBAL_TAG_FAILURE = 'DELETE_GLOBAL_TAG_FAILURE'

export const fetchProjectTagsRequest = (projectId) => {
  return {
    type: FETCH_PROJECT_TAGS_REQUEST,
    payload: projectId
  }
}

export const fetchProjectTagsSuccess = ({ entities, result }) => {
  return {
    type: FETCH_PROJECT_TAGS_SUCCESS,
    payload: { entities, result }
  }
}

export const fetchProjectTagsFailure = e => {
  return {
    type: FETCH_PROJECT_TAGS_FAILURE,
    payload: e,
    error: true
  }
}

export const fetchTagsRequest = () => {
  return {
    type: FETCH_TAGS_REQUEST
  }
}

export const fetchTagsSuccess = ({ entities, result }) => {
  return {
    type: FETCH_TAGS_SUCCESS,
    payload: { entities, result }
  }
}

export const fetchTagsFailure = e => {
  return {
    type: FETCH_TAGS_FAILURE,
    payload: e,
    error: true
  }
}

export const createTagRequest = tag => {
  return {
    type: CREATE_TAG_REQUEST,
    payload: tag
  }
}

export const createTagSuccess = () => {
  return {
    type: CREATE_TAG_SUCCESS
  }
}

export const createTagFailure = e => {
  return {
    type: CREATE_TAG_FAILURE,
    payload: e,
    error: true
  }
}

export const updateTagRequest = tag => {
  return {
    type: UPDATE_TAG_REQUEST,
    payload: tag
  }
}

export const updateTagSuccess = () => {
  return {
    type: UPDATE_TAG_SUCCESS
  }
}

export const updateTagFailure = e => {
  return {
    type: UPDATE_TAG_FAILURE,
    payload: e,
    error: true
  }
}

export const deleteTagRequest = tag => {
  return {
    type: DELETE_TAG_REQUEST,
    payload: tag
  }
}

export const deleteTagSuccess = tag => {
  return {
    type: DELETE_TAG_SUCCESS,
    payload: tag
  }
}

export const deleteTagFailure = e => {
  return {
    type: DELETE_TAG_FAILURE,
    payload: e,
    error: true
  }
}

export const createGlobalTagRequest = tag => {
  return {
    type: CREATE_GLOBAL_TAG_REQUEST,
    payload: tag
  }
}

export const createGlobalTagSuccess = () => {
  return {
    type: CREATE_GLOBAL_TAG_SUCCESS
  }
}

export const createGlobalTagFailure = e => {
  return {
    type: CREATE_GLOBAL_TAG_FAILURE,
    payload: e,
    error: true
  }
}

export const updateGlobalTagRequest = tag => {
  return {
    type: UPDATE_GLOBAL_TAG_REQUEST,
    payload: tag
  }
}

export const updateGlobalTagSuccess = () => {
  return {
    type: UPDATE_GLOBAL_TAG_SUCCESS
  }
}

export const updateGlobalTagFailure = e => {
  return {
    type: UPDATE_GLOBAL_TAG_FAILURE,
    payload: e,
    error: true
  }
}

export const deleteGlobalTagRequest = tag => {
  return {
    type: DELETE_GLOBAL_TAG_REQUEST,
    payload: tag
  }
}

export const deleteGlobalTagSuccess = tag => {
  return {
    type: DELETE_GLOBAL_TAG_SUCCESS,
    payload: tag
  }
}

export const deleteGlobalTagFailure = e => {
  return {
    type: DELETE_GLOBAL_TAG_FAILURE,
    payload: e,
    error: true
  }
}