import { call, all, put, takeEvery } from 'redux-saga/effects'

import * as actions from '../actions/tag'
import * as backend from '../api/backend/index'

function* fetchTags() {
  
  try {
    const tags = yield call(backend.tag.fetchTags)
    yield put(actions.fetchTagsSuccess(tags))
  } catch (e) {
    yield put(actions.fetchTagsFailure(e))
  }
}

function* createTag({ payload }) {
  try {
    const tag = yield call(backend.tag.createTag, payload)
    yield put(actions.createTagSuccess(tag))
  } catch (e) {
    yield put(actions.createTagFailure(e))
  }
}

function* updateTag({ payload }) {
  try {
    const tag = yield call(backend.tag.updateTag, payload)
    yield put(actions.updateTagSuccess(tag))
  } catch (e) {
    yield put(actions.updateTagFailure(e))
  }
}

function* deleteTag({ payload }) {
  try {
    const tag = yield call(backend.tag.deleteTag, payload)
    yield put(actions.deleteTagSuccess(tag))
  } catch (e) {
    yield put(actions.deleteTagFailure(e))
  }
}

function* createGlobalTag({ payload }) {
  try {
    const tag = yield call(backend.tag.createGlobalTag, payload)
    yield put(actions.createGlobalTagSuccess(tag))
  } catch (e) {
    yield put(actions.createGlobalTagFailure(e))
  }
}

function* updateGlobalTag({ payload }) {
  try {
    const tag = yield call(backend.tag.updateGlobalTag, payload)
    yield put(actions.updateGlobalTagSuccess(tag))
  } catch (e) {
    yield put(actions.updateGlobalTagFailure(e))
  }
}

function* deleteGlobalTag({ payload }) {
  try {
    const tag = yield call(backend.tag.deleteGlobalTag, payload)
    yield put(actions.deleteGlobalTagSuccess(tag))
  } catch (e) {
    yield put(actions.deleteGlobalTagFailure(e))
  }
}

export function* tagRootSaga() {
  yield all([
    yield takeEvery(actions.FETCH_TAGS_REQUEST, fetchTags),
    yield takeEvery(actions.CREATE_TAG_REQUEST, createTag),
    yield takeEvery(actions.UPDATE_TAG_REQUEST, updateTag),
    yield takeEvery(actions.DELETE_TAG_REQUEST, deleteTag),
    
    yield takeEvery(actions.CREATE_GLOBAL_TAG_REQUEST, createGlobalTag),
    yield takeEvery(actions.UPDATE_GLOBAL_TAG_REQUEST, updateGlobalTag),
    yield takeEvery(actions.DELETE_GLOBAL_TAG_REQUEST, deleteGlobalTag)
  ])
}
