import React from 'react'
import { Route, Switch } from 'react-router'
import styled from '@emotion/styled'

import { ProjectsConnected } from './MiddleSidebarProjects'
import { colors } from '../../../styles'

const MiddleSidebarSectionContainer = styled.div({
  flex: '1 1 100%',
  width: '100%',
  borderBottom: `0.0625rem solid ${colors.grey}`,
  borderTop: `0.0625rem solid ${colors.grey}`
})

const ProjectsConnectedContainer = styled.div({
  padding: '1rem'
})

export const ButtonChoose = ({
  input,

}) => (

    <div >
      <div className='black-button-field-projects' >
        {/* Just put the input here -input.value */}
        <button type='button' className={input ? 'grey-button' : 'black-button'} onClick={() => input.onChange(true)}>Yes</button>
        <button type='button' className={input ? 'black-button' : 'grey-button'} onClick={() => input.onChange(false)}>No</button>
      </div>
    </div >
  )

const MiddleSidebarSection = ({ match }) => {
  return (
    <MiddleSidebarSectionContainer position='relative'>
      <Switch>
        <Route path="/project/all" component={() => {
          return (
            <div>
              <div style={{ color: 'white', padding: '1rem 1rem 0rem 1rem' }}>Show archived projects?</div>

              <ProjectsConnectedContainer>
                <ButtonChoose />
              </ProjectsConnectedContainer>
            </div>

          )
        }} />
        <Route
          path="/project/:projectId/shot/:shotId"
          component={() => {
            return (
              <ProjectsConnectedContainer>
                <ProjectsConnected />
              </ProjectsConnectedContainer>
            )
          }}
        />

      </Switch>
    </MiddleSidebarSectionContainer>
  )
}

export { MiddleSidebarSection }
