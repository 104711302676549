import { combineReducers } from 'redux'
import { SET_PROJECT_SHOT_SELECTION } from '../actions/ui'
import { SET_USER_MANAGER_SELECTION} from '../actions/ui'

const assetSelections = (state = {}, action) => {
  return state
}

const shotSelections = (state = {}, action) => {
  switch (action.type) {
    case SET_PROJECT_SHOT_SELECTION:
      return {
        ...state,
        ...action.payload.selection
      }
    default:
      return state
  }
}

const userManagerSelection = (state = {}, action) => {

  switch (action.type) {
    case SET_USER_MANAGER_SELECTION:
      return action.payload
    default:
      return state
  }
}

const projectSelection = (state = {}, action) => {
  return {
    ...state,
    shots: shotSelections(state.shots, action),
    assets: assetSelections(state.assets, action)
  }
}

const projectSelections = (state = {}, action) => {
  switch (action.type) {
    case SET_PROJECT_SHOT_SELECTION:
      return {
        ...state,
        [action.payload.projectId]: projectSelection(
          state[action.payload.projectId],
          action
        )
      }
    default:
      return state
  }
}

// not public -> needs know to much about state
const getSelection = (state, selectionPath = []) => {
  return selectionPath.reduce((accumulator, currentValue) => {
    return accumulator && accumulator[currentValue]
  }, state.ui.selections)
}

// public -> encodes the state path
export const getProjectShotSelection = (state, { projectId, selection }) => {
  return getSelection(state, ['projects', projectId, 'shots', selection])
}

export const getUserManagerSelection = (state) => {
  return state.ui.selections.userManager
}

export const selections = combineReducers({
  projects: projectSelections,
  userManager: userManagerSelection
})
