import { FETCH_SITES_SUCCESS, UPDATE_SITES_SUCCESS } from '../actions/sites'
import { combineReducers } from 'redux'
import { union } from 'lodash'

export const sitesById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SITES_SUCCESS:
      return { ...state, ...action.payload}
    default:
      return state
  }
}

function allSites(state = [], action) {
  switch (action.type) {
    case FETCH_SITES_SUCCESS:
    case UPDATE_SITES_SUCCESS:
      return union([], state, action.payload)
    default:
      return state
  }
}

export const sites = combineReducers({
  byId: sitesById,
  allIds: allSites
})

export const getSites = state => {
  return Object.values(state.entities.sites.byId)
}

export const getSite = ({ state, siteId }) => {
  return state.entities.sites.byId[siteId]
}
