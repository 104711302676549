export const FETCH_SITES_REQUEST = 'FETCH_SITES_REQUEST'
export const FETCH_SITES_SUCCESS = 'FETCH_SITES_SUCCESS'
export const FETCH_SITES_FAILURE = 'FETCH_SITES_FAILURE'
export const FETCH_CURRENT_SITE_REQUEST = 'FETCH_CURRENT_SITE_REQUEST'
export const FETCH_CURRENT_SITE_SUCCESS = 'FETCH_CURRENT_SITE_SUCCESS'
export const FETCH_CURRENT_SITE_FAILURE = 'FETCH_CURRENT_SITE_FAILURE'
export const UPDATE_SITES_REQUEST = 'UPDATE_SITES_REQUEST'
export const UPDATE_SITES_SUCCESS = 'UPDATE_SITES_SUCCESS'
export const UPDATE_SITES_FAILURE = 'UPDATE_SITES_FAILURE'

export const fetchSitesRequest = () => {
  return {
    type: FETCH_SITES_REQUEST
  }
}

export const fetchSitesSuccess = (sites) => {
  return {
    type: FETCH_SITES_SUCCESS,
    payload: sites
  }
}

export const fetchSitesFailure = e => {
  return {
    type: FETCH_SITES_FAILURE,
    payload: e,
    error: true
  }
}

export const fetchCurrentSiteRequest = () => {
  return {
    type: FETCH_CURRENT_SITE_REQUEST
  }
}

export const fetchCurrentSiteSuccess = (site) => {
  return {
    type: FETCH_CURRENT_SITE_SUCCESS,
    payload: site
  }
}

export const fetchCurrentSiteFailure = e => {
  return {
    type: FETCH_CURRENT_SITE_FAILURE,
    payload: e,
    error: true
  }
}

export const updateSitesRequest = site => {
  return {
    type: UPDATE_SITES_REQUEST,
    payload: site
  }
}

export const updateSitesSuccess = () => {
  return {
    type: UPDATE_SITES_SUCCESS
  }
}

export const updateSitesFailure = e => {
  return {
    type: UPDATE_SITES_FAILURE,
    payload: e,
    error: true
  }
}
