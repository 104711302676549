function projectManagerSubmit(values, dispatch, props, initialValues) {

  //Project
  props.updateProjectRequest({
    projectId: props.match.params.projectId, director: values.director,
    dop: values.dop, editor: values.editor, isArchived: values.isArchived,
    isStarred: values.isStarred, notes: values.notes, producer: values.producer,
    projectType: values.projectType, shortTitle: values.shortTitle,
    title: values.title, vfxCompany: values.vfxCompany
  })
  //Episodes
  let episodeUpdateAndCreate = values.episodes.filter(function (obj) {
    return props.singleProject.episodes.indexOf(obj) == -1;
  });

  episodeUpdateAndCreate.map((value) => {
    if (value.episodeId) {
      props.updateEpisodeRequest({ episodeId: value.episodeId, title: value.title, number: value.number, director: value.director })
    }
    else {
      props.createEpisodeRequest({
        title: value.title, number: value.number, director: value.director,
        projectId: props.match.params.projectId
      })
    }
  })

  let arrayForEpisodes = props.singleProject.episodes.filter(function (obj) { return values.episodes.indexOf(obj) == -1; });

  let arrayDeletedEpisodes = arrayForEpisodes.filter(o => !episodeUpdateAndCreate.find(o2 => o.episodeId === o2.episodeId))

  arrayDeletedEpisodes.map((value) => {
    props.deleteEpisodeRequest(value.episodeId)
  })

  //Statuses

  //Version Statuses
  let versionStatusesUpdateAndCreate = values.availableVersionStatuses.filter(function (obj) {
    return props.singleProject.availableVersionStatuses.indexOf(obj) == -1;
  });

  versionStatusesUpdateAndCreate.map((value) => {
    if (value.projectStatusId) {
      props.updateProjectStatusRequest({
        projectStatusId: value.projectStatusId, statusId: value.statusId,
        projectId: props.match.params.projectId,
        overrideColor: value.color
      })
    }
    else {
      props.createProjectStatusRequest({
        projectId: props.match.params.projectId, statusId: value.statusId,
        overrideColor: value.color, statusType: 2
      })
    }
  })

  let arrayVersion = props.singleProject.availableVersionStatuses.filter(function (obj) { return values.availableVersionStatuses.indexOf(obj) == -1; });

  let arrayDeletedVersionStatuses = arrayVersion.filter(o => !versionStatusesUpdateAndCreate.find(o2 => o.projectStatusId === o2.projectStatusId))

  arrayDeletedVersionStatuses.map((value) => {
    props.deleteProjectStatusRequest({ projectStatusId: value.projectStatusId, projectId: props.match.params.projectId })
  })

  //Shot/Asset Statuses
  let shotStatusesUpdateAndCreate = values.availableShotAssetStatuses.filter(function (obj) {
    return props.singleProject.availableShotAssetStatuses.indexOf(obj) == -1;
  });

  shotStatusesUpdateAndCreate.map((value) => {
    if (value.projectStatusId) {
      props.updateProjectStatusRequest({
        projectStatusId: value.projectStatusId, statusId: value.statusId,
        projectId: props.match.params.projectId, overrideColor: value.color
      })
    }
    else {
      props.createProjectStatusRequest({
        projectId: props.match.params.projectId, statusId: value.statusId,
        overrideColor: value.color, statusType: 1
      })
    }
  })

  let arrayShot = props.singleProject.availableShotAssetStatuses.filter(function (obj) { return values.availableShotAssetStatuses.indexOf(obj) == -1; });

  let arrayDeletedShotStatuses = arrayShot.filter(o => !shotStatusesUpdateAndCreate.find(o2 => o.projectStatusId === o2.projectStatusId))

  arrayDeletedShotStatuses.map((value) => {
    props.deleteProjectStatusRequest({ projectStatusId: value.projectStatusId, projectId: props.match.params.projectId })
  })


  //Tags
  let tagUpdateAndCreate = values.availableTags.filter(function (obj) {
    return props.singleProject.availableTags.indexOf(obj) == -1;
  });

  tagUpdateAndCreate.map((value) => {
    if (value.projectTagId) {
      props.updateTagRequest({ projectTagId: value.projectTagId, tagId: value.tagId, projectId: props.match.params.projectId, overrideColor: value.color })
    }
    else {
      props.createTagRequest({ overrideColor: value.color, projectId: props.match.params.projectId, tagId: value.tagId })
    }
  })

  let array4 = props.singleProject.availableTags.filter(function (obj) { return values.availableTags.indexOf(obj) == -1; });

  let arrayDeletedTags = array4.filter(o => !tagUpdateAndCreate.find(o2 => o.projectTagId === o2.projectTagId))

  arrayDeletedTags.map((value) => {
    props.deleteTagRequest({ projectTagId: value.projectTagId, projectId: props.match.params.projectId })
  })

  //Members
  values.members.map((value) => {

    let invitation
    let permissionsChecked = value.permissions
    if (value.assigned == true) {
      invitation = "invite"
    }
    else if (value.assigned == false) {
      invitation = "remove"
      permissionsChecked = ""
    }

    props.updateProjectMembersRequest({
      userId: value.userId, permissions: permissionsChecked,
      projectId: props.match.params.projectId, action: "invite"
    })
  })

  let membersUpdateAndCreate = values.members.filter(function (obj) {
    return props.singleProject.members.indexOf(obj) == -1;
  });
  let arrayMembers = props.singleProject.members.filter(function (obj) { return values.members.indexOf(obj) == -1; });
  let arrayDeletedMembers = arrayMembers.filter(o => !membersUpdateAndCreate.find(o2 => o.userId === o2.userId))
  arrayDeletedMembers.map((value) => {
    props.updateProjectMembersRequest({
      userId: value.userId,
      projectId: props.match.params.projectId, action: "remove"
    })

  })

}

export default projectManagerSubmit

