import React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'

import { units, colors, type } from '../../styles'
import { getTag } from '../../reducers/tag'

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',

  padding: '0 0.5rem',
  margin: '0 0.1875rem 0.1875rem 0',

  background: colors.darkerGrey,
  borderRadius: units.borderRadius
})

const Label = styled.h4({
  ...type.label,
  color: type.colors.night,
  whiteSpace: 'nowrap'
})

const Tag = ({ tag }) => {
  return (
    <Container bgColor={tag && tag.bgColor}>
      <Label>{tag && tag.title}</Label>
    </Container>
  )
}

const mapStateToProps = (state, { tagId }) => {
  return {
    tag: getTag({ state, tagId })
  }
}

const TagConnected = connect(mapStateToProps)(Tag)

export { Tag, TagConnected }
