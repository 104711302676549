import { colors, type, units } from '../../styles'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

const ddKeyframes = keyframes`
0% {
  opacity: 0;
  transform: scaleY(0)
}
80% {
  opacity: 0.5;
  transform: scaleY(1.1)
}
100% {
  opacity: 1;
  transform: scaleY(1)
}
`
export const UserManagerContainer = styled.div({
    flex: '1 1 100%',
    width: '100%',
    padding: '1rem',
    color: colors.lightGrey,
})

export const BorderHeader= styled.div({
    borderBottom: '0.0625rem solid #4a4b4c',
    marginBottom: '1rem'
})

export const Header = styled.div({
    display: 'flex',
    padding: '1rem 0',
    fontFamily: 'Heebo',
})

export const HeaderItem = styled.span({
    ...type.subHeader,
    padding: '0 1rem',
    'first-of-type': {
        paddingLeft: '0'
    },
    fontSize:"18px",
    fontFamily: 'Heebo',
})

export const UserProfileContainer = styled.div({
    position: 'relative',
    width: '100%',
    animation: `${ddKeyframes} .7s ease-in-out forwards`,
    transformOrigin: 'top center',

})

export const DropdownContainer = styled.div({
    position: 'relative',

})

export const SelectContainer = styled.div({
    position: 'relative',
    width: '100%',
    height: '1.75rem',
    background: colors.darkerGrey,
    border: `0.0625rem solid ${colors.darkerGrey}`,
    borderRadius: units.borderRadius,
    outline: '0',
    ':focus': {
        ...type.formActive
    },
    marginTop: "0.1rem"
})

export const WrapContainer = styled.div({
    marginTop: "0.2rem",
    marginBottom: "0.2rem",

})

export const IconWrap = styled.div({
    height: '1.5rem',
    width: '3rem',
    position: 'relative'

})

export const SelectedItem = styled.label({
    ...type.formActive,
    color: colors.day,
    fontSize: "0.8rem",
    fontStyle: "bold",
    fontFamily: 'Heebo',
})