import { schemas } from './schemas'
import { request } from './index'
import { normalize } from 'normalizr'

export const fetchTags = async () => {
  let res = await request(`/tags`)
  return normalize(res, schemas.TAGS)
}

export const createGlobalTag = async tag => {
  let res = await request(`/tags`, {
    method: 'post',
    data: tag
  })
  return res
}

export const updateGlobalTag = async tag => {
  let res = await request(`/tags/${tag.tagId}`, {
    method: 'put',
    data: tag
  })
  return res
}

export const deleteGlobalTag = async tag => {
  let res = await request(`/tags/${tag.tagId}`, {
    method: 'delete',
  })
  return res
}

//should this endpoint still have tags?action=invite
export const createTag = async projectTag => {
  let res = await request(`/projects/${projectTag.projectId}/tags`, {
    method: 'post',
    data: projectTag
  })
  return res
}

//should change the endpoints
export const updateTag = async tag => {
  let res = await request(`/projects/${tag.projectId}/tags/${tag.projectTagId}`, {
    method: 'put',
    data: tag
  })
  return res
}

export const deleteTag = async tag => {
  let res = await request(`/projects/${tag.projectId}/tags/${tag.projectTagId}`, {
    method: 'delete',
  })
  return res
}