import { schemas } from './schemas'
import { request } from './index'
import { normalize } from 'normalizr'

export const fetchToken = async ({ payload }) => {
  let res = await request('/users/login', {
    method: 'post',
    data: payload
  })
  return res
}

export const requestUserToValidate = async ({
  payload: { validationToken }
}) => {
  let res = await request(`/users/validate/${validationToken}`)
  return normalize(res, schemas.USER)
}

export const validateUser = async ({ payload: { user, validationToken } }) => {
  let res = await request(`/user/validate/${validationToken}`, {
    method: 'post',
    data: user
  })
  return res
}

export const requestPasswordRetrieval = async ({ payload }) => {
  let res = await request(`/users/resetPassword`, {
    method: 'post',
    data: payload
  })
  return res
}

export const fetchUsers = async () => {
  let res = await request('/users')
  return normalize(res, schemas.USERS)
}

export const fetchUser = async ({ payload: { personId } }) => {
  request(`/users/${personId}`)
}

export const fetchSelectedUser = async ( {personId} ) => {
  let res = await request(`/users/${personId}`)
  return normalize(res, schemas.USER)
}

export const updateUser = async (userData) => {
  let res = await request(`/users/${userData.userId}`, {
    method: 'put',
    data: { ...userData}
  })
  return normalize(res, schemas.USER)
}

export const createUser = async (userData) => {
  let res = await request(`/users/`, {
    method: 'post',
    data: { ...userData}
  })
  return normalize(res, schemas.USER)
}

export const updatePictureThumb = async ({ userId, files }) => {
  let formData = new FormData()
  formData.append('files[0]', files[0])
  let res = await request(`/users/${userId}/upload-avatar`, {
    method: 'post',
    data: formData
  })
  return res
}