import {
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION
} from '../actions/notification'
import { omit } from 'lodash'

const notifications = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NOTIFICATION:
      return {
        [action.payload.notificationID]: action.payload,
        ...state
      }
    case DELETE_NOTIFICATION:
      return omit(state, action.payload.notificationID)
    default:
      return state
  }
}

export { notifications }

export const getNotificationsByTimestamp = state => {
  return Object.values(state.ui.notifications).sort((a, b) => {
    return a.timestamp > b.timestamp
  })
}
