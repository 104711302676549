import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'

import { colors, units, type } from '../../styles'

const UnorderedList = styled.ul({
  position: 'absolute',
  left: '0',
  zIndex: '100',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'stretch',

  padding: `${units.half} 0`,
  width: '100%',

  background: colors.night,
  cursor: 'pointer',

})

const ListItem = styled.a({
  width: '100%',
  padding: `${units.half} ${units.base}`,
  ...type.subHeader,
  '&:hover': { color: colors.lightGrey, background: colors.day }
})

const Divider = styled.hr({
  border: 'unset',
  borderTop: `0.0625rem solid ${colors.grey}`,
  width: `calc(100% - ${units.base})`,
  margin: `'${units.base} ${units.half}`
})

const Dropdown = ({ items = [] }) => {
  return (
    <UnorderedList>
      {items.map((item, key) => {
        switch (item.type) {
          case 'link':

            return (
              <ListItem as={Link} key={key} to={{ pathname: item.path }}>
                {item.name}
              </ListItem>
            )
          case 'action':
            return (
              <ListItem key={key} onClick={item.action}>
                {item.name}
              </ListItem>
            )
          case 'link-action':
            return (
              <ListItem as={Link} key={key} onClick={item.action} to={{ pathname: item.path }} >
                  {item.name}
              </ListItem>
            )
          case 'divider':
            return <Divider key={key} />
          default:
            return <div key={key} />
        }
      })}
    </UnorderedList>
  )
}

export { Dropdown }
