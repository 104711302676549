import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'

import { OuterSelect } from '../UserManager/Select/OuterSelect'
import { BorderHeader, Header, HeaderItem, UserManagerContainer } from '../UserManager/UserManagerStyles'
import { fetchUserToValidateRequest } from '../../actions/auth'

import { getCurrentUser } from '../../reducers/auth'
import { updateUserRequest } from '../../actions/editUser'
import { getEditUserSelected } from '../../reducers/editUser'
import qs from 'query-string'
import { fetchSelectedUserRequest } from '../../actions/auth'
import { fetchSelectedUserProjectsRequest } from '../../actions/userManager'
import { getSelectedUserProjects } from '../../reducers/userManager'
import { getAllUserIds } from '../../reducers/user'
import { fetchProjectsArchivedRequest } from '../../actions/project'
import { updateProjectMembersRequest } from '../../actions/project'
import { getProjects } from '../../reducers/project'

const UserAccount = ({
    selectedUserProjects,
    fetchSelectedUserProjectsRequest,
    location,
    userEditable,
    updateUserRequest,
    fetchSelectedUserRequest,
    fetchUserToValidateRequest,
    currentUserId,
    loggedInUser,
    fetchProjectsArchivedRequest,
    projects,
    updateProjectMembersRequest
}) => {

    let { token } = qs.parse(location.search)

    useEffect(() => {
        token && fetchUserToValidateRequest(token)
    }, [token, fetchUserToValidateRequest])

    useEffect(() => {
        if (!isNaN(currentUserId)) {
            fetchSelectedUserRequest(currentUserId)
            fetchSelectedUserProjectsRequest(currentUserId)
        }
    }, [currentUserId])
    
    return (
        <UserManagerContainer >
            <Helmet>
                <title>SA User Management</title>
            </Helmet>

            <BorderHeader>
                <Header>
                    {loggedInUser[currentUserId] && (
                        <HeaderItem >
                            {loggedInUser[currentUserId].firstName + " " +
                                loggedInUser[currentUserId].lastName}
                        </HeaderItem>
                    )}
                </Header>
            </BorderHeader>

            {loggedInUser && (
                <OuterSelect
                    userData={loggedInUser[currentUserId]}
                    userProjects={selectedUserProjects}
                    editable={userEditable ? userEditable : false}
                    updateUserRequest={updateUserRequest}
                    fetchUserProjectsRequest={fetchSelectedUserProjectsRequest}
                    projects={projects}
                    updateProjectMembersRequest={updateProjectMembersRequest}
                    fetchProjectsArchivedRequest={fetchProjectsArchivedRequest}
                >
                </OuterSelect>
            )}
        </UserManagerContainer>
    )
}
const mapStateToProps = (state, userManagerSelection, currentUserId) => {
    return {
        userEditable: getEditUserSelected(state),
        currentUserId: getCurrentUser(state),
        loggedInUser: getAllUserIds(state, currentUserId),
        selectedUserProjects: getSelectedUserProjects(state, userManagerSelection),
        projects: getProjects(state),
    }
}

export default connect(mapStateToProps, {
    fetchSelectedUserProjectsRequest,
    fetchUserToValidateRequest,
    fetchSelectedUserRequest,
    updateUserRequest,
    fetchProjectsArchivedRequest,
    updateProjectMembersRequest
})(UserAccount)