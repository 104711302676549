import { request } from './index'

export const createEpisode = async episode => {
  let res = await request('/episodes', {
    method: 'post',
    data: episode
  })
  return res
}

export const updateEpisode = async episode => {
  let res = await request(`/episodes/${episode.episodeId}`, {
    method: 'put',
    data: episode
  })
  return res
}

export const deleteEpisode = async episodeId => {
  let res = await request(`/episodes/${episodeId}`, {
    method: 'delete'
  })
  return res
}