import { schemas } from './schemas'
import { request } from './index'
import { normalize } from 'normalizr'

export const fetchStatuses = async () => {
  let res = await request(`/statuses`)
  return normalize(res, schemas.STATUSES)
}

export const createStatus = async status => {
  let res = await request('/statuses', {
    method: 'post',
    data: status
  })
  return res
}

export const updateStatus = async status => {
  let res = await request(`/statuses/${status.statusId}`, {
    method: 'put',
    data: status
  })
  return res
}

export const deleteStatus = async statusId => {
  let res = await request(`/statuses/${statusId}`, {
    method: 'delete'
  })
  return res
}

export const createProjectStatus = async status => {
  let res = await request(`/projects/${status.projectId}/statuses`, {
    method: 'post',
    data: status
  })

  return res
}

export const updateProjectStatus = async status => {
  let res = await request(`/projects/${status.projectId}/statuses/${status.projectStatusId}`, {
    method: 'put',
    data: status
  })
  return res
}

export const deleteProjectStatus = async status => {
  let res = await request(`/projects/${status.projectId}/statuses/${status.projectStatusId}`, {
    method: 'delete'
  })
  return res
}