import React, { useState, useEffect } from 'react'
import { Field, FieldArray } from 'redux-form'
import Button from '../Button/Button'
import {
    RowTitles, Row, Label, Section, LabelSection,
    Icon, CloseIcon, WrapRight, WrapLeft, getItemStyle, getListStyle
} from '../ProjectsManager/DetailsComponents/DetailsComponentsStyles'
import reorderIcon from './reorderIcon.svg'
import { colors } from '../../styles'
import { ColorsSelect } from '../ProjectsManager/DetailsComponents/RenderFields'
import closeIcon from './closeIcon.svg'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { getTags } from '../../reducers/tag'
import {
    fetchTagsRequest, updateGlobalTagRequest,
    createGlobalTagRequest, deleteGlobalTagRequest
} from '../../actions/tag'
import styled from '@emotion/styled'
import globalTagsSubmit from './globalTagsSubmit'

const Container = styled.div({
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
})

const renderField = ({
    input, label, type, meta: { touched, error, warning },
}) => (
        <div >
            <label>{label}</label>
            <div >
                <input className="black-field-projects" {...input} placeholder={label} type={type}
                />
            </div>
        </div>
    )
const RenderTags = ({ fields }) => {

    let [items, setItems] = useState(fields ? fields : '')

    useEffect(() => {
        setItems(fields)
    }, [fields == undefined])

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        fields.move(result.source.index, result.destination.index)
    }

    if (fields !== undefined) {
        return (
            <React.Fragment>
                <DragDropContext onDragEnd={onDragEnd} style={{
                    position: 'relative',
                    width: '100%',
                    background: colors.night,
                }}>

                    <Droppable droppableId="droppable"
                        style={{
                            position: 'relative',
                            width: '100%',
                            background: colors.night,
                        }}>
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle()}
                            >
                                {fields.map((globalTag, index) => {
                                    return (
                                        <Draggable key={index} draggableId={`${globalTag}.tagId`} index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                >
                                                    <div style={{
                                                        position: 'relative',
                                                        height: '20%',
                                                        width: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        background: colors.night,
                                                        borderBottom: '0.0625rem solid #4a4b4c',
                                                    }}>
                                                        <Section style={{ display: 'flex', flexWrap: 'wrap', }}>

                                                            <Icon src={reorderIcon} {...provided.dragHandleProps} />
                                                            <WrapRight>
                                                                <Field
                                                                    name={`${globalTag}.title`}
                                                                    type="text"
                                                                    component={renderField}
                                                                />
                                                            </WrapRight>
                                                        </Section>

                                                        <Section>
                                                            <Field
                                                                name={`${globalTag}.color`}
                                                                component={ColorsSelect}
                                                                options={[
                                                                    { label: 'blue', value: '#374890' },
                                                                    { label: 'darkY', value: '#B68A2B' },
                                                                    { label: 'red', value: '#853533' },
                                                                    { label: 'green', value: '#327A85' },
                                                                    { label: 'lightY', value: '#BBEAA6' },
                                                                    { label: 'black', value: '#000000' },
                                                                    { label: 'grey', value: '#323232' },
                                                                    { label: 'purple', value: '#843364' },
                                                                ]}
                                                            >
                                                            </Field>
                                                        </Section>
                                                        <Section>

                                                        </Section>
                                                        <Section><CloseIcon src={closeIcon} onClick={() => fields.remove(index)}></CloseIcon></Section>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                            </div>)}
                    </Droppable>
                </DragDropContext>

                <Row>
                    <Section><Button type='button' onClick={() => fields.push({})}
                        style={{ height: '2.5rem', fontWeight: 'bold' }}>Add Tag
             </Button></Section>
                    <Section></Section>
                    <Section></Section>
                    <Section></Section>
                </Row>

            </React.Fragment>)
    }
    else return <div>""</div>
}
let Tags = (props) => {

    useEffect(() => {
        props.fetchTagsRequest()
    }, [props.fetchTagsRequest])

    useEffect(() => {
        const { initialize } = props

        if (props.globalTags !== undefined && props.globalTags.length !== 0) {
            initialize({ globalTagsArray: props.globalTags })
        }
    }, [props.globalTags == undefined, props.globalTags.length == 0])

    const { handleSubmit } = props

    return (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            {(() => {
                if (props.globalTags !== undefined) {
                    return (
                        <React.Fragment >
                            {props.isEditable ? (
                                <Container>

                                    <RowTitles >
                                        <LabelSection style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            <WrapLeft></WrapLeft>
                                            <WrapRight><Label >Tag title</Label></WrapRight>
                                        </LabelSection>
                                        <LabelSection><Label>Select color</Label></LabelSection>
                                        <LabelSection><Label></Label></LabelSection>
                                        <LabelSection></LabelSection>
                                    </RowTitles>

                                    <FieldArray name="globalTagsArray" component={RenderTags} />

                                </Container>
                            ) : (
                                    <Container>
                                        <RowTitles >
                                            <LabelSection style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '0.2rem' }}>
                                                <WrapLeft></WrapLeft>
                                                <WrapRight><Label >Tag title</Label></WrapRight>
                                            </LabelSection>
                                            <LabelSection><Label></Label></LabelSection>
                                            <LabelSection><Label></Label></LabelSection>
                                            <LabelSection></LabelSection>
                                        </RowTitles>

                                        {Object.entries(props.globalTags).map(([key, value]) => {
                                            return (
                                                <React.Fragment key={value.tagId}>

                                                    <Row>
                                                        <Section>
                                                            <Button style={{ height: '2.5rem', backgroundColor: value.color, color: 'black', float: 'left' }}>{value.title}</Button>
                                                        </Section>

                                                        <Section></Section>
                                                        <Section></Section>
                                                        <Section></Section>
                                                    </Row>
                                                </React.Fragment>
                                            )
                                        })}
                                    </Container>
                                )
                            }
                        </React.Fragment>)
                } else {
                    return (
                        <div>catch all</div>
                    )
                }
            })()}
        </form>
    )
}

Tags = reduxForm({
    form: 'GlobalTagsForm',
    onSubmit: globalTagsSubmit,
})(Tags)

const mapStateToProps = (state) => {
    return {
        globalTags: getTags(state)
    }
}
Tags = connect(mapStateToProps, {
    fetchTagsRequest,
    updateGlobalTagRequest,
    createGlobalTagRequest,
    deleteGlobalTagRequest
})(Tags)

export default Tags
