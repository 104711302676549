import React from 'react'
import { Container, Row, Section, Text } from './DetailsComponentsStyles'
import { Field } from 'redux-form'
import styled from '@emotion/styled'

const Label = styled.div({
    fontSize: "0.9rem",
    fontStyle: "bold",
    paddingBottom: '0.5rem'
})

const renderField = ({
    input, label, type, meta: { touched, error, warning },
}) => (
        <div>
            <label>{label}</label>
            <div  >
                <input className="black-field-projects" {...input} placeholder={label} type={type}
                />
            </div>
        </div>
    )
const Site = (props) => {

    return (
        <React.Fragment>
            {(() => {
                if (props.isEditable) {
                    return (
                        <Container>
                            <Row>
                                <Section>
                                    <Label>Site Name</Label>
                                    <Field
                                        name='siteName'
                                        type="text"
                                        component={renderField}
                                    />
                                </Section>
                                <Section></Section>
                                <Section></Section>
                                <Section></Section>
                            </Row>
                        </Container>
                    )
                } else {
                    return (
                        <Container>
                            <Row>
                                <Section>
                                    <Label>Site Name</Label>
                                    <Text>{props.site.siteName}</Text>
                                </Section>
                                <Section></Section>
                                <Section></Section>
                                <Section></Section>
                            </Row>
                        </Container>
                    )
                }
            })()}
        </React.Fragment>
    )
}

export default Site