import { put, takeEvery, all } from 'redux-saga/effects'
import * as actionsUi from '../actions/ui'

export function* userManager(userId) {
    try {
        yield put(actionsUi.setUserManagerSelection(userId))
        yield put(actionsUi.setUserManagerSelectionSuccess(userId))
    } catch (e) {
        yield put(actionsUi.setUserManagerSelectionFailure(e))
    }
}

export function* selection() {
    yield all([
        takeEvery(actionsUi.SET_USER_MANAGER_SELECTION, userManager)
    ])
}