import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'

import { Spinner } from '../Spinner/Spinner'
import { Status } from '../Status/Status'

import { getShotVersion } from '../../reducers/shotVersion'
import {
  fetchShotVersionRequest,
  updateShotVersionRequest
} from '../../actions/shotVersion'

import { colors, type } from '../../styles'

const ShotVersionContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: '25rem 15rem 1fr',
  width: '100%'
})

const ShotVersionSection = styled.div({
  minWidth: '100%',
  display: 'flex',
  padding: '0.5rem 0.8125rem',
  alignItems: 'center'
})

const ShotVersionCode = styled.h3({
  ...type.subHeader,
  color: colors.day,
  padding: '0 0 0 10rem'
})

const Remark = styled.p({
  ...type.content
})

const ShotVersion = ({
  shotVersion,
  shotVersionId,
  fetchShotVersionRequest,
  updateShotVersionRequest
}) => {
  useEffect(() => {
    fetchShotVersionRequest({ shotVersionId })
  }, [shotVersionId, fetchShotVersionRequest])

  return (
    <ShotVersionContainer>
      {shotVersion ? (
        <>
          <ShotVersionSection>
            <ShotVersionCode>{shotVersion.versionNumber}</ShotVersionCode>
          </ShotVersionSection>
          <ShotVersionSection>
            <Status
              status={shotVersion.status}
              statusHandler={statusId => {
                updateShotVersionRequest({
                  shotVersionId: shotVersion.shotVersionId,
                  statusId
                })
              }}
            />
          </ShotVersionSection>
          <ShotVersionSection>
            <Remark>{shotVersion.remarks}</Remark>
          </ShotVersionSection>
        </>
      ) : (
        <Spinner />
      )}
    </ShotVersionContainer>
  )
}

const mapStateToProps = (state, { shotVersionId }) => {
  return {
    shotVersion: getShotVersion(state, shotVersionId)
  }
}

const ShotVersionConnected = connect(
  mapStateToProps,
  { fetchShotVersionRequest, updateShotVersionRequest }
)(ShotVersion)

export { ShotVersion, ShotVersionConnected }
